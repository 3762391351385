import crypto from 'crypto';

/**
 * Encrypt a string data using an encryption key
 * @param text string data to encrypt
 * @param key string key to encrypt the data
 * @returns encrypted string
 */
export function encrypt(text: string, key: string): string {
  try {
    const algorithm = 'aes-256-cbc';
    const iv = crypto.randomBytes(16); // Generate a random IV
    const hashKey = crypto.createHash('sha256').update(String(key)).digest('base64').substr(0, 32);
    const cipher = crypto.createCipheriv(algorithm, hashKey, iv);
    let encrypted = cipher.update(text);
    encrypted = Buffer.concat([encrypted, cipher.final()]);
    return iv.toString('hex') + encrypted.toString('hex'); // Combine IV and encrypted data
  } catch (error) {
    console.error('Error encrypting data', error);
    return '';
  }
}

/**
 * decrypt a string data using an encryption key
 * @param text string data to decrupt
 * @param key string key to decrypt the data
 * @returns decrypted string
 */
export function decrypt(text: string, key: string): string {
  try {
    const algorithm = 'aes-256-cbc';
    const iv = Buffer.from(text.slice(0, 32), 'hex'); // Extract IV from the combined data
    const encryptedText = Buffer.from(text.slice(32), 'hex');
    const hashKey = crypto.createHash('sha256').update(String(key)).digest('base64').substr(0, 32);
    const decipher = crypto.createDecipheriv(algorithm, hashKey, iv);
    let decrypted = decipher.update(encryptedText);
    decrypted = Buffer.concat([decrypted, decipher.final()]);
    return decrypted.toString();
  } catch (error) {
    console.error('Error decrypting data', error);
    return '';
  }
}
