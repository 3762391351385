import { useCallback, useEffect, useReducer, useRef } from 'react';
import { useRouter } from 'next/router';
import { useToast } from '@chakra-ui/react';
import { algoliaInitSearchInsights } from 'composable/analytics/algolia/algolia-tracking';
import { analyticsTrackLogin } from 'composable/analytics/analytics-event-tracking';
import { APPCUES_ID } from 'composable/analytics/appcues';
import { appcuesIdentifyAndGroupUser } from 'composable/analytics/appcues/appcues-tracking';
import { LOGROCKET_ID } from 'composable/analytics/logrocket/constants';
import { logrocktIdentifyUser } from 'composable/analytics/logrocket/logrocket-tracking';
import { APPLICATION_URL, TOAST_ICON } from 'composable/components/general';
import {
  ACCESS_TOKEN,
  EXPIRES_ON,
  ORIGIN_PATH,
  REFRESH_TOKEN,
  SELECTED_BUSINESS_UNIT_KEY,
} from 'composable/helpers/constants';
import { useEditOrderMode, useLocalStorage } from 'composable/helpers/hooks';
import {
  getBrowserAccessToken,
  getCustomerObject,
  getServerAzureLogoutUrl,
  getServerShamrockRedirectUrl,
  GetServerShamrockRedirectUrlParams,
  refreshAccessToken,
  storeAuthTime,
} from 'composable/helpers/utils/use-user-utils';
import { getAlgoliaKey } from 'composable/helpers/utils/user-utils';
import { ERROR_DICTIONARY } from 'helpers/constants/auth';
import { LOGIN_METHOD } from 'helpers/constants/eventTracking';
import { permission } from 'helpers/constants/permissions';
import routes from 'helpers/constants/routes';
import { useFormat } from 'helpers/hooks';
import { isSlugValid, PUBLIC_PREFIX } from 'helpers/slugHelpers';
import { trackShamrockUserLogin } from 'helpers/utils/trackShamrockUserLogin';
import { fetchApiHub } from 'frontastic';
import { deleteLocalStorageValuesWithoutBu, login } from 'frontastic/actions/account';
import { reduceUser, userInitialState } from './reduce-user';
import { UserGlobalStateActions } from './types';
import { getAdditionalAzureUrlParams, selectedAccountDetails, validateAccessToken } from './utils';

export const ADDITIONAL_AZURE_URL_PARAMS = 'state';

export const use_privateUserGlobal = ({ isPublic, encryptionKey = '' }) => {
  /* eslint-disable */
  const [state, dispatch] = useReducer(reduceUser, userInitialState);
  const [localStorageAccessToken] = useLocalStorage(ACCESS_TOKEN, '');
  const [localStorageRefreshToken] = useLocalStorage(REFRESH_TOKEN, '');
  const [localStorageExpiresOn] = useLocalStorage(EXPIRES_ON, 0);
  const [originPath, setOriginPath] = useLocalStorage(ORIGIN_PATH, '');
  const [activeAccountKey, setActiveAccountKey] = useLocalStorage(SELECTED_BUSINESS_UNIT_KEY, '');
  const toast = useToast();
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'common' });
  const azureRedirectUrl = useRef('');
  /* eslint-enable */

  if (state.ctUser && state.activeAccount) {
    algoliaInitSearchInsights({ user: state.ctUser.customer, activeAccount: state.activeAccount });
  }

  const setAzureUrls = async () => {
    // Additional params to be passed to the azure redirect url
    const params: GetServerShamrockRedirectUrlParams = { redirectTo: originPath ?? '/' };

    try {
      azureRedirectUrl.current = getServerShamrockRedirectUrl(APPLICATION_URL, '301', '', params);
    } catch (error) {
      console.error('Error while getting azure urls', error);
    }
  };

  //eslint-disable-next-line
  const successCallback = useCallback(async (accessToken: string, clientIp: string, state: any) => {
    trackShamrockUserLogin({ accessToken, clientIp });

    analyticsTrackLogin({ method: LOGIN_METHOD });

    // Call to logrocket if logrocketid is available to identify user
    if (LOGROCKET_ID) {
      logrocktIdentifyUser(state.ctUser.customer, state.activeAccount);
    }

    const accessCallback = window.localStorage.getItem(ACCESS_TOKEN);

    if (accessCallback) {
      storeAuthTime(encryptionKey);

      const redirectPath = originPath.toLowerCase();
      const canRedirect = isSlugValid(redirectPath);

      // Extract any additional parameters from the azure redirect url
      const additionalParams = getAdditionalAzureUrlParams(azureRedirectUrl.current);
      const redirectToValue = additionalParams.redirectTo;

      if (
        (redirectPath !== routes.HOME && redirectPath !== '' && canRedirect) ||
        (redirectToValue && redirectToValue !== routes.HOME)
      ) {
        setOriginPath(routes.HOME);

        router.replace(
          {
            pathname: redirectPath || redirectToValue,
            query: '',
          },
          undefined,
          { shallow: false },
        );
      } else {
        // remove the ?code from the url
        const { code } = router.query;
        if (code) {
          router.replace(
            {
              pathname: routes.HOME,
              query: '',
            },
            undefined,
            { shallow: true },
          );
        }
      }

      // Delaying appcues call after login to avoid issues while resolving redirects.
      setTimeout(() => {
        // Call to appcues if appcuesid and customer information is available
        if (APPCUES_ID) {
          appcuesIdentifyAndGroupUser(
            state.ctUser.customer,
            state.activeAccount,
            state.shamrockUser.user.permissions.data,
          );
        }
      }, 3000);
    }
  }, []);

  //eslint-disable-next-line
  const logout = useCallback(
    async (hardLogout: boolean = false, message?: string) => {
      if (hardLogout) {
        try {
          await fetchApiHub('/action/account/logout', { method: 'POST' });
        } catch (error) {
          console.error('Error API call: logout', error);
        }
      }

      // directly assinging the values to local storage to avoid it only be set
      // on a state refresh
      window.localStorage.removeItem(ACCESS_TOKEN);
      window.localStorage.removeItem(REFRESH_TOKEN);
      window.localStorage.removeItem(EXPIRES_ON);

      await dispatch({ type: UserGlobalStateActions.LOGOUT });

      if (hardLogout) {
        // If error is included in dictionary send user to error page
        if (!!message && !!ERROR_DICTIONARY[message]) {
          const errorPageUrl = `${routes.PUBLIC_ERROR_PAGE}?login=${message}`;
          window.location.href = errorPageUrl;
          return;
        }

        // otherwise, send to azure login
        deleteLocalStorageValuesWithoutBu();
        window.location.href = await getServerAzureLogoutUrl(message);
        return;
      }

      const loginRedirectUrl = await getServerShamrockRedirectUrl(APPLICATION_URL, null, message);
      window.location.href = loginRedirectUrl;
    },
    [state.loading],
  );

  //eslint-disable-next-line
  const setSelectedAccount = useCallback(
    async (accessToken: string, accountKey: string, refreshToken?: string, expiresOn?: number) => {
      try {
        const loginResponse = await login(accessToken, toast, formatMessage({ id: 'app.generic.error' }), accountKey);

        if (loginResponse.statusCode || loginResponse.error_code) {
          throw loginResponse;
        }

        window.localStorage.setItem(ACCESS_TOKEN, accessToken);
        setActiveAccountKey(accountKey);

        const customerObject = getCustomerObject(accessToken, loginResponse);

        const actionPayload = {
          accessToken: accessToken,
          refreshToken: refreshToken || localStorageRefreshToken,
          expiresOn: expiresOn || localStorageExpiresOn,
          shamrockUser: loginResponse.shamrockUser,
          ctUser: customerObject.customer,
          activeAccount: selectedAccountDetails(loginResponse.selectedBuKey, loginResponse.businessUnits),
          activeWarehouse: loginResponse.store.details,
          accountList: loginResponse.businessUnits,
        };

        dispatch({ type: UserGlobalStateActions.LOGIN, payload: actionPayload });
        successCallback(accessToken, loginResponse.clientIp, actionPayload);
      } catch (error) {
        // avoid user not found getting stuck in loading
        console.warn('##DLDebug error during login process', error);

        if (error?.message) {
          const parsedError = JSON.parse(error.message);
          await logout(true, parsedError?.error_code);
          return;
        }

        // on login error send to error page
        logout(true, 'user_error');
      }
    },
    [state.loading, localStorageAccessToken, localStorageExpiresOn, localStorageRefreshToken],
  );

  //eslint-disable-next-line
  const switchAccount = useCallback(
    async (accountKey: string) => {
      if (!accountKey) {
        return;
      }

      dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: true } });
      localStorage.setItem('previousAccount', activeAccountKey);
      try {
        await fetchApiHub(
          '/action/account/switchAccount',
          { method: 'POST' },
          { accessToken: state.accessToken, selectedBuKey: accountKey },
        );

        await setSelectedAccount(state.accessToken, accountKey);

        await toast({
          status: 'success',
          title: formatMessage({ id: 'switchAccount.toast.success' }),
          duration: 5000,
          icon: TOAST_ICON.success,
        });
      } catch (error) {
        console.error('Error while switching account', error);
      } finally {
        await dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: false } });
      }
    },
    [state.accessToken],
  );

  // Create a callback checkIfLoggedIn and export it
  //eslint-disable-next-line
  const checkIfLoggedIn = useCallback(async () => {
    const incrementalLog: any = {
      accessTokens: {
        state: state.accessToken,
        localStorage: window.localStorage?.getItem('access_token'),
        url: window.location.search,
      },
    };
    const url = router.asPath;
    const urlWithoutQuery = url.split('?')[0];

    if (!urlWithoutQuery.includes(PUBLIC_PREFIX)) {
      setOriginPath(urlWithoutQuery);
    }

    await setAzureUrls();

    if (isPublic && !localStorageAccessToken) {
      await dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: false } });
      return;
    }

    // handle users redirected to redirect-from-so route
    if (router?.pathname.toLowerCase().includes(routes.REDIRECT_FROM_SO.toLowerCase())) {
      incrementalLog.redirectPage = true;
      console.warn('##DLDebug Redirecting user to login because user landed on the redirection page', incrementalLog);
      await logout(false);
      return;
    }

    let loginAccessToken = '';
    let loginRefreshToken = localStorageRefreshToken;
    let loginExpiresOn = localStorageExpiresOn;
    let hasValidToken = false;

    // check state accessToken
    if (
      state.accessToken &&
      validateAccessToken(state.accessToken, state.expiresOn) &&
      validateAccessToken(state.refreshToken)
    ) {
      // check if accesstoken is valid
      loginAccessToken = state.accessToken;
      hasValidToken = validateAccessToken(loginAccessToken);
      incrementalLog.validStateToken = true;
    }

    // check if we received a code from the query
    if (!hasValidToken) {
      const { accessToken, refreshToken, expiresOn } = await getBrowserAccessToken(window);
      incrementalLog.getToken = {
        accessToken,
        refreshToken,
        expiresOn,
      };

      if (accessToken && refreshToken && expiresOn) {
        window.localStorage.setItem(ACCESS_TOKEN, accessToken);
        window.localStorage.setItem(REFRESH_TOKEN, refreshToken);
        window.localStorage.setItem(EXPIRES_ON, expiresOn.toString());

        // as we're using state to set the localstorage values, they might not be
        // updated in the first run, so we should update local varibles too
        loginAccessToken = accessToken;
        loginRefreshToken = refreshToken;
        loginExpiresOn = expiresOn;
        hasValidToken = validateAccessToken(loginAccessToken);
      }
    }

    // check localStorage accessToken
    if (
      !hasValidToken &&
      validateAccessToken(localStorageAccessToken, localStorageExpiresOn) &&
      validateAccessToken(localStorageRefreshToken)
    ) {
      loginAccessToken = localStorageAccessToken;
      hasValidToken = true;
      incrementalLog.validLSToken = true;
    }

    if (hasValidToken) {
      const refreshToken = loginRefreshToken || localStorageRefreshToken;

      // sets refresh token
      if (validateAccessToken(refreshToken)) {
        refreshAccessToken(refreshToken, getServerAzureLogoutUrl(), encryptionKey, (getTokensResponse) => {
          window.localStorage.setItem(ACCESS_TOKEN, getTokensResponse.access_token);
          dispatch({
            type: UserGlobalStateActions.SET_ACCESS_TOKEN,
            payload: {
              accessToken: getTokensResponse.access_token,
              refreshToken: getTokensResponse.refresh_token,
              expiresOn: getTokensResponse.expires_on,
            },
          });
        });
      }

      await setSelectedAccount(loginAccessToken, activeAccountKey, loginRefreshToken, loginExpiresOn);
    }

    if (!hasValidToken) {
      console.warn('##DLDebug Unable to login user, redirecting to login page', incrementalLog);
      await dispatch({ type: UserGlobalStateActions.SET_LOADING, payload: { loading: false } });
      logout(false, 'user_not_logged_in');
    }
  }, [activeAccountKey, isPublic, localStorageAccessToken, localStorageRefreshToken, localStorageExpiresOn]);

  //eslint-disable-next-line
  const canViewProductCatalog = useCallback(() => {
    // If user get error on algoliaKey treat as permission denied
    if (!state.loading && state?.activeAccount?.key) {
      const algoliaKey = getAlgoliaKey(state.activeAccount, state.ctUser?.customer);

      if (!algoliaKey) {
        return false;
      }
    }

    return state.shamrockUser?.user?.permissions?.data?.includes(permission.CanViewProductCatalog) || state.loading;
  }, [state.shamrockUser?.user, state.loading, state.activeAccount?.key]);

  // @TODO remove Edit Order Mode on SHAM-1955

  const {
    editOrder,
    handleDisableEditOrder,
    handleEnableEditOrder,
    handleChangeOrderEditing,
    updateOrder,
    setUpdatingOrder,
    persistedSplittedEditOrder,
    updatingOrder,
    findOriginalOrderFromCurrentEditingOrder,
    //eslint-disable-next-line
  } = useEditOrderMode();

  // login once we hit here.
  //eslint-disable-next-line
  useEffect(() => {
    checkIfLoggedIn();
  }, []);

  return {
    state,
    dispatch,
    checkIfLoggedIn,
    logout,
    switchAccount,
    canViewProductCatalog,
    azureRedirectUrl: azureRedirectUrl.current,
    // Edit Order Mode
    editOrder,
    handleDisableEditOrder,
    handleEnableEditOrder,
    handleChangeOrderEditing,
    updateOrder,
    setUpdatingOrder,
    persistedSplittedEditOrder,
    updatingOrder,
    findOriginalOrderFromCurrentEditingOrder,
  };
};
