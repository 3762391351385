/**
 * Extracts the user ID from the Google Analytics device ID.
 * @example `GA1.1.xxxxxxxxxx.xxxxxxxxxx => xxxxxxxxxx_xxxxxxxxxx`
 * @link https://support.google.com/analytics/answer/11397207
 */
function extractGoogleAnalyticsUserIdFromCookie(gaCookie) {
  if (!gaCookie) {
    return undefined;
  }
  // Remove the Google Analytics tracker from the device ID.
  const userIdParts = gaCookie.split('.').slice(-2);
  return userIdParts.length === 2 ? userIdParts.join('_') : undefined;
}

function getBrowserCookie(cookieName) {
  // In React Native environments, `document.cookie` doesn't exist.
  if (typeof document !== 'object' || typeof document.cookie !== 'string') {
    return undefined;
  }
  const name = cookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return undefined;
}

/**
 * Returns the Google Analytics User ID from a browser cookie name.
 * @example `getGoogleAnalyticsUserIdFromBrowserCookie('_ga')`
 */
export function getGoogleAnalyticsUserIdFromBrowserCookie(cookieName) {
  const browserCookie = getBrowserCookie(cookieName);

  if (!browserCookie) {
    return undefined;
  }

  return extractGoogleAnalyticsUserIdFromCookie(browserCookie);
}
