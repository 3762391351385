import {
  FunctionComponent,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { isPrintBackPage, isPublicPage } from 'composable/helpers/utils/is-public-page';
import { use_privateCartGlobal } from 'hooks/global/use_privateCartGlobal';
import { UseCartGlobalResponse } from 'hooks/global/use_privateCartGlobal/types';
import { use_privateCutoffsGlobal } from 'hooks/global/use_privateCutoffsGlobal';
import { UseCutoffsGlobalResponse } from 'hooks/global/use_privateCutoffsGlobal/types';
import { use_privateOrderGuideListGlobal } from 'hooks/global/use_privateOrderGuideListGlobal';
import { UseOrderGuideListGlobalResponse } from 'hooks/global/use_privateOrderGuideListGlobal/types';
import { use_privateTrendingItemsGlobal } from 'hooks/global/use_privateTrendingItemGlobal';
import { TrendingItemsGlobalType } from 'hooks/global/use_privateTrendingItemGlobal/types';
import { use_privateUserGlobal } from 'hooks/global/use_privateUserGlobal';
import { UseUserGlobalResponse } from 'hooks/global/use_privateUserGlobal/types';
import { PageProps } from 'frontastic';
import { useLocalStorage } from 'composable/helpers/hooks';
import { ACCESS_TOKEN } from 'composable/helpers/constants';

type GlobalContextValues = {
  useUserGlobal: UseUserGlobalResponse;
  useCartGlobal: UseCartGlobalResponse;
  useCheckoutGlobal: null;
  useOrderEditGlobal: null;
  useTrendingItemsGlobal: TrendingItemsGlobalType;
  useOrderGuideListGlobal: UseOrderGuideListGlobalResponse;
  useRevalidateOGData: number;
  setUseRevalidateOGData: Dispatch<SetStateAction<number>>;
  useCutoffsGlobal: UseCutoffsGlobalResponse;
  isPrintBack: boolean;
};

const GlobalContext = createContext<GlobalContextValues>(null!);

export const useGlobal = () => {
  const context = useContext(GlobalContext);

  if (!context) {
    throw new Error('useGlobal must be used within a GlobalProvider');
  }

  return context;
};

export const GlobalProvider: FunctionComponent<PropsWithChildren & { pageProps?: PageProps }> = ({
  children,
  pageProps,
}) => {
  const [localStorageAccessToken] = useLocalStorage(ACCESS_TOKEN, '');
  const isPublic = isPublicPage(pageProps?.data) && !localStorageAccessToken;
  const isPrintBack = isPrintBackPage(pageProps?.data);

  // Avoid fetching cart or unecessary data for public pages print back pages
  const avoidFetch = isPublic || isPrintBack;

  const encryptionKey = pageProps?.encryptionKey || '';

  const useUserGlobal = use_privateUserGlobal({ isPublic: isPublic, encryptionKey });
  const useCartGlobal = use_privateCartGlobal({ user: useUserGlobal.state, isPublicPage: avoidFetch });
  const useCheckoutGlobal = null;
  const useOrderEditGlobal = null;
  const useTrendingItemsGlobal = use_privateTrendingItemsGlobal(useUserGlobal.state, avoidFetch);
  const useOrderGuideListGlobal = use_privateOrderGuideListGlobal(useUserGlobal, avoidFetch);
  // state used as a trigger to revalidate OG data products and list OG
  const [useRevalidateOGData, setUseRevalidateOGData] = useState<number>(0);
  const useCutoffsGlobal = use_privateCutoffsGlobal({ user: useUserGlobal.state, isPublic: avoidFetch });

  return (
    <GlobalContext.Provider
      value={{
        useUserGlobal,
        useCartGlobal,
        useCheckoutGlobal,
        useOrderEditGlobal,
        useTrendingItemsGlobal,
        useOrderGuideListGlobal,
        useRevalidateOGData,
        setUseRevalidateOGData,
        useCutoffsGlobal,
        isPrintBack,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
