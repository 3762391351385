import routes from 'helpers/constants/routes';

export const PUBLIC_PREFIX = '/public';
export const isPublicSlug = (slug: string) => slug.includes(PUBLIC_PREFIX);

export const isSlugValid = (slug: string) => {
  for (let path in routes) {
    const pathValue = routes[path].toLowerCase();

    const canRedirectConditions =
      pathValue !== routes.HOME && pathValue !== routes.NOT_FOUND && slug.includes(pathValue) && !isPublicSlug(slug);

    if (canRedirectConditions) {
      return true;
    }
  }
  return false;
};
