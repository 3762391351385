import { OrderStatus } from '@Types/shamrockApi/Order';
import {
  isCancelled,
  isDeliveredComplete,
  isOutForDelivery,
  isProcessing,
  isReadyForPickup,
  isSubmitted,
} from 'composable/helpers/utils/order-status';

/**
 * Gives a value for each status. Lowest values are displayed first
 * @param status Order status
 * @returns number value to use for comparison.
 */
export const orderStatusSortingValues = (status: OrderStatus): number => {
  if (!status || status === '') {
    return 5;
  }

  if (isOutForDelivery(status) || isReadyForPickup(status)) {
    return 1;
  }

  if (isProcessing(status)) {
    return 2;
  }

  if (isSubmitted(status)) {
    return 3;
  }

  if (isDeliveredComplete(status)) {
    return 4;
  }

  if (isCancelled(status)) {
    return 6;
  }

  return 5;
};
