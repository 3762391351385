import { OGMLocalStorageValues } from '../types';

export const AUTOCOMPLETE_MAXIMUM_RESULTS = 5;
export const AUTOCOMPLETE_MINIMUM_CHARACTERS = 3;
export const SLICE_LIMIT_NO_COPIES = 30;
export const SLICE_LIMIT_WITH_COPIES = 26;

export const SORTS_KEYS_OGM = {
  asc: 'asc',
  desc: 'desc',
  highestItemCount: 'highestItemCount',
  lowestItemCount: 'lowestItemCount',
  newestDateModified: 'newestDateModified',
  oldestDateModified: 'oldestDateModified',
};

export const OGM_LOCAL_STORAGE_INITIAL_STATE: OGMLocalStorageValues = {
  searchTerm: '',
  selectedSortOptionOrderGuideManagement: {
    selectedSort: 'asc',
  },
  orderGuideName: '',
  accountName: '',
  comesFromOGM: false,
  scrollPosition: 0,
};

export const exportOGMOptions = ['PDF', 'Excel', 'Word'] as const;
