import { Product } from '@Types/product/Product';
import { Result } from '@Types/product/Result';
import { ProductQuery } from '@Types/query/ProductQuery';
import { mutate } from 'swr';
import { fetchApiHub } from 'frontastic';
import { GENERIC_TOAST_ERROR_ID, TOAST_ICON } from 'composable/components/general';
import { useToast } from '@chakra-ui/react';

export const getProduct = async (query: ProductQuery, toast: ReturnType<typeof useToast>, toastMessage: string) => {
  try {
    const res = await fetchApiHub(
      '/action/product/getProduct',
      {
        method: 'GET',
      },
      query,
    );
    mutate('/action/product/getProduct', res, { revalidate: false });
  } catch (error) {
    console.error('Error API: getProduct', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    throw error;
  }
};

export const query = async (
  queryParams: ProductQuery,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
): Promise<Product[]> => {
  try {
    const route = `/action/product/query?limit=${queryParams.limit}`;
    const res = (await fetchApiHub(
      route,
      {
        method: 'POST',
      },
      { ...queryParams },
    )) as Result;
    mutate(route, res, { revalidate: false });
    return res.items as Product[];
  } catch (error) {
    console.error('Error API: query', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
      console.warn("Error toast shown", { reason: `/action/product/query?limit=${queryParams.limit}` });
    }
    throw error;
  }
};
