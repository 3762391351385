import axios from 'axios';

export type ShamrockSWRFetcherOptions<TPayload> = {
  payload: TPayload;
  accessToken: string;
};

export const shamrockSWRFetcher = async <TPayload, TReponse>(
  url: string,
  { payload, accessToken }: ShamrockSWRFetcherOptions<TPayload>,
): Promise<TReponse> => {
  try {
    const { data } = await axios<TReponse>({
      method: 'POST',
      url,
      headers: { Authorization: `Bearer ${accessToken}` },
      data: payload,
    });
    return data;
  } catch (error) {
    throw new Error('Error fetching Shamrock Gateway API data.', error);
  }
};
