/**
 * Use the discountedPrice if available and cheaper than the current price
 * @param {number} currentPrice
 * @param {number} discountedPrice
 * @returns {number}
 */
export const getPrice = (currentPrice: number, discountedPrice: number) => {
  if (currentPrice == 0 || discountedPrice == 0) {
    return 0;
  }
  if (currentPrice == discountedPrice) {
    return currentPrice;
  }
  if (currentPrice > discountedPrice) {
    return discountedPrice;
  }
};
