import { LineItem } from '@Types/cart/LineItem';
import { OrderDetailLineItem } from '@Types/shamrockApi/Order';
import { ExtraProductData } from '@Types/shamrockApi/Product';

export const mapLineItemsToProducts = (products: ExtraProductData[], items: LineItem[] | OrderDetailLineItem[]) => {
  return (items as any).filter((item) =>
    products.some(
      (product) => product?.productNumber === item?.productNumber || product?.productNumber === item?.variant?.sku,
    ),
  );
};

export const mapLineItemsToProductsByProductName = (products: ExtraProductData[], items: OrderDetailLineItem[]) => {
  return items.filter((item) => products.some((product) => product?.productNumber === item?.productNumber));
};
