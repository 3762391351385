import { ComponentStyleConfig } from '@chakra-ui/react';

const Alert: ComponentStyleConfig = {
  baseStyle: {
    container: {
      px: 3,
      py: 2,
    },
    title: {
      fontSize: 'sm',
      fontWeight: 'bold',
      lineHeight: '120%',
      mt: 1,
    },
    containerStyle: {
      pointerEvents: 'none',
    },
  },
  variants: {
    subtle: (props) => {
      const { colorScheme: c } = props;

      if (c === 'green') {
        return {
          container: {
            bg: 'success-light',
          },
          icon: { color: 'success-med' },
          title: {
            color: 'success-dark',
          },
        };
      }

      if (c === 'red') {
        return {
          container: {
            bg: 'danger-light',
          },
          icon: { color: 'danger-med' },
          title: {
            color: 'danger-dark',
          },
        };
      }

      if (c === 'orange') {
        return {
          container: {
            bg: 'yellow.100',
          },
          icon: { color: 'yellow.500' },
          title: {
            color: 'yellow.800',
          },
        };
      }
    },
  },
};

export default Alert;
