import { datadogRum } from '@datadog/browser-rum';
import {
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  DATADOG_REPLAY_SAMPLE_RATE,
  DATADOG_SESSION_SAMPLE_RATE,
  DATADOG_SERVICE,
  DATADOG_SITE,
  APPLICATION_VERSION,
  DATADOG_ENVIRONMENT,
} from 'composable/components/general';

export function initializeRUM() {
  if (DATADOG_APPLICATION_ID == null || DATADOG_APPLICATION_ID == '') {
    console.warn("Datadog application id not defined. Data won't flow to Datadog");
    return;
  }
  datadogRum.init({
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: DATADOG_SITE,
    service: DATADOG_SERVICE,
    env: DATADOG_ENVIRONMENT,
    version: APPLICATION_VERSION,
    sessionSampleRate: DATADOG_SESSION_SAMPLE_RATE,
    sessionReplaySampleRate: DATADOG_REPLAY_SAMPLE_RATE,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}
