import { extendTheme } from '@chakra-ui/react';
import * as components from './theme/components';
import foundations from './theme/foundations';
import colors from './theme/foundations/colors';
import { semanticTokens } from './theme/foundations/semanticTokens';
import layerStyles from './theme/layerStyles';
import styles from './theme/styles';
import textStyles from './theme/textStyles';

export const theme = extendTheme({
  ...foundations,
  textStyles, // text variants
  layerStyles, // style variants
  styles, // Global style override
  colors,
  semanticTokens,
  components: {
    ...components,
  },
});
