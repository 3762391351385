import { ProductDataExtended } from '../helpers';
import { Product } from '@Types/product/Product';
import { ExtraProductData } from '@Types/shamrockApi/Product';

export const sortProductsByLastPurchase = (
  a: { ctInformation: Product; extraInformation: ExtraProductData },
  b: { ctInformation: Product; extraInformation: ExtraProductData },
): number => {
  const { extraInformation: aExtra, ctInformation: aCt } = a;
  const { extraInformation: bExtra, ctInformation: bCt } = b;
  const dateA = aExtra.purchaseHistory?.data?.lastPurchase?.date || '';
  const dateB = bExtra.purchaseHistory?.data?.lastPurchase?.date || '';

  if (dateA === '' && dateB === '') {
    return 0;
  }
  if (dateA === '') {
    return 1;
  }
  if (dateB === '') {
    return -1;
  }

  return new Date(dateB).getTime() - new Date(dateA).getTime() || aCt?.name?.localeCompare(bCt.name);
};
