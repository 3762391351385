
import { switchAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys)

export default {
  baseStyle: definePartsStyle({
    // define the part you're going to style
    // container: {
    //   // ...
    // },
    thumb: {
      bg: 'white',
    },
    track: {
      bg: '#778',
      _checked: {
        bg: 'primary',
      },
    },
  })
}
