import { useEffect, useMemo, useRef, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { useFormat } from 'helpers/hooks/useFormat';
import { getProductExtraData } from 'helpers/utils/eventTracking';
import { UseUserGlobalResponse } from 'hooks/global/use_privateUserGlobal/types';
import { Cache } from 'swr';
import { useShouldExecuteApi } from './useHandleCancelApiCall';
import { useGlobal } from 'components/globalProvider';

const useGetExtraProductDataExecutable = (skus: string[], user: UseUserGlobalResponse['state'], cache?: Cache) => {
  const { isPrintBack } = useGlobal();
  const [loadingExtraData, setLoadingExtraData] = useState(false);
  const [extraProductData, setExtraProductData] = useState([]);
  const skusString = useMemo(() => skus?.join(), [skus]);
  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });

  const timeoutRef = useRef(null);

  const getExtraData = async () => {
    if (!!skusString && !isPrintBack) {
      setLoadingExtraData(true);
      const extraData = await getProductExtraData(skus, user, toast, formatMessage({ id: 'app.generic.error' }), cache);
      setLoadingExtraData(false);
      if (extraData) {
        const filteredExtraData = extraData.map((data) => data.productExtraData);
        setExtraProductData(filteredExtraData);
      }
    }
  };

  useEffect(() => {
    // using timeout to prevent multiple requests
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      getExtraData();
    }, 500);
  }, [skusString]);

  return {
    isLoadingExtraData: loadingExtraData,
    extraProductData,
  };
};

//eslint-disable-next-line
export const useGetExtraProductData = useShouldExecuteApi(useGetExtraProductDataExecutable, {
  isLoadingExtraData: false,
  extraProductData: {},
});
