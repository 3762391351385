import { defineStyleConfig } from '@chakra-ui/react';

const isIconButton = (variant: string) => {
  return variant?.includes('ds-icon-button');
};

const isIconTextLink = (variant: string) => {
  return variant?.includes('ds-icon-text-link');
};

const setSvgProperties = (width: string, height: string) => ({ width, height });

export default defineStyleConfig({
  baseStyle: (props) => ({
    borderRadius: isIconButton(props?.variant) ? '4px' : '6px',
  }),
  sizes: {
    xs: {
      height: '24px',
      textStyle: 'body-default',
    },
    sm: {
      height: '32px',
      textStyle: 'Mobile/XS',
    },
    md: {
      height: '40px',
      textStyle: 'Mobile/XS',
    },
    lg: {
      height: '48px',
      textStyle: 'Mobile/S',
    },
    xl: {
      height: { sm: '48px', base: '40px' },
      fontSize: { sm: 'md', base: 'base' },
      paddingInline: { sm: '24px', base: '16px' },
    },
    'ds-xs': (props) => ({
      height: isIconButton(props?.variant) ? '1.25rem' : 'auto',
      maxH: isIconButton(props?.variant) ? '1.25rem' : 'auto',
      padding: isIconButton(props?.variant) ? '0' : 'auto',
      width: isIconButton(props?.variant) ? '1.25rem' : 'auto',
      maxW: isIconButton(props?.variant) ? '1.25rem' : 'auto',
      svg: setSvgProperties('0.75rem', '0.75rem'),
    }),
    'ds-sm': (props) => ({
      height: isIconTextLink(props?.variant) ? '1.125rem' : '1.5rem',
      maxH: isIconTextLink(props?.variant) ? '1.125rem' : '1.5rem',
      padding: isIconTextLink(props?.variant) ? '0' : isIconButton(props?.variant) ? '0' : '0.625rem 0.5rem',
      lineHeight: '0.9rem',
      fontSize: '0.75rem',
      width: isIconButton(props?.variant) ? '1.5rem' : 'auto',
      maxW: isIconButton(props?.variant) ? '1.5rem' : 'auto',
      svg: setSvgProperties('1rem', '1rem'),
    }),
    'ds-md': (props) => ({
      height: isIconTextLink(props?.variant) ? '1.5rem' : '2rem',
      maxH: isIconTextLink(props?.variant) ? '1.5rem' : '2rem',
      padding: isIconTextLink(props?.variant) ? '0' : isIconButton(props?.variant) ? '0' : '0.625rem 0.75rem',
      lineHeight: '1.05rem',
      fontSize: '0.875rem',
      width: isIconButton(props?.variant) ? '2rem' : 'auto',
      maxW: isIconButton(props?.variant) ? '2rem' : 'auto',
      svg: setSvgProperties('1rem', '1rem'),
    }),
    'ds-lg': (props) => ({
      height: isIconTextLink(props?.variant) ? '2rem' : '2.5rem',
      maxH: isIconTextLink(props?.variant) ? '2rem' : '2.5rem',
      padding: isIconTextLink(props?.variant) ? '0' : isIconButton(props?.variant) ? '0' : '0.625rem 1rem',
      lineHeight: '1.2rem',
      fontSize: '1rem',
      width: isIconButton(props?.variant) ? '2.5rem' : 'auto',
      maxW: isIconButton(props?.variant) ? '2.5rem' : 'auto',
      svg: setSvgProperties('1.25rem', '1.25rem'),
    }),
    'ds-xl': (props) => ({
      height: isIconTextLink(props?.variant) ? '2.5rem' : '3rem',
      maxH: isIconTextLink(props?.variant) ? '2.5rem' : '3rem',
      padding: isIconTextLink(props?.variant) ? '0' : isIconButton(props?.variant) ? '0' : '0.625rem 1.5rem',
      lineHeight: '1.35rem',
      fontSize: '1.125rem',
      width: isIconButton(props?.variant) ? '3rem' : 'auto',
      maxW: isIconButton(props?.variant) ? '3rem' : 'auto',
      svg: setSvgProperties('1.5rem', '1.5rem'),
    }),
  },
  variants: {
    solid: {
      color: 'background',
      background: 'primary',
      _hover: {
        bg: 'primary',
        _disabled: {
          bg: 'text-muted',
        },
      },
      _active: {
        bg: 'primary',
      },
      _disabled: {
        opacity: 0.5,
        bg: 'text-muted',
      },
    },
    outline: {
      color: 'primary',
      borderColor: 'primary',
      _hover: {
        bg: 'muted',
        borderColor: 'primary',
        _disabled: {
          color: 'muted',
          borderColor: 'muted',
        },
      },
      _active: {
        bg: 'accent',
        borderColor: 'accent',
      },
      _disabled: {
        opacity: 1,
        color: 'text-muted',
        borderColor: 'text-muted',
      },
    },
    ghost: {
      color: 'primary',
      borderColor: 'primary',
      textDecoration: 'underline',
      textUnderlineOffset: '3px',
      __before: {
        content: '" "',
        display: 'block',
        width: '10px',
        height: '10px',
        background: 'red',
      },
      _hover: {
        bg: 'background',
        borderColor: 'text-muted',
      },
      _active: {
        bg: 'text',
        borderColor: 'text-muted',
      },
      _disabled: {
        opacity: 1,
        color: 'text-muted',
        borderColor: 'text-muted',
      },
    },

    // Button styles
    'ds-filled': {
      display: 'inline-flex',
      justifyContent: 'center',
      gap: '8px',
      flexShrink: 0,
      background: 'primary.500',
      bgGradient: 'linear-gradient(90deg, primary.500 0%, green.1000 100%)',
      color: 'white',
      fontWeight: 'bold',
      textShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
      boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.12)',
      _hover: {
        background: 'primary.500',
        textShadow: 'none',
        boxShadow: 'none',
      },
      _disabled: {
        background: 'neutral.400 !important',
        color: 'neutral.700',
        textShadow: 'none',
        boxShadow: 'none',
        opacity: 1,
      },
      _focus: {
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
      },
      _focusVisible: {
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
        boxShadow: 'none',
      },
      _loading: {
        background: 'neutral.400',
        color: 'white',
      },
      span: {
        margin: 0,
      },
    },
    'ds-ghost': (props) => ({
      display: 'inline-flex',
      justifyContent: 'center',
      gap: '8px',
      border: 'double 1px transparent',
      backgroundClip: 'padding-box, border-box',
      backgroundImage: `linear-gradient(${props.bgColor}, ${props.bgColor}), linear-gradient(90deg, primary.500 0%, green.1000 100%)`,
      backgroundOrigin: 'border-box',
      color: 'white',
      fontWeight: 700,
      _hover: {
        borderColor: 'primary.500',
        borderWidth: '2px',
        px: `${paddingX[props.size] - 0.063}rem`,
        _disabled: {
          borderColor: 'transparent',
          borderWidth: '1px',
          px: `${paddingX[props.size]}rem`,
        },
      },
      _disabled: {
        borderColor: 'neutral.500 !important',
        color: 'neutral.500',
        borderWidth: '1px',
        opacity: 1,
      },
      _focus: {
        borderColor: 'green.1000 !important',
        borderWidth: '1px',
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
        px: `${paddingX[props.size]}rem`,
      },
      _focusVisible: {
        borderColor: 'green.1000 !important',
        borderWidth: '1px',
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
        boxShadow: 'none',
        px: `${paddingX[props.size]}rem`,
      },
      _loading: {
        borderWidth: '1px',
        borderColor: 'neutral.500',
        color: 'white',
      },
      span: {
        margin: 0,
      },
    }),
    'ds-ghost-on-light': (props) => ({
      display: 'inline-flex',
      justifyContent: 'center',
      gap: '8px',
      border: 'double 1px transparent',
      backgroundClip: 'padding-box, border-box',
      backgroundImage: `linear-gradient(${props.bgColor}, ${props.bgColor}), linear-gradient(90deg, primary.500 0%, green.1000 100%)`,
      backgroundOrigin: 'border-box',
      color: 'neutral.900',
      fontWeight: 700,

      _hover: {
        borderColor: 'primary.500',
        borderWidth: '2px',
        px: `${paddingX[props.size] - 0.063}rem`,
        _disabled: {
          borderColor: 'transparent',
          borderWidth: '1px',
          px: `${paddingX[props.size]}rem`,
        },
      },
      _disabled: {
        borderColor: 'neutral.400 !important',
        color: 'neutral.400',
        borderWidth: '1px',
        opacity: 1,
      },
      _focus: {
        borderColor: 'green.1000 !important',
        borderWidth: '1px',
        outlineColor: 'violet.500',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
        px: `${paddingX[props.size]}rem`,
      },
      _focusVisible: {
        borderColor: 'green.1000 !important',
        borderWidth: '1px',
        outlineColor: 'violet.500',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
        boxShadow: 'none',
        px: `${paddingX[props.size]}rem`,
      },
      _loading: {
        borderWidth: '1px',
        borderColor: 'neutral.400',
        color: 'neutral.900',
      },
      span: {
        margin: 0,
      },
    }),
    'ds-outlined': (props) => ({
      display: 'inline-flex',
      justifyContent: 'center',
      gap: '8px',
      borderColor: 'green.1000',
      borderStyle: 'solid',
      borderWidth: '1px',
      color: 'white',
      fontWeight: 700,
      _hover: {
        borderColor: 'primary.500',
        borderWidth: '2px',
        px: `${paddingX[props.size] - 0.063}rem`,
        _disabled: {
          borderColor: 'transparent',
          borderWidth: '1px',
          px: `${paddingX[props.size]}rem`,
        },
      },
      _disabled: {
        borderColor: 'neutral.500 !important',
        color: 'neutral.500',
        borderWidth: '1px',
        opacity: 1,
      },
      _focus: {
        borderColor: 'green.1000 !important',
        borderWidth: '1px',
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
        px: `${paddingX[props.size]}rem`,
      },
      _focusVisible: {
        borderColor: 'green.1000 !important',
        borderWidth: '1px',
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
        boxShadow: 'none',
        px: `${paddingX[props.size]}rem`,
      },
      _loading: {
        borderWidth: '1px',
        borderColor: 'neutral.500',
        color: 'white',
      },
      span: {
        margin: 0,
      },
    }),
    'ds-default': {
      fontSize: 'base',
      borderRadius: '6px',
      fontWeight: 'extrabold',
      border: 'double 1px transparent',
      backgroundClip: 'padding-box, border-box',
      backgroundImage: 'linear-gradient(white, white), linear-gradient(90deg, primary.500 0%, green.1000 100%)',
      color: { base: 'neutral.900', xl: 'black' },
      lineHeight: '120%',
      _hover: {
        borderColor: 'primary.500',
        borderWidth: '2px',
      },
      _focus: {
        borderColor: 'green.1000 !important',
        borderWidth: '1px',
        outlineColor: 'violet.500',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
      },
      _focusVisible: {
        outline: '1px solid primary.300',
      },
      _disabled: {
        color: 'neutral.400',
        borderColor: 'neutral.400',
        textShadow: 'unset',
        _hover: {
          borderWidth: '1px',
        }
      },
    },

    // Icon text link styles
    'ds-icon-text-link-light': {
      display: 'inline-flex',
      justifyContent: 'center',
      gap: '8px',
      color: 'neutral.900',
      fontWeight: 700,
      _hover: {
        color: 'primary.600',
        _disabled: {
          color: 'neutral.500',
          opacity: 1,
        },
      },
      _disabled: {
        color: 'neutral.500',
        opacity: 1,
      },
      _focus: {
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        borderRadius: '2px',
      },
      _focusVisible: {
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        boxShadow: 'none',
        outlineOffset: '0px',
        borderRadius: '2px',
      },
      _loading: { color: 'neutral.900' },
      span: { margin: 0 },
    },
    'ds-icon-text-link-dark': {
      display: 'inline-flex',
      justifyContent: 'center',
      gap: '8px',
      color: 'white',
      fontWeight: 700,
      _hover: {
        color: 'primary.400',
        _disabled: {
          color: 'neutral.500',
          opacity: 1,
        },
      },
      _disabled: {
        color: 'neutral.500',
        opacity: 1,
      },
      _focus: {
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        borderRadius: '2px',
      },
      _focusVisible: {
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '0px',
        boxShadow: 'none',
        borderRadius: '2px',
      },
      _loading: { color: 'white' },
      span: { margin: 0 },
    },

    // Icon button styles
    'ds-icon-button-default-filled': {
      display: 'inline-flex',
      justifyContent: 'center',
      gap: '8px',
      flexShrink: 0,
      background: 'primary.500',
      bgGradient: 'linear-gradient(90deg, primary.500 0%, green.1000 100%)',
      color: 'white',
      fontWeight: 'bold',
      textShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
      boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.12)',
      _hover: {
        background: 'primary.500',
        textShadow: 'none',
        boxShadow: 'none',
      },
      _disabled: {
        background: 'neutral.400 !important',
        color: 'neutral.700',
        textShadow: 'none',
        boxShadow: 'none',
        opacity: 1,
      },
      _focus: {
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
      },
      _focusVisible: {
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
        boxShadow: 'none',
      },
      _loading: {
        background: 'neutral.400',
        color: 'white',
      },
      span: {
        margin: 0,
      },
    },
    'ds-icon-button-default-ghost': (props) => ({
      display: 'inline-flex',
      justifyContent: 'center',
      gap: '8px',
      border: 'double 1px transparent',
      backgroundClip: 'padding-box, border-box',
      backgroundImage: `linear-gradient(${props.bgColor}, ${props.bgColor}), linear-gradient(90deg, primary.500 0%, green.1000 100%)`,
      backgroundOrigin: 'border-box',
      color: 'white',
      fontWeight: 700,
      _hover: {
        borderColor: 'primary.500',
        borderWidth: '2px',
        _disabled: {
          borderColor: 'transparent',
          borderWidth: '1px',
        },
      },
      _disabled: {
        borderColor: 'neutral.500 !important',
        color: 'neutral.500',
        borderWidth: '1px',
        opacity: 1,
      },
      _focus: {
        borderColor: 'green.1000 !important',
        borderWidth: '1px',
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
      },
      _focusVisible: {
        borderColor: 'green.1000 !important',
        borderWidth: '1px',
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
        boxShadow: 'none',
      },
      _loading: {
        borderWidth: '1px',
        borderColor: 'neutral.500',
        color: 'white',
      },
      span: {
        margin: 0,
      },
    }),
    'ds-icon-button-pressed-filled': {
      display: 'inline-flex',
      justifyContent: 'center',
      gap: '8px',
      backgroundColor: 'primary.700',
      color: 'white',
      fontWeight: 700,
      borderColor: 'primary.700',
      _hover: {
        borderColor: 'primary.500',
        borderWidth: '2px',
        _disabled: {
          borderColor: 'transparent',
          borderWidth: '1px',
        },
      },
      _disabled: {
        background: 'neutral.400 !important',
        color: 'neutral.700',
        borderColor: 'neutral.400',
        textShadow: 'none',
        boxShadow: 'none',
        opacity: 1,
      },
      _focus: {
        borderColor: 'green.1000 !important',
        borderWidth: '1px',
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
      },
      _focusVisible: {
        borderColor: 'green.1000 !important',
        borderWidth: '1px',
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
        boxShadow: 'none',
      },
      _loading: {
        borderWidth: '1px',
        borderColor: 'neutral.500',
        color: 'white',
      },
      span: {
        margin: 0,
      },
    },
    'ds-icon-button-pressed-ghost': {
      display: 'inline-flex',
      justifyContent: 'center',
      gap: '8px',
      color: 'white',
      fontWeight: 700,
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'primary.700',
      _hover: {
        borderColor: 'primary.500',
        borderWidth: '2px',
        _disabled: {
          borderColor: 'transparent',
          borderWidth: '1px',
        },
      },
      _disabled: {
        borderColor: 'neutral.500 !important',
        color: 'neutral.500',
        borderWidth: '1px',
        opacity: 1,
      },
      _focus: {
        borderColor: 'green.1000 !important',
        borderWidth: '1px',
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
      },
      _focusVisible: {
        borderColor: 'green.1000 !important',
        borderWidth: '1px',
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
        boxShadow: 'none',
      },
      _loading: {
        borderWidth: '1px',
        borderColor: 'neutral.500',
        color: 'white',
      },
      span: {
        margin: 0,
      },
    },
    'ds-icon-button-light-muted-filled': {
      display: 'inline-flex',
      justifyContent: 'center',
      gap: '8px',
      backgroundColor: 'neutral.50',
      color: 'primary.500',
      fontWeight: 700,
      _hover: {
        borderColor: 'primary.500',
        borderWidth: '2px',
        _disabled: {
          borderColor: 'transparent',
          borderWidth: '1px',
        },
      },
      _disabled: {
        background: 'neutral.400 !important',
        color: 'neutral.700',
        borderColor: 'neutral.400',
        textShadow: 'none',
        boxShadow: 'none',
        opacity: 1,
      },
      _focus: {
        borderColor: 'green.1000 !important',
        borderWidth: '1px',
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
      },
      _focusVisible: {
        borderColor: 'green.1000 !important',
        borderWidth: '1px',
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
        boxShadow: 'none',
      },
      _loading: {
        borderWidth: '1px',
        borderColor: 'neutral.500',
        color: 'white',
      },
      span: {
        margin: 0,
      },
    },
    'ds-icon-button-light-muted-ghost': {
      display: 'inline-flex',
      justifyContent: 'center',
      gap: '8px',
      borderColor: 'neutral.300',
      borderWidth: '1px',
      borderStyle: 'solid',
      color: 'primary.500',
      fontWeight: 700,
      _hover: {
        borderColor: 'primary.500',
        borderWidth: '2px',
        _disabled: {
          borderColor: 'transparent',
          borderWidth: '1px',
        },
      },
      _disabled: {
        borderColor: 'neutral.500 !important',
        color: 'neutral.500',
        borderWidth: '1px',
        opacity: 1,
      },
      _focus: {
        borderColor: 'green.1000 !important',
        borderWidth: '1px',
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
      },
      _focusVisible: {
        borderColor: 'green.1000 !important',
        borderWidth: '1px',
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
        boxShadow: 'none',
      },
      _loading: {
        borderWidth: '1px',
        borderColor: 'neutral.500',
        color: 'white',
      },
      span: {
        margin: 0,
      },
    },
    'ds-icon-button-dark-muted-filled': {
      display: 'inline-flex',
      justifyContent: 'center',
      gap: '8px',
      backgroundColor: 'neutral.600',
      borderColor: 'neutral.600',
      color: 'primary.400',
      fontWeight: 700,
      _hover: {
        borderColor: 'primary.500',
        borderWidth: '2px',
        _disabled: {
          borderColor: 'transparent',
          borderWidth: '1px',
        },
      },
      _disabled: {
        background: 'neutral.400 !important',
        color: 'neutral.700',
        borderColor: 'neutral.400',
        textShadow: 'none',
        boxShadow: 'none',
        opacity: 1,
      },
      _focus: {
        borderColor: 'green.1000 !important',
        borderWidth: '1px',
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
      },
      _focusVisible: {
        borderColor: 'green.1000 !important',
        borderWidth: '1px',
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
        boxShadow: 'none',
      },
      _loading: {
        borderWidth: '1px',
        borderColor: 'neutral.500',
        color: 'white',
      },
      span: {
        margin: 0,
      },
    },
    'ds-icon-button-dark-muted-ghost': {
      display: 'inline-flex',
      justifyContent: 'center',
      gap: '8px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'neutral.700',
      color: 'primary.400',
      fontWeight: 700,
      _hover: {
        borderColor: 'primary.500',
        borderWidth: '2px',
        _disabled: {
          borderColor: 'transparent',
          borderWidth: '1px',
        },
      },
      _disabled: {
        borderColor: 'neutral.500 !important',
        color: 'neutral.500',
        borderWidth: '1px',
        opacity: 1,
      },
      _focus: {
        borderColor: 'green.1000 !important',
        borderWidth: '1px',
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
      },
      _focusVisible: {
        borderColor: 'green.1000 !important',
        borderWidth: '1px',
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
        boxShadow: 'none',
      },
      _loading: {
        borderWidth: '1px',
        borderColor: 'neutral.500',
        color: 'white',
      },
      span: {
        margin: 0,
      },
    },
  },
  defaultProps: {},
});

// padding values in rem unit
const paddingX = {
  'ds-sm': 0.5,
  'ds-md': 0.75,
  'ds-lg': 1,
  'ds-xl': 1.5,
};
