export class StringHelpers {
  /**
   * isNumeric tests a string and returns true when it's a decimal value
   */
  static isNumeric = (val: string) => /^\d+$/.test(val);

  /**
   * capitaliseFirstLetter capitalises only the very first character of a string, leaving the
   * rest unedited
   */
  static capitaliseFirstLetter = (val: string) => val.charAt(0).toUpperCase() + val.slice(1);

  /**
   * capitaliseEveryWord capitalises every word in a string
   * @param text
   * @returns string
   */
  static capitaliseEveryWord = (text: string) => {
    if (!text) return '';
    const textSplit = text.toLowerCase().split(' ');
    return textSplit.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  /**
   * formatPhoneNumber takes a string and returns it in the format of 123-456-7890
   * @param phoneNumber
   * @returns string
   */
  static formatPhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber) return '';
    const phoneCleaned = phoneNumber.replace(/[^a-zA-Z0-9\+]/g, '');

    const remainingChars = phoneCleaned.length - 10;
    const regexPattern =
      remainingChars > 0
        ? new RegExp('([a-zA-Z0-9+]{3})([a-zA-Z0-9+]{3})([a-zA-Z0-9+]{4})([a-zA-Z0-9+]{' + remainingChars + '})')
        : new RegExp('([a-zA-Z0-9+]{3})([a-zA-Z0-9+]{3})([a-zA-Z0-9+]{4})');
    const replacePattern = remainingChars > 0 ? '$1-$2-$3 $4' : '$1-$2-$3';

    return phoneCleaned.replace(regexPattern, replacePattern);
  };
}
