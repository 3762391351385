import { Storefront, Truck } from 'phosphor-react';
import { ShareFatIcon } from './share-fat-icon';

//The keys are coming from the CT shipping methods
export const KEY_SCHEDULED_DELIVERY = 'standard-shipping-method';
export const KEY_PICK_UP = 'willcall-shipping-method';
export const KEY_A_LA_CARTE = 'alc-shipping-method';
export const KEY_NAME_SCHEDULED_DELIVERY = 'Standard shipping method';
export const KEY_NAME_PICK_UP = 'Will Call shipping method';
export const KEY_NAME_A_LA_CARTE = 'A La Carte shipping method';

export const ORDER_TYPE_FOR_SCHEDULED_DELIVERY = 'Standard';
export const ORDER_TYPE_FOR_PICK_UP = 'Rtd_Wc';
export const ORDER_TYPE_FOR_A_LA_CARTE = 'Flxmetro';

export const DEFAULT_SHIPPING_METHOD = 'standard-shipping-method';

export const DELIVERY_METHODS_INFO = {
  [KEY_SCHEDULED_DELIVERY]: {
    label: 'checkout.deliveryMethodSection.scheduled',
    icon: Truck,
  },
  [KEY_PICK_UP]: {
    label: 'checkout.deliveryMethodSection.pickUp',
    icon: Storefront,
  },
  [KEY_A_LA_CARTE]: {
    label: 'checkout.deliveryMethodSection.aLaCarte',
    icon: ShareFatIcon,
  },
};

export const LABELS_BY_DELIVERY_METHOD = {
  [KEY_SCHEDULED_DELIVERY]: 'deliveryDates.title.scheduled',
  [KEY_PICK_UP]: 'deliveryDates.title.pickup',
  [KEY_A_LA_CARTE]: 'deliveryDates.title.aLaCarte',
};
