import axios, { AxiosError } from 'axios';
import { OrderGuideMultipleCustomers } from 'composable/components/order-guide-management/components';
import { REQUEST_TIMEOUT, handleAxiosError } from 'composable/components/order-guide/utils/fetchers';

// TODO replace by Shamrock generic Fetcher
export const orderGuidesByCustomerNumbers = async ([url, { payload, accessToken, logout }]) => {
  try {
    const { data } = await axios<Array<OrderGuideMultipleCustomers>>({
      method: 'POST',
      url,
      timeout: REQUEST_TIMEOUT,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: { ...payload },
    });
    return data || [];
  } catch (error) {
    console.error('Error API call: orderGuidesByCustomerNumbers', error);
    if (error instanceof AxiosError) {
      handleAxiosError(error, logout);
    }
    throw error;
  }
};
