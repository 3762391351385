import { createIcon } from '@chakra-ui/react';

export const WarningIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.1991 17.6335L14.0006 3.39569C13.7957 3.04687 13.5033 2.75764 13.1522 2.55668C12.8011 2.35572 12.4036 2.25 11.9991 2.25C11.5945 2.25 11.197 2.35572 10.8459 2.55668C10.4948 2.75764 10.2024 3.04687 9.99749 3.39569L1.79905 17.6335C1.60193 17.9709 1.49805 18.3546 1.49805 18.7454C1.49805 19.1361 1.60193 19.5199 1.79905 19.8572C2.0013 20.2082 2.29327 20.499 2.64502 20.6998C2.99676 20.9006 3.3956 21.0043 3.80061 21.0001H20.1975C20.6022 21.0039 21.0006 20.9001 21.352 20.6993C21.7034 20.4985 21.9951 20.2079 22.1972 19.8572C22.3946 19.52 22.4988 19.1364 22.4991 18.7456C22.4995 18.3549 22.3959 17.9711 22.1991 17.6335ZM11.2491 9.75006C11.2491 9.55115 11.3281 9.36038 11.4687 9.21973C11.6094 9.07908 11.8001 9.00006 11.9991 9.00006C12.198 9.00006 12.3887 9.07908 12.5294 9.21973C12.67 9.36038 12.7491 9.55115 12.7491 9.75006V13.5001C12.7491 13.699 12.67 13.8897 12.5294 14.0304C12.3887 14.171 12.198 14.2501 11.9991 14.2501C11.8001 14.2501 11.6094 14.171 11.4687 14.0304C11.3281 13.8897 11.2491 13.699 11.2491 13.5001V9.75006ZM11.9991 18.0001C11.7765 18.0001 11.559 17.9341 11.374 17.8105C11.189 17.6868 11.0448 17.5111 10.9597 17.3056C10.8745 17.1 10.8523 16.8738 10.8957 16.6556C10.9391 16.4374 11.0462 16.2369 11.2036 16.0796C11.3609 15.9222 11.5613 15.8151 11.7796 15.7717C11.9978 15.7283 12.224 15.7505 12.4296 15.8357C12.6351 15.9208 12.8108 16.065 12.9345 16.25C13.0581 16.4351 13.1241 16.6526 13.1241 16.8751C13.1241 17.1734 13.0055 17.4596 12.7945 17.6706C12.5836 17.8815 12.2974 18.0001 11.9991 18.0001Z"
      fill="currentColor"
    />
  </svg>
);

export const WarningDiamondIcon = (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.0627 9.94242L12.058 0.936799C11.777 0.657423 11.3968 0.50061 11.0005 0.50061C10.6042 0.50061 10.2241 0.657423 9.94303 0.936799L0.943025 9.94242C0.663649 10.2235 0.506836 10.6036 0.506836 10.9999C0.506836 11.3962 0.663649 11.7764 0.943025 12.0574L9.94771 21.063C10.2288 21.3424 10.6089 21.4992 11.0052 21.4992C11.4015 21.4992 11.7817 21.3424 12.0627 21.063L21.0674 12.0574C21.3468 11.7764 21.5036 11.3962 21.5036 10.9999C21.5036 10.6036 21.3468 10.2235 21.0674 9.94242H21.0627ZM10.2505 6.49992C10.2505 6.30101 10.3295 6.11025 10.4702 5.96959C10.6108 5.82894 10.8016 5.74992 11.0005 5.74992C11.1994 5.74992 11.3902 5.82894 11.5309 5.96959C11.6715 6.11025 11.7505 6.30101 11.7505 6.49992V11.7499C11.7505 11.9488 11.6715 12.1396 11.5309 12.2803C11.3902 12.4209 11.1994 12.4999 11.0005 12.4999C10.8016 12.4999 10.6108 12.4209 10.4702 12.2803C10.3295 12.1396 10.2505 11.9488 10.2505 11.7499V6.49992ZM11.0005 16.2499C10.778 16.2499 10.5605 16.1839 10.3755 16.0603C10.1905 15.9367 10.0463 15.761 9.96116 15.5554C9.87601 15.3499 9.85373 15.1237 9.89714 14.9054C9.94055 14.6872 10.0477 14.4868 10.205 14.3294C10.3624 14.1721 10.5628 14.0649 10.781 14.0215C10.9993 13.9781 11.2255 14.0004 11.431 14.0856C11.6366 14.1707 11.8123 14.3149 11.9359 14.4999C12.0595 14.6849 12.1255 14.9024 12.1255 15.1249C12.1255 15.4233 12.007 15.7094 11.796 15.9204C11.585 16.1314 11.2989 16.2499 11.0005 16.2499Z"
      fill="#F5AD23"
    />
  </svg>
);

export const WarningDiamond = createIcon({
  displayName: 'WarningDiamond',
  viewBox: '0 0 21 20',
  path: (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="currentColor">
      <g id="WarningDiamond">
        <path
          id="Vector"
          d="M10.5001 5.62499C10.6659 5.62499 10.8248 5.69084 10.9421 5.80805C11.0593 5.92526 11.1251 6.08423 11.1251 6.24999V10.625C11.1251 10.7908 11.0593 10.9497 10.9421 11.0669C10.8248 11.1841 10.6659 11.25 10.5001 11.25C10.3344 11.25 10.1754 11.1841 10.0582 11.0669C9.94096 10.9497 9.87511 10.7908 9.87511 10.625V6.24999C9.87511 6.08423 9.94096 5.92526 10.0582 5.80805C10.1754 5.69084 10.3344 5.62499 10.5001 5.62499ZM9.56261 13.4375C9.56261 13.6229 9.6176 13.8042 9.72061 13.9583C9.82362 14.1125 9.97004 14.2327 10.1413 14.3036C10.3127 14.3746 10.5012 14.3932 10.683 14.357C10.8649 14.3208 11.0319 14.2315 11.163 14.1004C11.2941 13.9693 11.3834 13.8022 11.4196 13.6204C11.4558 13.4385 11.4372 13.25 11.3663 13.0787C11.2953 12.9074 11.1751 12.761 11.021 12.658C10.8668 12.555 10.6855 12.5 10.5001 12.5C10.2515 12.5 10.013 12.5988 9.8372 12.7746C9.66139 12.9504 9.56261 13.1889 9.56261 13.4375ZM19.2501 9.99999C19.2506 10.1637 19.2186 10.3259 19.1559 10.4772C19.0933 10.6284 19.0013 10.7658 18.8853 10.8812L11.3814 18.3859C11.1472 18.6187 10.8303 18.7494 10.5001 18.7494C10.1699 18.7494 9.85307 18.6187 9.61886 18.3859L2.11886 10.8812C1.88605 10.647 1.75537 10.3302 1.75537 9.99999C1.75537 9.66976 1.88605 9.35295 2.11886 9.11874L9.62277 1.61406C9.85697 1.38124 10.1738 1.25056 10.504 1.25056C10.8343 1.25056 11.1511 1.38124 11.3853 1.61406L18.8892 9.11874C19.0045 9.23454 19.0958 9.37203 19.1577 9.52328C19.2197 9.67453 19.2511 9.83655 19.2501 9.99999ZM18.0001 9.99999L10.5001 2.49999L3.00011 9.99999L10.5001 17.5L18.0001 9.99999Z"
          fill="currentColor"
        />
      </g>
    </svg>
  ),
});
