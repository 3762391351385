import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { SHAMROCK_API_URL } from 'helpers/constants/environment';
import { ContainingProduct } from 'helpers/services/shamrock';
import transformParamsToQueryParams from 'helpers/utils/transformParamsToQueryParams';

export const containingProduct = async (params: ContainingProduct.Params, accessToken: string) => {
  const queryParams = transformParamsToQueryParams(params);
  if (accessToken && queryParams) {
    try {
      const { data } = await axios<ContainingProduct.Response>({
        url: `${SHAMROCK_API_URL}/order-guides/containing-product?${queryParams}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return data;
    } catch (error) {
      console.error('Error API call: containingProduct', error);

      throw error;
    }
  }
};
