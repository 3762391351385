import { Reducer, useEffect, useMemo, useReducer, useRef } from 'react';
import { useGetCtProducts } from 'composable/helpers/hooks';
import { ProductRecommendations } from 'helpers/services/shamrock';
import { productRecommendations } from 'helpers/services/shamrock/productRecommendations';
import every from 'lodash/every';
import values from 'lodash/values';
import { trendingItemsReducer } from './reducer-trending-items';
import { TrendingItemsGlobalType, TrendingLineItemsAction, TrendingLineItemsActionType } from './types';
import { UserState } from '../use_privateUserGlobal/types';
import { useRouter } from 'next/router';
import { isNotNecessaryFetching } from './utils';
import useSWR from 'swr';

const INITIAL_STATE: TrendingItemsGlobalType = { trendingItemsList: [], loading: true };

/* eslint-disable react-hooks/rules-of-hooks */
export function use_privateTrendingItemsGlobal(user: UserState, avoidFetch): TrendingItemsGlobalType {
  const alreadyHandled = useRef(false);
  const { asPath } = useRouter();
  const [state, dispatch] = useReducer<Reducer<TrendingItemsGlobalType, TrendingLineItemsAction>>(
    trendingItemsReducer,
    INITIAL_STATE,
  );

  const payload: ProductRecommendations.Params = {
    includeTrending: true,
    includeSimilar: false,
    includeMightAlsoLike: false,
    customerNumber: user?.activeAccount?.key,
    warehouseNumber: user?.activeWarehouse?.warehouseNumber,
    businessUnitName: user?.activeWarehouse?.businessUnit,
    businessSegmentName: user?.activeWarehouse?.businessSegment,
  };

  const isNotNecessaryFetchingYet = useMemo(() => isNotNecessaryFetching(asPath), [asPath]);

  const isValidPayload = useMemo(() => {
    return !avoidFetch && every(values(payload), (val) => val !== '' && !(Array.isArray(val) && val.length === 0));
  }, [avoidFetch, payload]);

  const { data, isLoading: isLoadingTrendingProducts } = useSWR(
    isValidPayload && !user.loading && !isNotNecessaryFetchingYet && !alreadyHandled.current
      ? 'trending-products'
      : null,
    () => productRecommendations(payload, user?.accessToken),
    {
      onError: (err) => {
        console.error(err);
        dispatch({ type: TrendingLineItemsActionType.SET_LOADING, payload: false });
      },
      revalidateOnFocus: false,
    },
  );

  const { products, isLoading: isLoadingCtInformation } = useGetCtProducts(
    data?.trendingProducts?.map(({ productNumber }) => productNumber) || [],
  );

  useEffect(() => {
    if (
      !avoidFetch &&
      !user.loading &&
      !isLoadingCtInformation &&
      products.length &&
      data?.trendingProducts &&
      data?.trendingProducts?.length > 0
    ) {
      const formattedList = data?.trendingProducts.map((item) => ({
        ...products.find(({ productId }) => productId === item.productNumber),
        extraInformation: { ...item },
      })) as unknown as ProductRecommendations.TransformedData[];
      dispatch({
        type: TrendingLineItemsActionType.SET_TRENDING_LINEITEMS,
        payload: formattedList || [],
      });
      alreadyHandled.current = true;
      dispatch({ type: TrendingLineItemsActionType.SET_LOADING, payload: false });
    }
    if (!isLoadingTrendingProducts && data?.trendingProducts?.length === 0) {
      dispatch({ type: TrendingLineItemsActionType.SET_LOADING, payload: false });
    }
  }, [isLoadingCtInformation, products, data?.trendingProducts, user.loading, avoidFetch, isLoadingTrendingProducts]);

  return { ...state };
}
