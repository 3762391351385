import { KEY_A_LA_CARTE, KEY_PICK_UP } from 'composable/components/checkout/utils/constants';
import { DeliveryMethod, DeliveryOptionsEligibility } from 'helpers/services/shamrock/types';
import { DeliveryOptionsEligibilityMap } from 'helpers/utils/fetchers';

export const formatDeliveryOptionsEligibilitiesResponse = (
  response: DeliveryOptionsEligibility[],
): DeliveryOptionsEligibilityMap => {
  return response?.reduce(
    (acc, option) => ({
      ...acc,
      [option.customerNumber]: {
        [KEY_A_LA_CARTE]: option.eligibleDeliveryOptions.includes(DeliveryMethod.A_LA_CARTE),
        [KEY_PICK_UP]: option.eligibleDeliveryOptions.includes(DeliveryMethod.PICK_UP),
      },
    }),
    {},
  );
};
