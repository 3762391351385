const isStringJson = (data: any) => {
  return typeof data === 'string' &&
  /^[\],:{}\s]*$/.test(
    data
      .replace(/\\["\\\/bfnrtu]/g, '@')
      .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
      .replace(/(?:^|:|,)(?:\s*\[)+/g, ''),
  );
};

const parseData = (data: string | object) => {
  try {
    if (typeof data === 'object' || !isStringJson(data) || (typeof data === 'string' && data.length === 0)) {
      return data;
    }

    if (data.indexOf('/\\/') === -1) {
      return JSON.parse(data.toString());
    } else {
      return JSON.parse(data.replace(/\\"/g, '"'));
    }
  } catch (err) {
    console.error(`Error parsing data: ${err.message}`);
  }
};

export const getAttribute = (attribute: any, locale: string = 'en') => {
  // This function exists only to prevent cart failures from data loaded in different forms (string/localied string)
  //TODO: remove this function once data from CT comes in the same format (either as a plain string or localied)
  if (!attribute) return '';
  return attribute?.[locale] || attribute?.['en'] || attribute?.['en_US'] || attribute?.['en-US'] || attribute || '';
};

export const getMappedData = (data: string, locale: string = 'en') => {
  const parsedData = parseData(data) || {};
  const parsedList = Array.isArray(parsedData) ? parsedData : [parsedData];

  return parsedList
    .map((entry) => {
      if (entry && typeof entry === 'object') {
        return !!entry.items?.[locale] ? { key: entry.type, value: entry.items?.[locale] } : null;
      } else {
        console.error('Invalid entry:', entry);
        return null;
      }
    })
    .filter(Boolean);
};

export const mapAttributeToArray = (ingredients: string | object, locale: string) => {
  const parsedData = parseData(ingredients);
  const parsedList = Array.isArray(parsedData) ? parsedData : [parsedData];

  return parsedList.map(
    (entry) =>
      entry?.['en'] ||
      entry?.['en-US'] ||
      entry?.item?.['en'] ||
      entry?.item?.['en-US'] ||
      entry?.item?.[locale] ||
      entry,
  );
};
