import { useCallback, useEffect, useMemo, useRef } from 'react';
import { FEATURE_FLAG_OFFLINE_QUEUE } from 'composable/components/general';
import { useOfflineDetector } from 'helpers/hooks/useOfflineDetector';
import { SEPARATOR_FOR_QUEUE } from './global/use_privateCartGlobal/utils';

type QueueAction = {
  id: string;
  action: () => Promise<any>;
};

export const useQueue = () => {
  const { isOffline } = useOfflineDetector({});
  const isProcessingQueueRef = useRef(false);
  const queueRef = useRef<QueueAction[]>([]);
  const isOnline = useMemo(() => !isOffline, [isOffline]);

  useEffect(() => {
    if (isOnline) {
      processQueue();
    }
  }, [isOnline]);

  const addActionToQueue = useCallback(({ id, action }: QueueAction) => {
    // eslint-disable-next-line
    const [_, sku] = id.split(SEPARATOR_FOR_QUEUE);
    console.log('addActionToQueue', id);
    const existingActionToItem = queueRef.current.findIndex((item) => item.id.includes(sku));

    //In this way we handle the use case that the user tries to add the same product but with different quantity
    existingActionToItem !== -1
      ? (queueRef.current[existingActionToItem] = { id, action })
      : queueRef.current.push({ id, action });
  }, []);

  const processQueue = useCallback(async () => {
    if (isProcessingQueueRef.current || queueRef.current.length === 0) {
      return;
    }

    isProcessingQueueRef.current = true;

    while (queueRef.current.length > 0) {
      const nextAction = queueRef.current.shift();
      try {
        await nextAction.action();
      } catch (error) {
        break;
      }
    }

    isProcessingQueueRef.current = false;
  }, [queueRef.current]);

  const shouldAddToQueue = useMemo(() => !isOnline && FEATURE_FLAG_OFFLINE_QUEUE, [isOnline]);

  const removeActionFromQueue = useCallback(
    (id: string) => {
      if (!FEATURE_FLAG_OFFLINE_QUEUE) {
        return;
      }
      const initialLength = queueRef.current.length;
      queueRef.current = queueRef.current.filter((item) => item.id !== id);
      return queueRef.current.length < initialLength;
    },
    [queueRef.current],
  );

  return {
    addActionToQueue,
    shouldAddToQueue,
    removeActionFromQueue,
  };
};
