import { useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useToast } from '@chakra-ui/react';
import { Order } from '@Types/cart/Order';
import { OrderDetail } from '@Types/shamrockApi/Order';
import { GENERIC_TOAST_ERROR_ID, TOAST_ICON } from 'composable/components/general';
import {
  CURRENT_ORDER_EDITING,
  EDIT_ORDER_STATE,
  NUM_OF_CART_EXCEPTIONS,
  TOTAL_EXCEPTIONS,
  ORIGINAL_ORDER,
} from 'composable/helpers/constants';
import { EditOrder } from 'composable/helpers/utils/use-user-utils/types';
import routes from 'helpers/constants/routes';
import { useFormat } from 'helpers/hooks/useFormat';
import { mountUpdateOrderPayload } from 'utils/orderEdit/mountUpdateOrderPayload';
import { updateCustomerOrderDetail } from 'frontastic/actions/shamrockApi';
import { UpdateOrderApiParams } from 'frontastic/actions/shamrockApi/types';
import { sleep } from 'frontastic/tastics/composable/edit-order/utils';
import { useLocalStorage } from '../useLocalStorage';

export const useEditOrderMode = () => {
  const [isEditOrder, setIsEditOrder] = useLocalStorage(EDIT_ORDER_STATE, '');
  const [orderEditing, setOrderEditing] = useLocalStorage(CURRENT_ORDER_EDITING, '');

  const [originalOrder, setOriginalOrder] = useLocalStorage(ORIGINAL_ORDER, '');

  const [, setNumOfCartExceptions] = useLocalStorage(NUM_OF_CART_EXCEPTIONS, { total: 0 });
  const [, setTotalExceptions] = useLocalStorage(TOTAL_EXCEPTIONS, 0);
  const router = useRouter();
  const [updatingOrder, setUpdatingOrder] = useState(false);

  const persistedSplittedEditOrder = useRef<{ parentOrderNumber: string; splittedOrder: Order }>();

  const [editOrder, setEditOrder] = useState<EditOrder>({
    isEditOrder: isEditOrder === 'true',
    order: orderEditing.length ? JSON.parse(orderEditing) : undefined,
  });

  const handleEnableEditOrder = (order: OrderDetail) => {
    const orderEdit = { ...order, editModeTimeEnabled: new Date() };
    setEditOrder({
      isEditOrder: true,
      order: orderEdit,
    });
    setIsEditOrder('true');
    setOrderEditing(JSON.stringify(orderEdit));
    setOriginalOrder(JSON.stringify(order));

    setNumOfCartExceptions({ total: 0 });
    setTotalExceptions(0);

    router.push(`${routes.EDIT_ORDER_PAGE}${order.orderNumber}`);
  };

  const handleDisableEditOrder = () => {
    setEditOrder({
      isEditOrder: false,
      order: undefined,
    });
    setIsEditOrder('');
    setOrderEditing('');
    setOriginalOrder('');
  };

  const handleChangeOrderEditing = (order: OrderDetail) => {
    setEditOrder((prev) => ({
      ...prev,
      order,
    }));
    setOrderEditing(JSON.stringify(order));
  };

  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });

  const findOriginalOrderFromCurrentEditingOrder = (sku: string) => {
    const originalOrderParsed = JSON.parse(originalOrder) as OrderDetail;
    const lineItem = originalOrderParsed.lineItems.find((item) => item.productNumber === sku);

    return lineItem;
  };

  const updateOrder = async () => {
    setUpdatingOrder(true);

    const updateOrderPayload: UpdateOrderApiParams = mountUpdateOrderPayload(editOrder.order);

    let response: boolean;

    try {
      response = await updateCustomerOrderDetail(updateOrderPayload, toast, formatMessage({ id: 'app.generic.error' }));
    } catch (error) {
      // If API is not successful then the user stays on the
      // checkout page and toast error is displayed for 5 seconds
      setUpdatingOrder(false);
      console.error('Error API: updateCustomerOrderDetail', error);
      if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
        toast({
          duration: 5000,
          status: 'error',
          title: formatMessage({ id: 'editOrder.checkout.toast.error' }),
          icon: TOAST_ICON.error,
        });
      }
      return;
    }

    // delay the redirect to allow the API to complete the update
    await sleep(3000);

    // If API is successful, user is taken back to the Order details
    //  with toast success message is displayed for 5 seconds
    router.push(`${routes.ORDER_DETAIL_PAGE}${editOrder.order.customerNumber}/${editOrder.order.orderNumber}`);

    // delay the toast to allow the page to redirect
    await sleep(1000);
    toast({
      duration: 5000,
      status: 'success',
      title: formatMessage({ id: 'editOrder.checkout.toast.success' }),
      icon: TOAST_ICON.success,
    });
    handleDisableEditOrder();

    setUpdatingOrder(false);
  };

  return {
    isEditOrder,
    editOrder,
    handleEnableEditOrder,
    handleDisableEditOrder,
    handleChangeOrderEditing,
    updateOrder,
    setUpdatingOrder,
    persistedSplittedEditOrder,
    updatingOrder,
    findOriginalOrderFromCurrentEditingOrder,
  };
};
