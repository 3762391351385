import { ADDEDD_PRODUCTS_FROM_PLP } from 'composable/helpers/constants';
import { useLocalStorage } from 'composable/helpers/hooks';
import { AddedProductsFromsPLP } from 'composable/types';

export const useLocalStorageAddedProducts = () => {
  const [addedProducts, setAddedProducts] = useLocalStorage<AddedProductsFromsPLP[]>(ADDEDD_PRODUCTS_FROM_PLP, []);

  const addProduct = (value: any) => {
    const valueFromLocalStorage = JSON.parse(window.localStorage.getItem(ADDEDD_PRODUCTS_FROM_PLP));
    setAddedProducts([...(valueFromLocalStorage ? valueFromLocalStorage : []), value]);
  };

  const clearProducts = () => setAddedProducts([]);

  return {
    addedProducts,
    addProduct,
    clearProducts,
  };
};
