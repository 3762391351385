import { Cart } from '@Types/cart/Cart';
import { ADDEDD_PRODUCTS_FROM_PLP } from 'composable/helpers/constants';
import { AddedProductsFromsPLP } from 'composable/types';

export const removeProductFromLocalStorage = (lineItemId: string, cart: Partial<Cart>): void => {
  const productRemoved = cart.lineItems.find((product) => product.lineItemId === lineItemId);
  if (!productRemoved) {
    return;
  }

  const addedProducts = JSON.parse(localStorage.getItem(ADDEDD_PRODUCTS_FROM_PLP || '[]')) as AddedProductsFromsPLP[];
  const updatedProducts = addedProducts.filter((product) => !product.objectIDs[0].includes(productRemoved.productId));
  localStorage.setItem(ADDEDD_PRODUCTS_FROM_PLP, JSON.stringify(updatedProducts));
};
