const borders = {
  none: 'none',
  sm: '1px',
  md: '2px',
  lg: '4px',
  xl: '8px',
  xxl: '10px',
  xxxl: '12px',

  //chakra-ui default
  '1px': '1px solid',
  '2px': '2px solid',
  '4px': '4px solid',
  '8px': '8px solid',
};

export type Borders = typeof borders;

export default borders;
