import { FormLabelProps, RadioProps } from '@chakra-ui/react';

const Radio = {
  baseStyle: {
    container: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    control: {
      bg: 'neutral.50',
      borderColor: 'neutral.300',
      mt: '2px',
      _checked: {
        bg: 'primary.500',
        borderColor: 'primary.500',
        _before: {
          bg: 'primary.200',
        },
      },
      _disabled: {
        bg: 'neutral.300',
        borderColor: 'neutral.400',
      },
    } as RadioProps,
    label: {
      color: 'neutral.900',
      fontWeight: 'bold',
      lineHeight: '120%',
      _disabled: { color: 'neutral.500', opacity: 1 },
    } as FormLabelProps,
  },
  sizes: {
    md: { label: { fontSize: 'base', ml: 3 } },
  },
};

export default Radio;
