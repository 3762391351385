import routes from 'helpers/constants/routes';
import { InfoIcon } from '../info-icon';
import { WarningDiamondIcon } from '../warning-icon';

export const HEADER_HEIGHT = 60;
export const HEADER_DESKTOP_HEIGHT = 64;
export const TITLE_AND_BUY_SECTION_HEIGHT = 52;
export const TAB_HEADER_HEIGHT = 52;

// Breakpoints:
export const LAYOUT_VALUES = {
  PADDING_RIGHT: [3, 5, 5, 14.5, 15, 15, 25, 25, 85],
  PADDING_LEFT: [3, 5, 5, 14.5, 15, 16, 16, 16, 23.5],
};

export enum ProductSubstituteVariant {
  May = 'May',
  Will = 'Will',
}

export enum SubstituteType {
  REGULAR = 'Regular',
  AUTO = 'Auto',
  CLEAN_INVOICE = 'Clean Invoice',
  FORCED = 'Forced',
}

export const SEARCH_LOCAL_INPUT_ID = 'local-search-bar';

export const KEYBOARD_KEYS = {
  ESC: 'Escape',
  ENTER: 'Enter',
  TAB: 'Tab',
  Q: 'q', //This is a workaround to handle new hotkeys in OG
};

export const HAS_LOCAL_SEARCH = [
  routes.ORDER_GUIDE,
  routes.ORDER_GUIDE_EDIT,
  routes.ORDER_LIST_PAGE,
  routes.ORDER_GUIDE_MANAGEMENT,
];

export const APPLICABLE_SUBSTITUTE_TYPES = [SubstituteType.AUTO, SubstituteType.CLEAN_INVOICE, SubstituteType.FORCED];

export const COLOR_PROPERTIES_BY_VARIANT = {
  [ProductSubstituteVariant.May]: {
    translationId: 'pdp.substitutions.may',
    bg: 'violet.100',
    borderInlineStartColor: 'violet.500',
    textColor: 'violet.800',
    subTextColor: 'neutral.900',
    icon: InfoIcon,
    iconColor: 'violet.500',
    borderColor: 'violet.200',
    backgroundHover: 'violet.50',
  },
  [ProductSubstituteVariant.Will]: {
    translationId: 'pdp.substitutions.will',
    bg: 'yellow.100',
    borderInlineStartColor: 'yellow.500',
    textColor: 'yellow.800',
    subTextColor: 'neutral.900',
    icon: () => WarningDiamondIcon,
    iconColor: 'yellow.500',
    borderColor: 'yellow.300',
    backgroundHover: 'yellow.50',
  },
};
