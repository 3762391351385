import { useMemo } from 'react';
import { useToast } from '@chakra-ui/react';
import { useGlobal } from 'components/globalProvider';
import { FEATURE_FLAG_DISPLAY_DELIVERY_OPTIONS } from 'composable/components/general';
import { SWR_OPTIONS } from 'composable/components/order-guide/helpers';
import { getPayloadByWarehouses } from 'helpers/components/accountsOverviewHelper';
import { useFormat } from 'helpers/hooks/useFormat';
import { DeliveryOptionsEligibility } from 'helpers/services/shamrock';
import { formatDeliveryOptionsEligibilitiesResponse } from 'helpers/utils';
import { EligibleOptionsByType, DeliveryOptionsEligibilityMap } from 'helpers/utils/fetchers';
import useSWR from 'swr';
import { getDeliveryOptionsEligibilities } from 'frontastic/actions/shamrockApi';
import { useShouldExecuteApi } from './useHandleCancelApiCall';

interface useDeliveryOptionsEligibilitiesPayload {
  isLoading: boolean;
  eligibleOptionsByCustomers?: DeliveryOptionsEligibilityMap | {};
  currentUserEligibleOptions?: EligibleOptionsByType | {};
  deliveryOptionsEligibilities?: DeliveryOptionsEligibility[];
}

const useDeliveryOptionsEligibilitiesExecutable = (): useDeliveryOptionsEligibilitiesPayload => {
  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });

  const { useUserGlobal, isPrintBack } = useGlobal();

  const { state: user } = useUserGlobal;
  const { accessToken, accountList, activeAccount } = user;
  const payload = useMemo(() => getPayloadByWarehouses(accountList), [accountList]);

  const { data, isLoading, isValidating } = useSWR(
    payload && accessToken && !isPrintBack ? ['useDeliveryOptionsEligibilities', payload] : null,
    () => getDeliveryOptionsEligibilities(accessToken, payload, toast, formatMessage({ id: 'app.generic.error' })),
    { ...SWR_OPTIONS, errorRetryCount: 2 },
  );

  const formattedData = useMemo(() => {
    if (data?.length > 0) {
      return formatDeliveryOptionsEligibilitiesResponse(data) ?? {};
    }
    return {};
  }, [data]);

  return {
    deliveryOptionsEligibilities: data ?? [],
    eligibleOptionsByCustomers: FEATURE_FLAG_DISPLAY_DELIVERY_OPTIONS ? formattedData : {},
    currentUserEligibleOptions: FEATURE_FLAG_DISPLAY_DELIVERY_OPTIONS ? formattedData?.[activeAccount?.key] ?? {} : {},
    isLoading: isLoading || isValidating,
  };
};

//eslint-disable-next-line
export const useDeliveryOptionsEligibilities = useShouldExecuteApi(useDeliveryOptionsEligibilitiesExecutable, {
  deliveryOptionsEligibilities: [],
  eligibleOptionsByCustomers: {},
  currentUserEligibleOptions: {},
  isLoading: false,
});
