import { IndexUiState } from 'instantsearch.js';
import {
  ALGOLIA_BASE_INDEX,
  ALGOLIA_FILTERS,
  ALGOLIA_INDEX_LIST,
  DEFAULT_SORTBY,
  DEFAULT_ALGOLIA_LOCALE,
} from './constants';

export const algoliaIndexNameResolver = ({ sortBy = DEFAULT_SORTBY }: { sortBy?: string }) => {
  if (sortBy == 'Default') {
    return `${ALGOLIA_BASE_INDEX}`;
  }
  return `${ALGOLIA_BASE_INDEX}_${sortBy}`;
};

export const getSortByFromIndex = (
  indexName: string,
  indexNameResolver?: (props: { sortBy?: string; isLoggedIn?: boolean }) => string,
) => {
  return ALGOLIA_INDEX_LIST.reduce<string | undefined>((acc, { value: sortBy }) => {
    return indexNameResolver?.({ sortBy }) === indexName ? sortBy : acc;
  }, undefined);
};

export const getRouteFilters = (
  filters: IndexUiState['refinementList'] | IndexUiState['numericMenu'] | IndexUiState['toggle'],
  separator = '|',
) => {
  return Object.entries(filters || {}).reduce<Record<string, string>>((acc, [filter, values]) => {
    const filterAlias = getFilterAttributeUrlAlias(filter);
    if (filterAlias) {
      return {
        ...acc,
        [filterAlias]: typeof values == 'object' ? values.join(separator) : values,
      };
    }
    return acc;
  }, {});
};

interface FilterState {
  numericMenu: {
    [attribute: string]: string;
  };
  refinementList: {
    [attribute: string]: string[];
  };
  toggle: {
    [attribute: string]: boolean;
  };
}

export const getAlgoliaStateFilters = (filters: Record<string, string>) => {
  return Object.entries(filters || {}).reduce<FilterState>(
    (acc, [filterAlias, value]) => {
      const fullAttribute = getFilterRawAttribute(filterAlias);
      if (!fullAttribute) return acc;
      const { attribute, type } = fullAttribute;

      switch (type) {
        case 'list':
          const previousValue = acc.refinementList[attribute] || [];
          return {
            ...acc,
            refinementList: {
              ...acc.refinementList,
              [attribute]: [...previousValue, ...value.split('|')],
            },
          };
        case 'numeric':
          return {
            ...acc,
            numericMenu: {
              ...acc.numericMenu,
              [attribute]: value,
            },
          };
        case 'toggle':
          return {
            ...acc,
            toggle: {
              ...acc.toggle,
              [attribute]: value == 'true' ? true : false,
            },
          };
      }
    },
    {
      numericMenu: {},
      refinementList: {},
      toggle: {},
    },
  );
};

const getFilterAttributeUrlAlias = (rawAttribute: string) => {
  return ALGOLIA_FILTERS.find((filter) => filter.attribute === rawAttribute)?.urlAlias;
};

const getFilterRawAttribute = (attributeAlias: string) => {
  return ALGOLIA_FILTERS.find((filter) => filter.urlAlias === attributeAlias);
};

// Function to get a value by locale
export const getValueByLocale = (product: any, propertyName: string, locale: string) => {
  if (product && product[propertyName] && product[propertyName][locale]) {
    return product[propertyName][locale];
  }
  return (product && product[propertyName] && product[propertyName][DEFAULT_ALGOLIA_LOCALE]) || null;
};
export interface LocalizedString {
  [locale: string]: string;
}
