import { Address } from '@Types/account/Address';
import { getTimeZone } from 'composable/components/plp/helpers';
import { RemainingDays } from 'composable/helpers/hooks/useGetDeliveryDates';
import { DeliveryWindow, FormattedDeliveryOption } from 'helpers/services/shamrock';
import { DateTime } from 'luxon';
import { SEPARATOR_TIME_WINDOWS } from './fetchers';

const HARDCODED_WAREHOUSE_ADDRESS: Partial<Address> = {
  firstName: 'ABC Warehouse',
  lastName: 'Fname Lname',
  streetName: '1234 Fake Street',
  city: 'Chicago',
  state: 'IL',
  postalCode: '60606',
  phone: '800-444-5566',
  country: 'US',
};

export const mapShamrockResponseToDeliveryOptions = ({
  availableDates,
  numberCases,
}: {
  availableDates: DeliveryWindow.DeliveryOption[];
  numberCases: number;
}): FormattedDeliveryOption[] => {
  return availableDates.map(({ deliveryDate, deliveryWindows, deliveryMinimums, deliveryType }) => {
    return {
      deliveryDate,
      deliveryDateWithTime: `${deliveryDate}T00:00:00`,
      deliveryWindows: deliveryWindows.map((timeWindow) => {
        const formattedStartTimeLocal = convertUTCDateTimeToLocal(
          `${deliveryDate}T${timeWindow.deliveryWindowStartTime}`,
        );
        const formattedEndTimeLocal = convertUTCDateTimeToLocal(`${deliveryDate}T${timeWindow.deliveryWindowEndTime}`);
        const { pickupAddress, isAvailable, maxCasesPerOrder } = timeWindow;
        const isUnavailableByCases = numberCases > maxCasesPerOrder && maxCasesPerOrder !== null;
        return {
          isUnavailableByCases,
          maxCasesPerOrder,
          isAvailable: isAvailable && !isUnavailableByCases,
          deliveryWindowStartTime: timeWindow.deliveryWindowStartTime,
          deliveryWindowEndTime: timeWindow.deliveryWindowEndTime,
          formattedStartTimeLocal,
          formattedEndTimeLocal,
          radioKey: `${timeWindow.deliveryWindowStartTime}${SEPARATOR_TIME_WINDOWS}${timeWindow.deliveryWindowEndTime}${SEPARATOR_TIME_WINDOWS}${deliveryDate}`,
          geocode: timeWindow.geocode,
          warehouseAddress: pickupAddress
            ? {
                firstName: pickupAddress.name,
                streetName: pickupAddress.address1,
                city: pickupAddress.city,
                postalCode: pickupAddress.postalCode,
                phone: pickupAddress.primaryPhoneNumber,
                state: pickupAddress.state,
              }
            : HARDCODED_WAREHOUSE_ADDRESS,
          remainingDays: timeWindow.cutOffTime
            ? calculateRemainingTime(deliveryDate, timeWindow.cutOffTime, timeWindow.leadDays)
            : null,
        };
      }),
      deliveryType,
      deliveryMinimums,
    };
  });
};

export const formatRadioDeliveryWindowKey = (deliveryDate: Date, radioKey: string): string => {
  const onlyDay = deliveryDate.toISOString().split('T')[0];
  const [startTime, endTime] = radioKey.split(SEPARATOR_TIME_WINDOWS);
  const formattedStartTimeLocal = convertUTCDateTimeToLocal(`${onlyDay}T${startTime}`);
  const formattedEndTimeLocal = convertUTCDateTimeToLocal(`${onlyDay}T${endTime}`);
  return `${formattedStartTimeLocal} - ${formattedEndTimeLocal} ${getTimeZone()}`;
};

//An example of the time should be '2024-03-05T08:00:00'
const convertUTCDateTimeToLocal = (utcDateTimeStr: string) => {
  const dateTimeInUtc = DateTime.fromISO(utcDateTimeStr, { zone: 'utc' });
  const dateTimeInLocal = dateTimeInUtc.toLocal();
  const formattedTime = dateTimeInLocal.toFormat('h:mm a');
  const timeInUpperCase = formattedTime.toUpperCase();
  return timeInUpperCase;
};

const calculateRemainingTime = (date: string, cutOffTime: string, leadDays: number): RemainingDays => {
  const dateTime = DateTime.fromISO(`${date}T${cutOffTime}`, { zone: 'utc' });

  //Acording with Shamrock team, the only values for lead days are 0 and 1.
  //In a future this could change in their side, so we should also consider the case when leadDays > 1.
  const newDateTime = dateTime.minus({ days: leadDays === 0 ? 1 : 0 }).toLocal();

  const days = newDateTime.diffNow('days').days;
  const hours = newDateTime.diffNow('hours').hours;
  const minutes = newDateTime.diffNow('minutes').minutes;

  const orderByDate = newDateTime.toJSDate();

  return {
    days: Math.floor(days),
    hours: Math.floor(hours % 24),
    minutes: Math.floor(minutes % 60),
    orderByDate,
  };
};
