import { useMemo } from 'react';
import { useToast } from '@chakra-ui/react';
import { Order } from '@Types/shamrockApi/Order';
import { useGlobal } from 'components/globalProvider';
import { FEATURE_FLAG_MULTI_ACCOUNT } from 'composable/components/general';
import { SWR_OPTIONS } from 'composable/components/order-guide/helpers';
import { getPayloadByWarehouses } from 'helpers/components/accountsOverviewHelper';
import { useFormat } from 'helpers/hooks/useFormat';
import useSWR from 'swr';
import { allAccountOrders } from 'frontastic/tastics/composable/orders/utils/fetchers';

type useGetMultipleOrdersProps = {
  period: number;
  isAllReturns: boolean;
  customerNumbers: string[] | null;
  enable?: boolean;
};

export const useGetMultipleOrders = ({
  period = 7,
  isAllReturns = false,
  customerNumbers,
  enable = true,
}: useGetMultipleOrdersProps) => {
  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });
  const {
    state: { accountList, accessToken },
    logout,
  } = useGlobal().useUserGlobal;

  const payload = useMemo(
    () =>
      getPayloadByWarehouses(
        customerNumbers && customerNumbers.length
          ? accountList.filter((bu) => customerNumbers.includes(bu.key))
          : accountList,
      ),
    [customerNumbers, accountList],
  );

  const errorMessage = formatMessage({ id: 'orders.api.error' });
  // TODO: This fetcher should be moved to a its own file
  const fetcher = async () => {
    if (!isAllReturns && FEATURE_FLAG_MULTI_ACCOUNT) {
      try {
        const data = await allAccountOrders([payload, period, { accessToken, logout }, toast, errorMessage]);
        return (data ?? []) as Order[];
      } catch (error) {
        console.error('Error API call: allAccountOrders', error);
      }
    }
  };

  const swrKey = ['useGetMultipleOrders', period, payload];

  const {
    data: multipleOrders,
    error,
    isValidating,
    isLoading,
    mutate,
  } = useSWR(enable && FEATURE_FLAG_MULTI_ACCOUNT && !isAllReturns && payload ? swrKey : null, fetcher, {
    ...SWR_OPTIONS,
    errorRetryCount: 2,
  });

  const isLoadingMultipleOrders = isValidating || isLoading;

  return { multipleOrders, isLoadingMultipleOrders, error, multipleOrdersMutation: mutate };
};
