import { mutate } from 'swr';
import { fetchApiHub } from 'frontastic';
import { GENERIC_TOAST_ERROR_ID, TOAST_ICON } from 'composable/components/general';
import { useToast } from '@chakra-ui/react';

export const getCustomerDetailsInCT = async (
  userId: string,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
) => {
  try {
    const res = await fetchApiHub(
      `/action/customer/fetchCustomerDetails?externalId=${userId.toUpperCase()}`,
      {
        method: 'POST',
      },
      {},
    );
    mutate(`/action/customer/fetchCustomerDetails?externalId=${userId.toUpperCase()}`, res, { revalidate: false });
    return res;
  } catch (error) {
    console.error('Error API: queryCategories', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    throw error;
  }
};

export const getStoreDetailsForSelectedBu = async (
  selectedBusinessUnitStoreKey: string,
  selectedBusinessUnitKey: string,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
) => {
  try {
    const res = await fetchApiHub(
      `/action/customer/fetchStoreDetailsForSelectedBu?selectedBusinessUnitStoreKey=${selectedBusinessUnitStoreKey}&selectedBusinessUnitKey=${selectedBusinessUnitKey}`,
      {
        method: 'POST',
      },
      {},
    );
    mutate(
      `/action/customer/fetchStoreDetailsForSelectedBu?selectedBusinessUnitStoreKey=${selectedBusinessUnitStoreKey}&selectedBusinessUnitKey=${selectedBusinessUnitKey}`,
      res,
      {
        revalidate: false,
      },
    );
    return res;
  } catch (error) {
    console.error('Error API: getStoreDetailsForSelectedBu', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    throw error;
  }
};
