import { useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { useGlobal } from 'components/globalProvider';
import { TOAST_ICON } from 'composable/components/general';
import { CART_ID, IS_SPLIT_ORDER, LAST_CHECKOUT_CART_ID } from 'composable/helpers/constants';
import { FeatureFlags } from 'utils/featureFlags';
import { UseCart } from 'frontastic/provider/Frontastic/UseCart';
import { useFormat } from './useFormat';

export const useSubmittedCartHandler = (cart: UseCart) => {
  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });

  const { activeAccount, loading: isUserLoading } = useGlobal().useUserGlobal.state;

  useEffect(() => {
    window.localStorage.removeItem(CART_ID);
    window.localStorage.removeItem(LAST_CHECKOUT_CART_ID);
  }, [activeAccount?.id]);

  // save cartId to local storage
  useEffect(() => {
    if (isUserLoading) {
      return;
    }
    // delay to wait for selectedBusinessUnit?.id checking
    const ref = setTimeout(() => {
      const cartId = window.localStorage.getItem(CART_ID);
      const lastCheckoutCartId = window.localStorage.getItem(LAST_CHECKOUT_CART_ID);
      const isSplitOrder = window.localStorage.getItem(IS_SPLIT_ORDER) === 'true';

      if (cart && cart.data?.cartId && cart.data?.cartId !== cartId) {
        window.localStorage.setItem(CART_ID, cart.data?.cartId);

        if (!isSplitOrder && cartId && cartId !== lastCheckoutCartId && !cart?.data?.lineItems?.length) {
          if (FeatureFlags.TOAST_CART_SUBMITTED_BY_SOMEONE_ELSE) {
            toast({
              duration: 5000,
              status: 'warning',
              title: formatMessage({ id: 'cart.hasBeenSubmitted' }),
              icon: TOAST_ICON.warning,
            });
          }
        }
      }
    }, 1000);

    return () => clearTimeout(ref);
  }, [cart, isUserLoading]);
};
