import { useMemo } from 'react';
import { useGlobal } from 'components/globalProvider';
import { AccountInformation } from 'composable/types';
import { useFormat } from 'helpers/hooks/useFormat';
import { getAccountOptions, getAccountsInformation } from '../utils/account-utils';
export const KEY_ALL_ACCOUNTS = 'allAccounts';

export const useGetAccounts = () => {
  const { formatMessage } = useFormat({ name: 'common' });
  const { accountList } = useGlobal().useUserGlobal.state;

  const accountOptions = useMemo(() => getAccountOptions(accountList, formatMessage), [accountList]);

  const accountsInformation = useMemo<AccountInformation[]>(() => getAccountsInformation(accountList), [accountList]);

  return { accountOptions, accountsInformation };
};
