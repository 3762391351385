import { createContext, useContext, useState, useEffect } from 'react';
import { useRouter } from 'next/router';

const NavigationHistoryContext = createContext(null);

export function NavigationHistoryProvider({ children }) {
  const [history, setHistory] = useState<string[]>([]);
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      setHistory((prevHistory) => [...prevHistory, router.asPath]);
    };

    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  return <NavigationHistoryContext.Provider value={history}>{children}</NavigationHistoryContext.Provider>;
}

console.warn('Deprecated provider NavigationHistoryProvider');
export const useNavigationHistory = (): string[] => {
  return useContext(NavigationHistoryContext);
};
