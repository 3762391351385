export enum SortOption {
  All = 'all',
  Submitted = 'submitted',
  Processing = 'processing',
  OutForDelivery = 'outfordelivery',
  ReadyForPickup = 'readyforpickup',
  Delivered = 'delivered',
  Cancelled = 'cancelled',
  AllReturns = 'allReturns',
}

export enum TypeOption {
  All = 'all',
  Standard = 'standard',
  ALaCarte = 'alacarte',
  Pickup = 'pickup',
  CustomerDirect = 'customerDirect',
  InvoiceReissue = 'invoiceReissue',
}
