import routes from 'helpers/constants/routes';

export const isNotNecessaryFetching = (path: string): boolean => {
  return [
    routes.ACCOUNTS,
    routes.CART,
    routes.CHECKOUT_SUCCESS,
    routes.CHECKOUT,
    routes.EDIT_ORDER_CHECKOUT,
    routes.EDIT_ORDER_PAGE,
    routes.ORDER_DETAIL_PAGE,
    routes.NOT_FOUND,
    routes.ORDER_GUIDE_EDIT,
    routes.ORDER_GUIDE_MANAGEMENT,
    routes.PUBLIC_ERROR_PAGE,
    routes.ORDER_LIST_PAGE,
  ].some((page) => path.includes(page));
};
