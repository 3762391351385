import { ExtraProductData } from '@Types/shamrockApi/Product';
import moment from 'moment-timezone';

export function calculateLatestDeliveryDate(extraProductDataList: ExtraProductData[]): moment.Moment {
  let latestDate: moment.Moment = null;
  (extraProductDataList ?? []).forEach((extraData) => {
    const cutoffs = extraData?.companyInfo?.data?.cutoffs;
    // Only calculate date for products with lead time
    if ((cutoffs?.daysOfWeek?.length ?? 0) > 0) {
      let deliveryDate = moment();
      // Add the lead days to the current date
      const currentDayOfWeek = deliveryDate.weekday();
      deliveryDate = deliveryDate.add(Math.abs(cutoffs.daysOfWeek[currentDayOfWeek].leadDays), 'days');
      // check if cutoff for that delivery is in the future
      let cutoff = moment(deliveryDate).add(cutoffs.daysOfWeek[deliveryDate.weekday()].leadDays, 'days');
      const cutoffTimeArray = cutoffs.daysOfWeek[deliveryDate.weekday()].cutoffTime.split(':');
      cutoff = cutoff.set('hour', parseInt(cutoffTimeArray[0] ?? '0', 10));
      cutoff = cutoff.set('minute', parseInt(cutoffTimeArray[1] ?? '0', 10));
      cutoff = cutoff.set('second', parseInt(cutoffTimeArray[2] ?? '0', 10));
      if (cutoff.isBefore(moment())) {
        deliveryDate.add(1, 'day');
      }

      if (latestDate == null || deliveryDate.isAfter(latestDate)) {
        latestDate = deliveryDate;
      }
    }
  });
  latestDate = latestDate ?? moment();
  return latestDate.tz('UTC').startOf('day');
}
