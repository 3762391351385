import { AxiosError } from 'axios';
import { KEY_A_LA_CARTE, KEY_PICK_UP } from 'composable/components/checkout/utils/constants';
import { GENERIC_TOAST_ERROR_ID, TOAST_ICON } from 'composable/components/general';
import { handleAxiosError } from 'composable/components/order-guide/utils/fetchers';
import { DeliveryWindow } from 'helpers/services/shamrock';
import { containingProduct } from 'helpers/services/shamrock/containingProduct';
import { productRecommendations } from 'helpers/services/shamrock/productRecommendations';
import { shamrockSWRFetcher } from 'utils/global/shamrockSWRFetcher';
import { GetDeliveryOptionsEligibilitiesData } from 'frontastic/actions/shamrockApi/types';
import { mapShamrockResponseToDeliveryOptions } from './fetchersHelpers';

export const SEPARATOR_TIME_WINDOWS = '|';
export const MAX_DELIVERY_DATES = 3;

// TODO replace by generic Shamrock fetcher
// SWR fetcher function
export const deliveryOptions = async ([url, { payload, accessToken, logout, toast, toastMessage }]) => {
  try {
    const numberCases = (payload as DeliveryWindow.Payload).products.reduce(
      (acc, product) => product.quantity + acc,
      0,
    );
    const response = await shamrockSWRFetcher<DeliveryWindow.Payload, DeliveryWindow.Response>(url, {
      payload,
      accessToken,
    });

    const availableDates = response.deliveryOptions
      .filter((option) => option.deliveryWindows.length > 0)
      .map((option) => ({
        ...option,
        deliveryWindows: option.deliveryWindows.map((window) => ({
          ...window,
          isAvailable: window.isAvailable,
        })),
      }));

    return {
      geocodeCutoffDate: response.geocodeCutoffDate,
      geocodeCutoffTime: response.geocodeCutoffTime,
      deliveryOptions: mapShamrockResponseToDeliveryOptions({ availableDates, numberCases }),
      deliveryMinimums: response.deliveryMinimums,
    };
  } catch (error) {
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }

    if (error instanceof AxiosError) {
      console.error(error);
      handleAxiosError(error, logout);
    }
    throw error;
  }
};

// TODO replace by generic Shamrock fetcher
export const containingProductFetcher = async ({ params, accessToken, logout }) => {
  try {
    return await containingProduct(params, accessToken);
  } catch (error) {
    console.error('Error API call: containingProduct', error);

    if (error instanceof AxiosError) {
      handleAxiosError(error, logout);
    }

    throw error;
  }
};

export const productRecommendationsFetcher = async ([, { params, accessToken }]) => {
  try {
    return await productRecommendations(params, accessToken);
  } catch (error) {
    console.error('Error API call: productRecommendations', error);
    throw error;
  }
};

export interface EligibilityRequestData extends GetDeliveryOptionsEligibilitiesData {
  eligibleDeliveryOptions: string[];
}

export interface EligibleOptionsByType {
  [KEY_A_LA_CARTE]: boolean;
  [KEY_PICK_UP]: boolean;
}

export interface DeliveryOptionsEligibilityMap {
  [customerNumber: string]: EligibleOptionsByType;
}
