import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import { GetDeliveryOptionsEligibilitiesData } from 'frontastic/actions/shamrockApi/types';

export const sortPaymentsAndCredits = (accountsBalanceData, selectedAccount) => {
  let sortCreditRequestsByDate = [];
  let totalPastDue = {
    last1to7DaysAmount: 0,
    last8to15DaysAmount: 0,
    last16to30DaysAmount: 0,
    last31to60DaysAmount: 0,
    last60plusDaysAmount: 0,
  };
  let totalBalance = {
    balancePastDueAmount: 0,
    totalBalanceAmount: 0,
    unappliedCreditAmount: 0,
    totalBalanceAsOfDateTime: '',
    dueOn: '',
  };

  if (!selectedAccount || selectedAccount === 'all') {
    //credit listing data for all accounts
    const concatCreditsData = accountsBalanceData.flatMap((accountBalance) => {
      const customerNumber = accountBalance.customerNumber;
      return accountBalance.credits.map((credit) => {
        return {
          ...credit,
          customerNumber,
        };
      });
    });

    sortCreditRequestsByDate = concatCreditsData.sort(
      (a, b) => new Date(b.creditDateTime).getTime() - new Date(a.creditDateTime).getTime(),
    );

    //past due data for all accounts
    accountsBalanceData.forEach((entry) => {
      const pastDues = entry.pastDue;
      Object.entries(pastDues).forEach(([period, amount]) => {
        // If the name exists in totalPastDue, add the amount to its sum, otherwise initialize it with the amount
        totalPastDue[period] = (totalPastDue[period] || 0) + amount;
      });
    });

    //balance data for all accounts
    const sortBalanceDate = accountsBalanceData.sort((a, b) => b.totalBalanceAsOfDateTime - a.totalBalanceAsOfDateTime);
    totalBalance['dueOn'] = sortBalanceDate[0]?.balance?.totalBalanceAsOfDateTime;

    accountsBalanceData.forEach((entry) => {
      const balances = entry.balance;
      Object.entries(balances).forEach(([field, amount]) => {
        // If the name exists in totalBalance, add the amount to its sum, otherwise initialize it with the amount
        totalBalance[field] = (totalBalance[field] || 0) + amount;
      });
    });
  } else {
    const businessUnitDataExists = accountsBalanceData.filter(
      (account) => account.customerNumber === selectedAccount?.accountId,
    );

    if (businessUnitDataExists && businessUnitDataExists.length > 0) {
      //credit listing data for individual account
      const concatCreditsData = businessUnitDataExists[0].credits.map((credit) => {
        return {
          ...credit,
          customerNumber: selectedAccount?.accountId,
        };
      });

      sortCreditRequestsByDate = concatCreditsData.sort(
        (a, b) => new Date(b.creditDateTime).getTime() - new Date(a.creditDateTime).getTime(),
      );

      //account past due data
      totalPastDue = businessUnitDataExists[0].pastDue;

      //account balance data
      totalBalance = { ...businessUnitDataExists[0].balance };
      totalBalance['dueOn'] = totalBalance?.totalBalanceAsOfDateTime;
    }
  }

  return {
    recentCreditData: sortCreditRequestsByDate.slice(0, 5),
    recentPaymentsData: [],
    totalPastDue,
    totalBalance,
  };
};

export const getPayloadByWarehouses = (
  businessUnits: AsAssociateBusinessUnitResult[],
): GetDeliveryOptionsEligibilitiesData[] => {
  const warehouses = [];
  businessUnits.map((bu) => {
    const storeKey = bu?.stores[0]?.key;
    const splitKey = storeKey.split('_');
    const warehouseNumber = splitKey[2] || '';
    let customerNumbers = bu?.key;

    const existingWarehouse = warehouses.find((warehouse) => warehouse.warehouseNumber === warehouseNumber);

    if (existingWarehouse) {
      existingWarehouse.customerNumbers.push(customerNumbers);
    } else {
      warehouses.push({
        customerNumbers: [bu?.key],
        warehouseNumber: splitKey[2] || '',
        businessUnitName: splitKey[0] || '',
        businessSegmentName: splitKey[1] || '',
      });
    }
  });

  return warehouses;
};
