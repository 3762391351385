import { useIsBrowser } from 'composable/helpers/hooks';
import { useEffect, useRef, useState } from 'react';

type OfflineDetectorHook = (_: { onReconnect?: () => void }) => { isOffline: boolean };

const INTERVAL = (process.env.NEXT_PUBLIC_OFFLINE_DETECTOR_INTERVAL as unknown as number) ?? 10000;
const TIMEOUT = (process.env.NEXT_PUBLIC_OFFLINE_DETECTOR_TIMEOUT as unknown as number) ?? 5000;

/**
 * Custom fetch function with timeout.
 */
const fetchWithTimeout = (input: RequestInfo | URL, { timeout, ...init }: RequestInit & { timeout: number }) =>
  Promise.race([
    fetch(input, init),
    new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), timeout)),
  ]);

export const useOfflineDetector: OfflineDetectorHook = ({ onReconnect }) => {
  const isBrowser = useIsBrowser();
  const [isOffline, setIsOffline] = useState(isBrowser ? navigator?.onLine : false);

  const timerRef = useRef<NodeJS.Timer>();

  const startPooling = () => {
    timerRef.current = setInterval(async () => {
      try {
        /**
         * It's used the `t` query param to avoid the browser cache.
         */
        const res = (await fetchWithTimeout(`/1px.webp?t=${Date.now()}`, {
          cache: 'no-store',
          timeout: TIMEOUT,
        })) as Response;

        const isImg1px = res.url.includes('1px.webp');

        /**
         * It's checked `!res.redirected` because the browser will redirect to the 404 page if the user is not logged in.
         */
        if (res.status >= 200 && res.status < 300 && !res.redirected && isImg1px) {
          setIsOffline((prev) => {
            if (prev) {
              onReconnect?.();
            }

            return false;
          });

          return;
        }

        throw new Error();
      } catch (error) {
        setIsOffline(true);
      }
    }, INTERVAL);
  };

  const stopPooling = () => clearInterval(timerRef.current);

  useEffect(() => {
    const handleOnline = () => {
      onReconnect?.();
      setIsOffline(false);
    };
    const handleOffline = () => setIsOffline(true);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    startPooling();

    () => stopPooling();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isOffline };
};
