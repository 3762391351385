import axios from 'axios';
import { SHAMROCK_API_URL } from 'helpers/constants/environment';
import { ProductRecommendations } from 'helpers/services/shamrock';
import transformParamsToQueryParams from 'helpers/utils/transformParamsToQueryParams';

export const productRecommendations = async (params: ProductRecommendations.Params, accessToken: string) => {
  const queryParams = transformParamsToQueryParams(params);
  if (accessToken && queryParams) {
    try {
      const { data } = await axios<ProductRecommendations.Response>({
        url: `${SHAMROCK_API_URL}/products/recommendations?${queryParams}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return data;
    } catch (error) {
      console.error('Error API call: productRecommendations', error);

      throw error;
    }
  }
};
