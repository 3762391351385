import { SortedOrder } from '@Types/shamrockApi/Order';
import { orderStatusSortingValues } from 'composable/helpers/utils/order-status-sorting-values';
import { getDeliveryTimeOutput } from 'composable/helpers/utils/order-utils';
import { DateTime } from 'luxon';

/**
 * Sort orders based on Status and time.
 * @param dateString string
 * @param timezone string
 * @returns DateTime<true> | DateTime<false>
 */
const parseDate = (dateString: string, timezone: string) => {
  if (!dateString) return null; // Handle empty strings

  // This regex captures both start and end times along with the timezone
  const timeRangePattern = /(\d{1,2}:\d{2} [AP]M) - (\d{1,2}:\d{2} [AP]M)/;
  const datePattern = /(\d{2}\/\d{2}\/\d{4})/; // Match specific date format.
  const timeMatch = dateString.match(timeRangePattern);
  const dateMatch = dateString.match(datePattern);

  if (timeMatch) {
    const [, startTime, endTime] = timeMatch;

    // Parse both start and end times with timezone.
    const format = 'h:mm a';
    const endTimeParsed = DateTime.fromFormat(endTime, format, { zone: timezone });

    // Determine if an adjustment for next day is needed (PM to AM rollover).
    const adjustment = startTime.includes('PM') && endTime.includes('AM') ? 1 : 0;

    // Adjust the date to the next day if necessary.
    const test = endTimeParsed.plus({ days: adjustment });

    if (test.isValid) {
      return endTimeParsed.plus({ days: adjustment });
    }

    return null;
  } else if (dateMatch) {
    return DateTime.fromFormat(dateMatch[1], 'MM/dd/yyyy');
  }

  return null;
};

/**
 * Sort orders based on Status and time.
 * @param orders Orders array
 * @returns Orders array sorted
 */
export const sortOrders = (orders: SortedOrder[]) => {
  return orders?.sort((a, b) => {
    const timezone = DateTime.local().zoneName;
    const statusA = orderStatusSortingValues(a.status);
    const statusB = orderStatusSortingValues(b.status);

    if (statusA !== statusB) {
      return statusA - statusB;
    }

    const dateA = parseDate(a.date, timezone);
    const dateB = parseDate(b.date, timezone);

    if (!dateA && !dateB) {
      return 0;
    }
    if (!dateA) {
      return 1;
    }
    if (!dateB) {
      return -1;
    }

    return dateA.toMillis() - dateB.toMillis();
  });
};
