import { useMemo, useState } from 'react';
import { StringHelpers } from 'helpers/stringHelpers';

const filterItems = <T extends unknown>(
  items: T[],
  searchValue: string,
  SEARCH_FIELDS: string[],
  mapItemToFilter?: (items: T) => T,
) => {
  const ordersParsed = mapItemToFilter ? items.map(mapItemToFilter) : items;

  const filteredItemsSearch = ordersParsed.filter((item) => {
    return SEARCH_FIELDS.some((field) => item[field] && item[field].toLowerCase().includes(searchValue.toLowerCase()));
  });

  return filteredItemsSearch;
};

const returnUniqueItemsAsString = <T extends unknown>(
  filteredItems: T[],
  searchValue: string,
  SEARCH_FIELDS: string[],
) => {
  const uniqueItems = new Set<string>();

  filteredItems.forEach((item) => {
    const matchingField = SEARCH_FIELDS.find(
      (field) => item[field] && item[field].toLowerCase().includes(searchValue.toLowerCase()),
    );
    if (matchingField) {
      uniqueItems.add(item[matchingField]);
    }
  });

  return Array.from(uniqueItems);
};

type UseSearchProps<T> = {
  originalItems: T[];
  setItemsFiltered: React.Dispatch<React.SetStateAction<T[]>>;
  SEARCH_FIELDS: (keyof T & string)[];
  minLength?: number;
  mapItemToFilter?: (items: T) => T;
  itemsPerPage?: number;
};

export const useSearch = <T extends unknown>({
  originalItems,
  setItemsFiltered,
  SEARCH_FIELDS,
  minLength = 1,
  mapItemToFilter,
  itemsPerPage = 25,
}: UseSearchProps<T>) => {
  // Input States
  const [searchValue, setSearchValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  // Search Results
  const [searchResults, setSearchResults] = useState<T[]>([]);
  const [noResultsDynamic, setNoResultsDynamic] = useState(false);
  const [noSearchResults, setNoSearchResults] = useState(false);

  // Results Box, Reset Btn, Clear Icon
  const [showResultsBox, setShowResultsBox] = useState(true);
  const [showClearIcon, setShowClearIcon] = useState(false);
  const [isActiveSearch, setIsActiveSearch] = useState(false);

  const itemsFromSearch: string[] = useMemo(() => {
    if (searchValue.length < minLength) {
      return [];
    }
    const filteredOrdersSearch = filterItems<T>(originalItems, searchValue, SEARCH_FIELDS, mapItemToFilter);
    setSearchResults(filteredOrdersSearch);

    if (filteredOrdersSearch.length === 0) {
      setNoResultsDynamic(true);

      return [];
    } else {
      setNoResultsDynamic(false);
      const uniqueOrders = returnUniqueItemsAsString<T>(filteredOrdersSearch, searchValue, SEARCH_FIELDS);
      return uniqueOrders;
    }
  }, [searchValue, originalItems]);

  // Functions
  const handleSearchByHitClick = (result: string) => {
    const filteredOrdersSearch = filterItems<T>(originalItems, result, SEARCH_FIELDS, mapItemToFilter);

    setItemsFiltered(filteredOrdersSearch);
    setIsActiveSearch(true);
    const resultSanitized = StringHelpers.capitaliseEveryWord(result);
    setSearchValue(resultSanitized);
    setNoSearchResults(false);
    resetValues();
  };

  const handleSearch = () => {
    if (searchValue.length > 0) {
      setItemsFiltered(searchResults);
      setIsActiveSearch(true);
      resetValues();
      checkSearchResults();
    }
  };

  const handleClearSearch = () => {
    setSearchValue('');
    setShowClearIcon(false);
  };

  const handleResetSearchFilter = () => {
    setSearchValue('');
    setShowClearIcon(false);
    setItemsFiltered(originalItems.slice(0, itemsPerPage));
    setIsActiveSearch(false);
    setNoSearchResults(false);
  };

  const checkSearchResults = () => {
    if (!searchResults.length) {
      setNoSearchResults(true);
    } else {
      setNoSearchResults(false);
    }
  };

  const resetValues = () => {
    setShowResultsBox(false);
    setShowClearIcon(true);
    setIsFocused(false);
  };

  return {
    handleClearSearch,
    handleResetSearchFilter,
    handleSearch,
    handleSearchByHitClick,
    setSearchValue,
    setShowResultsBox,
    setIsFocused,
    setNoResultsDynamic,
    isFocused,
    noResultsDynamic,
    showClearIcon,
    showResultsBox,
    isActiveSearch,
    itemsFromSearch,
    searchValue,
    noSearchResults,
  };
};
