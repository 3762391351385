import axios, { AxiosError } from 'axios';
import { GENERIC_TOAST_ERROR_ID, TOAST_ICON } from 'composable/components/general';
import { SHAMROCK_API_URL } from 'helpers/constants/environment';
import { handleAxiosError } from 'helpers/services/shamrock/handleAxiosError';
import moment from 'moment';

export const REQUEST_TIMEOUT = 60000;

export const allAccountOrders = async ([payload, period = 7, { accessToken, logout }, toast, toastMessage]) => {
  if (!accessToken) {
    return;
  }
  const date = period ? moment(new Date().toISOString()).subtract(period, 'd').format('YYYY-MM-DD') : '';
  const startDate = period ? `?start-date=${date}` : '';
  try {
    const { data: multipleAccountOrders } = await axios({
      method: 'POST',
      url: `${SHAMROCK_API_URL}/orders/bulk-get${startDate}`,
      timeout: REQUEST_TIMEOUT,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: payload,
    });

    return multipleAccountOrders;
  } catch (error) {
    console.error('Error API call: allAccountOrders', error);

    if (error.response?.status === 403) {
      await logout(true, 'unauthorized');
    } else if (error instanceof AxiosError) {
      console.log(error);
      handleAxiosError(error, logout);
    }
  }
};

export const allAccountOrderReturns = async ([payload, period = 7, { accessToken, logout }, toast, toastMessage]) => {
  const date = period ? moment(new Date().toISOString()).subtract(period, 'd').format('YYYY-MM-DD') : '';
  const startDate = period ? `?start-date=${date}` : '';
  try {
    const { data: multipleAccountOrders } = await axios({
      method: 'POST',
      url: `${SHAMROCK_API_URL}/returns/bulk-get${startDate}`,
      timeout: REQUEST_TIMEOUT,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: payload,
    });

    return multipleAccountOrders;
  } catch (error) {
    console.error('Error API call: allAccountOrderReturns', error);

    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }

    if (error instanceof AxiosError) {
      handleAxiosError(error, logout);
    }
    throw error;
  }
};
