import { useToast } from '@chakra-ui/react';
import { ExtraProductData } from '@Types/shamrockApi/Product';
import { CACHE_MINUTES_PRODUCT_EXTENDED_DATA, GENERIC_TOAST_ERROR_ID, TOAST_ICON } from 'composable/components/general';
import {
  DeliveryOptionsEligibility,
  ProductFeedback,
  ProductStockNotification,
  SoftCutoffsResponse,
} from 'helpers/services/shamrock';
import { ShamrockApi } from 'helpers/utils/shamrockApi';
import { Cache, mutate } from 'swr';
import { fetchApiHub } from 'frontastic';
import { CACHE_KEY_PRODUCT_DATA } from './constants';
import {
  decodeTokenProps,
  deleteOrderGuideProps,
  getCustomerOrderDetailProps,
  getCustomerOrdersProps,
  getOrderGuideProps,
  GetProductExtendedDataProps,
  CancelOrderApiParams,
  updateCustomerOrderDetailProps,
  GetProductStockNotificationsProps,
  CreateProductStockNotificationsProps,
  DeleteProductStockNotificationsProps,
  PostProductInterestProps,
  SendProductFeedbackProps,
  GetDeliveryOptionsEligibilitiesData,
  GetOrderPrintBackParams,
  GetDeliveryOptionsSoftCutoffsData,
} from './types';

const TTL = CACHE_MINUTES_PRODUCT_EXTENDED_DATA * 60 * 1000;

export type GetProductExtendedDataResult = {
  products?: ExtraProductData[];
  [key: string]: unknown;
};

export const getProductExtendedData = async (
  payload: GetProductExtendedDataProps,
  cache: Cache,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
): Promise<GetProductExtendedDataResult> => {
  try {
    // Check ids in cache
    let productsToFetch: string[] = payload.productNumbers;
    const cachedResults: ExtraProductData[] = [];

    if (cache) {
      productsToFetch = [];
      (payload?.productNumbers || []).forEach((productNumber) => {
        // Load data from SWR cache
        const cachedProduct = cache.get(`${CACHE_KEY_PRODUCT_DATA}${productNumber}`);
        const now = new Date();
        const lastUpdated = new Date(cachedProduct?.data?.updated?.getTime() + TTL);
        // If data is valid use it
        // Otherwise we'll fetch it from the API
        Boolean(cachedProduct?.data) && now <= lastUpdated
          ? cachedResults.push(cachedProduct.data.product)
          : productsToFetch.push(productNumber);
      });
    }

    let res: GetProductExtendedDataResult = { products: [] };
    // Skip API call if all products in the cache
    if (productsToFetch.length > 0) {
      // Fetch ids not in cache
      const customPayload = {
        ...payload,
        productNumbers: productsToFetch,
      };

      res = (await fetchApiHub(
        `/action/shamrockApi/${ShamrockApi.GetExtraProductData}`,
        { method: 'POST' },
        customPayload,
        2,
      )) as GetProductExtendedDataResult;
      // Update cache
      (res?.products || []).forEach((product) => {
        mutate(
          `${CACHE_KEY_PRODUCT_DATA}${product.productNumber}`,
          { product, updated: new Date() },
          { revalidate: false },
        );
      });
    }

    res.products?.push(...cachedResults);
    return res;
  } catch (error) {
    console.error('Error API call: getProductExtendedData', error);

    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }

    throw error;
  }
};

export const getProductExtendedDataWithoutCache = async (
  payload: GetProductExtendedDataProps,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
) => {
  try {
    const res = await fetchApiHub(
      `/action/shamrockApi/${ShamrockApi.GetExtraProductData}`,
      { method: 'POST' },
      payload,
    );
    mutate(`/action/shamrockApi/${ShamrockApi.GetExtraProductData}`, res, { revalidate: false });
    return res;
  } catch (error) {
    console.error('Error API call: getProductExtendedDataWithoutCache', error);

    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    throw error;
  }
};

export const getCustomerInShamrock = async (
  accessToken: string,
  decodeToken: decodeTokenProps,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
) => {
  try {
    const res = await fetchApiHub(
      '/action/shamrockApi/getShamrockUser',
      { method: 'POST' },
      {
        accessToken: accessToken,
        decodeToken: decodeToken,
      },
    );
    mutate('/action/shamrockApi/getShamrockUser', res, { revalidate: false });
    return res;
  } catch (error) {
    console.error('Error API call: getCustomerInShamrock', error);

    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    throw error;
  }
};

export const getOrderGuide = async (
  payload: getOrderGuideProps,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
) => {
  try {
    const res = await fetchApiHub('/action/shamrockApi/getCustomerOrderGuide', { method: 'POST' }, payload, 2);
    mutate('/action/shamrockApi/getCustomerOrderGuide', res, { revalidate: false });
    return res;
  } catch (error) {
    console.error('Error API call: getOrderGuide', error);

    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    throw error;
  }
};

export const getCustomerOrders = async (
  payload: getCustomerOrdersProps,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
) => {
  try {
    const res = await fetchApiHub('/action/shamrockApi/getCustomerOrders', { method: 'POST' }, payload);
    mutate('/action/shamrockApi/getCustomerOrders', res, { revalidate: false });
    return res;
  } catch (error) {
    console.error('Error API call: getCustomerOrders', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    throw error;
  }
};

export const getCustomerOrderDetail = async (
  payload: getCustomerOrderDetailProps,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
) => {
  try {
    const res = await fetchApiHub('/action/shamrockApi/getCustomerOrderDetail', { method: 'POST' }, payload);
    mutate('/action/shamrockApi/getCustomerOrderDetail', res, { revalidate: false });
    return res;
  } catch (error) {
    console.error('Error API call: getCustomerOrderDetail', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    throw error;
  }
};

export const updateCustomerOrderDetail = async (
  payload: updateCustomerOrderDetailProps,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
) => {
  try {
    const res = await fetchApiHub('/action/shamrockApi/updateCustomerOrderDetail', { method: 'POST' }, payload);
    mutate('/action/shamrockApi/updateCustomerOrderDetail', res, { revalidate: false });
    return res;
  } catch (error) {
    console.error('Error API call: updateCustomerOrderDetail', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    throw error;
  }
};

export const deleteOrderGuide = async (
  payload: deleteOrderGuideProps,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
) => {
  try {
    const res = await fetchApiHub('/action/shamrockApi/deleteOrderGuide', { method: 'DELETE' }, payload);
    mutate('/action/shamrockApi/deleteOrderGuide', res, { revalidate: false });
    return res;
  } catch (error) {
    console.error('Error API call: deleteOrderGuide', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    throw error;
  }
};

export const cancelCustomerOrder = async (
  payload: CancelOrderApiParams,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
) => {
  try {
    const res = await fetchApiHub('/action/shamrockApi/cancelOrder', { method: 'DELETE' }, payload);
    mutate('/action/shamrockApi/cancelOrder', res, { revalidate: false });
    return res;
  } catch (error) {
    console.error('Error API call: cancelCustomerOrder', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    throw error;
  }
};

export const getOrderPrintBack = async (
  payload: GetOrderPrintBackParams,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
) => {
  try {
    const res = await fetchApiHub('/action/shamrockApi/getOrderPrintBack', { method: 'POST' }, payload);
    mutate('/action/shamrockApi/getOrderPrintBack', res, { revalidate: false });
    return res;
  } catch (error) {
    console.error('Error API call: getOrderPrintBack', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }
    throw error;
  }
};

export const getDeliveryOptionsEligibilities = async (
  accessToken: string,
  payload: GetDeliveryOptionsEligibilitiesData[],
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
): Promise<DeliveryOptionsEligibility[]> => {
  try {
    const res = await fetchApiHub(
      `/action/shamrockApi/${ShamrockApi.GetDeliveryOptionsEligibilities}`,
      { method: 'POST' },
      { accessToken, data: payload },
    );
    mutate(`/action/shamrockApi/${ShamrockApi.GetDeliveryOptionsEligibilities}`, res, { revalidate: false });
    return res;
  } catch (error) {
    console.error('Error API call: getDeliveryOptionsEligibilities', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }

    throw error;
  }
};

export const getProductStockNotifications = async (
  payload: GetProductStockNotificationsProps,
): Promise<ProductStockNotification> => {
  try {
    const res = await fetchApiHub('/action/shamrockApi/getProductStockNotifications', { method: 'POST' }, payload);
    mutate('/action/shamrockApi/getProductStockNotifications', res, { revalidate: false });
    return res;
  } catch (error) {
    console.error('Error API call: getProductStockNotifications', error);

    throw error;
  }
};

export const createProductStockNotifications = async (
  payload: CreateProductStockNotificationsProps,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
): Promise<ProductStockNotification> => {
  try {
    const res = await fetchApiHub('/action/shamrockApi/createProductStockNotifications', { method: 'POST' }, payload);
    mutate('/action/shamrockApi/createProductStockNotifications', res, { revalidate: false });
    return res;
  } catch (error) {
    console.error('Error API call: createProductStockNotifications', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }

    throw error;
  }
};

export const deleteProductStockNotifications = async (
  payload: DeleteProductStockNotificationsProps,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
): Promise<ProductStockNotification> => {
  try {
    const res = await fetchApiHub('/action/shamrockApi/deleteProductStockNotifications', { method: 'POST' }, payload);
    mutate('/action/shamrockApi/deleteProductStockNotifications', res, { revalidate: false });
    return res;
  } catch (error) {
    console.error('Error API call: deleteProductStockNotifications', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }

    throw error;
  }
};

export const postProductInterest = async (
  payload: PostProductInterestProps,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
): Promise<{ success: boolean; errorMessage?: null | string }> => {
  try {
    const res = await fetchApiHub('/action/shamrockApi/postProductInterest', { method: 'POST' }, payload);
    mutate('/action/shamrockApi/postProductInterest', res, { revalidate: false });
    return res;
  } catch (error) {
    console.error('Error API call: postProductInterest', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }

    throw error;
  }
};

export const sendProductFeedback = async (
  payload: SendProductFeedbackProps,
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
): Promise<ProductFeedback> => {
  try {
    const res = await fetchApiHub('/action/shamrockApi/sendProductFeedback', { method: 'POST' }, payload);
    mutate('/action/shamrockApi/sendProductFeedback', res, { revalidate: false });
    return res;
  } catch (error) {
    console.error('Error API call: sendProductFeedback', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }

    throw error;
  }
};

export const getDeliveryOptionsSoftCutoffs = async (
  accessToken: string,
  payload: GetDeliveryOptionsSoftCutoffsData,
): Promise<SoftCutoffsResponse> => {
  try {
    const res = await fetchApiHub(
      `/action/shamrockApi/${ShamrockApi.GetDeliveryOptionsSoftCutoffs}`,
      { method: 'POST' },
      { accessToken, data: payload },
    );
    mutate(`/action/shamrockApi/${ShamrockApi.GetDeliveryOptionsSoftCutoffs}`, res, { revalidate: false });
    return res;
  } catch (error) {
    console.error('Error API call: getDeliveryOptionsSoftCutoffs', error);
    throw error;
  }
};
