import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import { Customer } from '@Types/customer/Customer';

interface AlgoliaInsightsInterface {
  user: Partial<Customer>;
  activeAccount: Partial<AsAssociateBusinessUnitResult>;
  indexName?: string;
}

interface AlgoliaInsightsClickAfterSearchEvent extends AlgoliaInsightsInterface {
  objectID: string;
  queryID: string;
  position: number;
}

interface AlgoliaInsightsClickEvent extends AlgoliaInsightsInterface {
  objectIDs: string[];
  customData?: Record<string, any>;
}

interface AlgoliaInsightsAddedToCartSearchEvent extends AlgoliaInsightsInterface {
  queryID?: string;
  objectIDs: string[];
  objectData?: AlgoliaObjectData[];
  totalValue?: number;
  customData?: Record<string, any>;
}

interface AlgoliaPurchasedObjectEvent extends AlgoliaInsightsAddedToCartSearchEvent {}

export interface AlgoliaObjectData {
  price: string | number;
  discount?: number;
  quantity?: number;
}

export interface AlgoliaInsightsViewedObjectIDsEvent extends AlgoliaInsightsInterface {
  objectIDs: string[];
  customData?: Record<string, any>;
}

export interface AlgoliaInsightsViewedFiltersEvent extends AlgoliaInsightsInterface {
  customData?: Record<string, any>;
  filters: string[];
}

interface AlgoliaInsightsClickedFiltersEvent extends AlgoliaInsightsViewedFiltersEvent {}

export enum AlgoliaInsightsEventNames {
  PRODUCT_CLICKED_AFTER_SEARCH = 'Product Clicked',
  PRODUCT_CLICKED = 'Clicked Product',
  PRODUCT_ADDED_TO_CART = 'Product Converted',
  PRODUCT_ADDED_TO_CART_AFTER_SEARCH = 'Product Converted After Search',
  PRODUCT_PURCHASED = 'Product Purchased',
  PRODUCT_PURCHASED_AFTER_SEARCH = 'Product Purchased After Search',
  PRODUCT_VIEWED = 'Product Viewed',
  VIEWED_FILTERS = 'Filters Viewed',
  CLICKED_FILTERS = 'Filters Clicked',
}

export type { AlgoliaInsightsInterface as AlgoliaInsightsProps };
export type { AlgoliaInsightsClickAfterSearchEvent as AlgoliaInsightsClickAfterSearchEventProps };
export type { AlgoliaInsightsClickEvent as AlgoliaInsightsClickEventProps };
export type { AlgoliaInsightsAddedToCartSearchEvent as AlgoliaInsightsAddedToCartSearchEventProps };
export type { AlgoliaPurchasedObjectEvent as AlgoliaPurchasedObjectEventProps };
export type { AlgoliaInsightsViewedObjectIDsEvent as AlgoliaInsightsViewedObjectIDsEventProps };
export type { AlgoliaInsightsViewedFiltersEvent as AlgoliaInsightsViewedFiltersEventProps };
export type { AlgoliaInsightsClickedFiltersEvent as AlgoliaInsightsClickedFiltersEventProps };
