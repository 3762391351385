export const cartItemsAnalyticsManager = () => {
  const cart = window.localStorage.getItem('current-cart');
  const setCartData = (lineItem, action) => {
    if (lineItem) {
      const cartData = cart ? JSON.parse(cart) : { lineItems: [] };

      const itemIndex = cartData.lineItems.findIndex((item) => item.productId === lineItem.productId);
      if (action === 'remove' && itemIndex > -1) {
        cartData.lineItems.splice(itemIndex, 1);
      }
      if (action === 'add' && itemIndex === -1) {
        cartData.lineItems.push(lineItem);
      }

      window.localStorage.setItem('current-cart', JSON.stringify(cartData));
    }
  };

  const getCartData = () => {
    const cartData = cart ? JSON.parse(cart) : { lineItems: [] };
    return cartData;
  };

  return {
    getCartData,
    setCartData,
  };
};

export const viewProductAnalytics = () => {
  const product = window.localStorage.getItem('view-product');
  const setViewProduct = (productId, index) => {
    window.localStorage.setItem(
      'view-product',
      JSON.stringify({
        productId,
        index: index || 0,
      }),
    );
  };

  const getViewProduct = () => {
    const productData = product ? JSON.parse(product) : {};
    return productData;
  };

  return {
    getViewProduct,
    setViewProduct,
  };
};
