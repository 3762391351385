import { Styles } from '@chakra-ui/theme-tools';
import { HEADER_DESKTOP_HEIGHT, LAYOUT_VALUES } from 'composable/components/pdp/constants';

const styles: Styles = {
  global: (props) => ({
    // body: {
    //   fontFamily: 'body',
    //   color: mode('gray.800', 'whiteAlpha.900')(props),
    //   bg: mode('red', 'green')(props),
    // },

    '#chakra-toast-manager-top': {
      gap: '8px',
      mt: { base: `70px !important`, xl: `${HEADER_DESKTOP_HEIGHT + 60}px !important` },
    },
    '.chakra-alert': {
      borderRadius: '0.375rem!important',
      boxShadow: 'none!important',
    },
    '.chakra-toast': {
      p: '0px!important',
      mb: '0px!important',
      mr: '0px!important',
      ml: { base: 0, xl: '246px' },
      // mt: { base: `${HEADER_HEIGHT}px`, xl: `${HEADER_DESKTOP_HEIGHT}px` },
    },
    '.chakra-toast__inner': {
      maxWidth: '100%!important',
      width: '100%',
      m: '0px!important',
      // pt: { base: 4, xl: 10 },
      pb: '0px!important',
      pr: LAYOUT_VALUES.PADDING_RIGHT,
      pl: LAYOUT_VALUES.PADDING_LEFT,
    },
    '.chakra-alert__icon': { h: '24px!important', w: '24px!important' },
    select: {
      pt: '0.1rem',
    },
    '*:focus': {
      boxShadow: 'none !important',
    },
    '*[data-focus]': {
      boxShadow: 'none !important',
    },
  }),
};

export const SCROLLBAR_STYES = {
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    width: '6px',
    backgroundColor: '#C8C9D0',
    borderRadius: '4px',
    marginBottom: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: '#6C6D75',
  },
};

export default styles;
