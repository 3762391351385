const languageMapper = {
  en_US: 'en_US',
  'en-US': 'en_US'
};
const mapLanguage = lang => {
  if (!languageMapper[lang]) {
    console.warn(`Language mapper is missing language ${lang}`);
  }

  //If language is not defined in languageMapper then select first locale
  return languageMapper[lang] || languageMapper[Object.keys(languageMapper)[0]];
};
module.exports = {
  languageMapper,
  mapLanguage
};