import { Address } from '@Types/account/Address';
import { Tastic } from 'frontastic';

export enum CheckoutActionType {
  SET_COMMENTS,
  SET_DELIVERY_DATE,
  SET_PO_NUMBER,
  SET_SHIPPING_METHOD,
  SET_DELIVERY_WINDOW,
  SET_WAREHOUSE_ADDRESS,
  SET_GEO_CODE,
  SET_SECONDARY_DATE,
  SET_SPECIAL_CUISINES,
}

export type CheckoutAction =
  | {
      type: CheckoutActionType.SET_COMMENTS;
      payload: string;
    }
  | {
      type: CheckoutActionType.SET_DELIVERY_DATE;
      payload: Date;
    }
  | {
      type: CheckoutActionType.SET_SECONDARY_DATE;
      payload: Date;
    }
  | {
      type: CheckoutActionType.SET_PO_NUMBER;
      payload: string;
    }
  | {
      type: CheckoutActionType.SET_SHIPPING_METHOD;
      payload: string;
    }
  | {
      type: CheckoutActionType.SET_DELIVERY_WINDOW;
      payload: string;
    }
  | {
      type: CheckoutActionType.SET_WAREHOUSE_ADDRESS;
      payload: Partial<Address>;
    }
  | {
      type: CheckoutActionType.SET_GEO_CODE;
      payload: string;
    }
  | {
      type: CheckoutActionType.SET_SPECIAL_CUISINES;
      payload: Tastic;
    };

export type CheckoutState = {
  comments?: string;
  deliveryDate?: Date;
  poNumber?: string;
  shippingMethod?: string;
  deliveryWindow?: string;
  warehouseAddress?: Partial<Address>;
  geocode?: string;
  secondaryDate?: Date;
  specialCuisines?: Tastic;
};

export const checkoutReducer = (state: CheckoutState, { type, payload }: CheckoutAction): CheckoutState => {
  switch (type) {
    case CheckoutActionType.SET_COMMENTS:
      return {
        ...state,
        comments: payload,
      };

    case CheckoutActionType.SET_DELIVERY_DATE:
      return {
        ...state,
        deliveryDate: payload,
      };

    case CheckoutActionType.SET_SECONDARY_DATE:
      return {
        ...state,
        secondaryDate: payload,
      };

    case CheckoutActionType.SET_PO_NUMBER:
      return {
        ...state,
        poNumber: payload,
      };
    case CheckoutActionType.SET_SHIPPING_METHOD:
      return {
        ...state,
        shippingMethod: payload,
      };
    case CheckoutActionType.SET_DELIVERY_WINDOW:
      return {
        ...state,
        deliveryWindow: payload,
      };
    case CheckoutActionType.SET_WAREHOUSE_ADDRESS:
      return {
        ...state,
        warehouseAddress: payload,
      };
    case CheckoutActionType.SET_GEO_CODE:
      return {
        ...state,
        geocode: payload,
      };
    default:
      return state;
  }
};
