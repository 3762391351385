import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useToast } from '@chakra-ui/react';
import { useGlobal } from 'components/globalProvider';
import { KEY_A_LA_CARTE, KEY_PICK_UP } from 'composable/components/checkout/utils/constants';
import { SWR_OPTIONS } from 'composable/components/order-guide/helpers';
import { hasCreditOnHoldInfo } from 'composable/helpers/utils/user-utils';
import { SHAMROCK_API_URL } from 'helpers/constants/environment';
import routes from 'helpers/constants/routes';
import { useFormat } from 'helpers/hooks/useFormat';
import { FormattedDeliveryOption } from 'helpers/services/shamrock';
import { MAX_DELIVERY_DATES, deliveryOptions } from 'helpers/utils/fetchers';
import every from 'lodash/every';
import values from 'lodash/values';
import moment from 'moment-timezone';
import useSWR, { useSWRConfig } from 'swr';
import { calculateLatestDeliveryDate } from 'utils/checkout/calculateLatestDeliveryDate';
import { useGetExtraProductData } from './useGetExtraProductData';

const WILL_CALL = 'WillCall';
const A_LA_CARTE = 'ALaCarte';
const BASE_URL = `${SHAMROCK_API_URL}/delivery-options/search`;

type ProductsForDeliveryWindow = {
  productNumber: string;
  quantity: number;
};

export const useGetDeliveryWindows = (
  products: ProductsForDeliveryWindow[],
  orderNumber?: string,
  eligibleForPickup?: boolean,
  eligibleForALaCarte?: boolean,
  placingOrder: boolean = false,
) => {
  const { state: user, logout } = useGlobal().useUserGlobal;
  const { activeWarehouse, accessToken, activeAccount } = user;
  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });
  const skus = products.map((product) => product.productNumber);
  const { cache } = useSWRConfig();
  const { extraProductData, isLoadingExtraData } = useGetExtraProductData(skus, user, cache);
  const [aLaCarteOptions, setALaCarteOptions] = useState([]);
  const [pickupOptions, setPickupOptions] = useState([]);
  const { asPath } = useRouter();

  const payload = useMemo(() => {
    if (!activeWarehouse || !accessToken || !activeAccount || isLoadingExtraData || !extraProductData.length) {
      return null;
    }

    // Get latest delivery date from product data
    let _startingAfter = calculateLatestDeliveryDate(extraProductData);
    const today = moment().tz('UTC').endOf('day');
    // if startingAfter is today send null
    if (_startingAfter.isBefore(today)) {
      _startingAfter = null;
    } else {
      // if in the future update date to be the end of the previous day
      _startingAfter = _startingAfter.add(-1, 'day').endOf('day');
    }

    return {
      warehouseNumber: activeWarehouse?.warehouseNumber,
      businessUnitName: activeWarehouse?.businessUnit,
      businessSegmentName: activeWarehouse?.businessSegment,
      customerNumber: activeAccount?.key,
      products,
      startingAfter: _startingAfter?.toISOString(),
    };
  }, [accessToken, extraProductData, isLoadingExtraData, products, activeAccount, activeWarehouse]);

  const isValidPayload =
    !!payload && every(values(payload), (val) => val !== '' && !(Array.isArray(val) && val.length === 0));
  const hasCreditOnHold = hasCreditOnHoldInfo(activeAccount);
  const readyToFetch =
    isValidPayload &&
    !hasCreditOnHold &&
    products?.length > 0 &&
    !placingOrder &&
    !isLoadingExtraData &&
    [routes.CHECKOUT, routes.EDIT_ORDER_CHECKOUT].includes(asPath);

  const {
    data: deliveryOptionsData,
    isLoading: isLoadingDeliveryOptions,
    isValidating: isValidatingDeliveryOptions,
  } = useSWR(
    () => {
      if (readyToFetch) {
        let deliveryMethod;
        if (eligibleForPickup && !eligibleForALaCarte) {
          deliveryMethod = WILL_CALL;
        } else if (eligibleForALaCarte && !eligibleForPickup) {
          deliveryMethod = A_LA_CARTE;
        }

        return [
          BASE_URL,
          {
            ...getObjectPayload(payload, orderNumber, deliveryMethod),
          },
        ];
      }
      return null;
    },
    ([url, payload]) =>
      deliveryOptions([
        url,
        { ...payload, accessToken, logout, toast, toastMessage: formatMessage({ id: 'app.generic.error' }) },
      ]),
    { ...SWR_OPTIONS, revalidateIfStale: true, errorRetryCount: 2 },
  );

  useEffect(() => {
    if (!isLoadingDeliveryOptions) {
      const aLaCarteOptionsList = deliveryOptionsData?.deliveryOptions
        .filter((option) => option?.deliveryType === A_LA_CARTE)
        .slice(0, MAX_DELIVERY_DATES);

      const pickupOptionsList = deliveryOptionsData?.deliveryOptions
        .filter((option) => option?.deliveryType === WILL_CALL)
        .slice(0, MAX_DELIVERY_DATES);

      if (aLaCarteOptionsList && aLaCarteOptionsList.length > 0) {
        setALaCarteOptions(aLaCarteOptionsList);
      }
      if (pickupOptionsList && pickupOptionsList.length > 0) {
        setPickupOptions(pickupOptionsList);
      }
    }
  }, [deliveryOptionsData?.deliveryOptions, isLoadingDeliveryOptions]);

  return {
    geocodeCutoffDate: deliveryOptionsData?.geocodeCutoffDate,
    geocodeCutoffTime: deliveryOptionsData?.geocodeCutoffTime,
    deliveryWindows: {
      [KEY_A_LA_CARTE]: (aLaCarteOptions as FormattedDeliveryOption[]) || [],
      [KEY_PICK_UP]: (pickupOptions as FormattedDeliveryOption[]) || [],
    },
    deliveryMinimums: deliveryOptionsData?.deliveryMinimums || [],
    isLoading: {
      [KEY_A_LA_CARTE]: isLoadingDeliveryOptions || isValidatingDeliveryOptions,
      [KEY_PICK_UP]: isLoadingDeliveryOptions || isValidatingDeliveryOptions,
    },
  };
};

//Private functions
const getObjectPayload = (payload: any, orderNumber: string, method?: 'WillCall' | 'ALaCarte') => {
  const payloadData = {
    payload: {
      ...payload,
      orderNumber,
    },
  };

  if (method) {
    payloadData.payload.method = method;
  }

  return payloadData;
};
