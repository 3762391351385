import { TrendingItemsGlobalType, TrendingLineItemsAction, TrendingLineItemsActionType } from './types';

export const trendingItemsReducer = (state: TrendingItemsGlobalType, { type, payload }: TrendingLineItemsAction) => {
  switch (type) {
    case TrendingLineItemsActionType.SET_TRENDING_LINEITEMS:
      return { ...state, trendingItemsList: payload };
    case TrendingLineItemsActionType.SET_LOADING:
      return { ...state, loading: payload };
    default:
      return state;
  }
};
