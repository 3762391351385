// workaround for MACKAGE site theme
// TODO update once we have CMS controlled theme
const _primaryColor = process.env.NEXT_PUBLIC_THEME_COLOR_PRIMARY ?? undefined;

export const palette = {
  white: '#ffffff',
  black: '#000000',
  greenGradient: 'linear-gradient(90deg, #12875E 0%, #2DA343 100%)',
  blackGradient: 'linear-gradient(179deg, #2F3037 6.97%, #1F1F23 28.76%)',

  // Shamrock Palette: https://www.figma.com/file/vOkcNtKe1KNzniwEejY6BA/Shamrock-Design-System-v2?node-id=332%3A9736&mode=dev
  primary: {
    '50': '#E8FCED',
    '100': '#E4FCEA',
    '200': '#ADF5C7',
    '300': '#63D998',
    '400': '#40B781',
    '500': '#12875E',
    '600': '#0C6E55',
    '700': '#08594C',
    '800': '#044340',
    '900': '#033436',
  },
  secondary: {
    '50': '#FAFEF1',
    '100': '#F5FCE2',
    '200': '#E8FAC7',
    '300': '#D3F0A6',
    '400': '#BBE18B',
    '500': '#9BCD65',
    '600': '#7AB049',
    '700': '#5B9332',
    '800': '#407620',
    '900': '#2D6213',
  },
  neutral: {
    '50': '#F1F1F3',
    '100': '#E4E4E7',
    '200': '#C8C9D0',
    '300': '#ADAEB8',
    '400': '#9293A0',
    '500': '#777788',
    '650': '#6C6D75',
    '600': '#5F606D',
    '700': '#474852',
    '750': '#3b3c45',
    '800': '#2F3037',
    '900': '#1F1F23',
  },
  yellow: {
    '50': '#FFFBEB',
    '100': '#FEF6D6',
    '200': '#FEECB3',
    '300': '#FCDB83',
    '400': '#F8C553',
    '500': '#F5AD23',
    '600': '#D39227',
    '700': '#B1731B',
    '800': '#72450E',
    '900': '#552F07',
  },
  orange: {
    '50': '#FEF7EC',
    '100': '#FDEDD4',
    '200': '#FBD6AA',
    '300': '#F4B77D',
    '400': '#EA975C',
    '500': '#DC6A2A',
    '600': '#BD4D1E',
    '700': '#9E3515',
    '800': '#7F210D',
    '900': '#691208',
  },
  red: {
    '50': '#FEECE7',
    '100': '#FDDDD4',
    '200': '#FBBCAA',
    '300': '#F4927D',
    '400': '#EA685C',
    '500': '#DC2A35',
    '600': '#BD1E36',
    '700': '#9E1536',
    '800': '#7F0D33',
    '900': '#690831',
  },
  magenta: {
    '50': '#FDF1F1',
    '100': '#FBDFDF',
    '200': '#F6BBC1',
    '300': '#E87D92',
    '400': '#D8416E',
    '500': '#D21D65',
    '600': '#A9135E',
    '700': '#820D53',
    '800': '#5F0744',
    '900': '#440337',
  },
  purple: {
    '50': '#FBEAF4',
    '100': '#F7D6E9',
    '200': '#F0AED9',
    '300': '#D47DBE',
    '400': '#AA529B',
    '500': '#92208A',
    '600': '#771778',
    '700': '#581560',
    '800': '#420D4F',
    '900': '#290636',
  },
  violet: {
    '50': '#F1EDFD',
    '100': '#E3D9FB',
    '200': '#C7B5F7',
    '300': '#A28BE7',
    '400': '#8068D0',
    '500': '#543CB2',
    '600': '#3F2B99',
    '700': '#2E1E80',
    '800': '#1E1367',
    '900': '#140B55',
  },
  // All objects below are NOT from the Shamrock Palette
  tertiary: {
    '100': '#FDFBFA',
    '200': '#FBF7F6',
    '300': '#F3EDED',
    '400': '#E8E0E0',
    '500': '#DAD0D1',
    '600': '#BB989E',
    '700': '#9C6877',
    '800': '#7E4258',
    '900': '#682746',
  },
  success: {
    '100': '#D2FBD3',
    '200': '#A7F8B0',
    '300': '#78EB90',
    '400': '#54D87C',
    '500': '#24BF62',
    '600': '#1AA45F',
    '700': '#128959',
    '800': '#0B6E50',
    '900': '#065B4A',
  },
  danger: {
    '100': '#FFE4D9',
    '200': '#FFC3B4',
    '300': '#FF9B8E',
    '400': '#FF7572',
    '500': '#FF4451',
    '600': '#DB314C',
    '700': '#B72247',
    '800': '#931540',
    '900': '#7A0D3C',
  },
  warning: {
    '100': '#FDEDD1',
    '200': '#FBD6A5',
    '300': '#F4B777',
    '400': '#EA9854',
    '500': '#DD6B20',
    '600': '#BE4F17',
    '700': '#9F3710',
    '800': '#80240A',
    '900': '#6A1606',
  },
  info: {
    '100': '#DCF4FE',
    '200': '#B9E6FE',
    '300': '#96D4FE',
    '400': '#7BC2FD',
    '500': '#50A6FC',
    '600': '#3A81D8',
    '700': '#2860B5',
    '800': '#194392',
    '900': '#0F2F78',
  },
  shading: {
    '100': '#F4F4F4',
    '200': '#EAEAEA',
    '300': '#C0C0C0',
    '400': '#828282',
    '500': '#404040',
    '600': '#303030',
    '700': '#222222',
    '800': '#1B1B1B',
    '900': '#111111',
  },
  green: {
    '1000': '#2DA343',
  },
};

// Color Tokens - Global Status
const colorTokens = {
  // From Shamrock Palette: https://www.figma.com/file/vOkcNtKe1KNzniwEejY6BA/Shamrock-Design-System-v2?node-id=333%3A13415&mode=dev

  // info
  'info-light': palette.violet['100'],
  'info-med': palette.violet['500'],
  'info-dark': palette.violet['800'],

  // success
  'success-light': palette.secondary['100'],
  'success-med': palette.secondary['600'],
  'success-dark': palette.secondary['900'],

  // warning
  'warning-light': palette.yellow['100'],
  'warning-med': palette.yellow['500'],
  'warning-dark': palette.yellow['800'],

  // danger
  'danger-light': palette.red['100'],
  'danger-med': palette.red['500'],
  'danger-dark': palette.red['900'],

  // received
  'received-lightmode-fill': palette.white,
  'received-lightmode-stroke': palette.neutral['900'],
  'received-lightmode-text': palette.neutral['900'],
  'received-lightmode-progress': palette.neutral['700'],
  'received-darkmode-fill': palette.neutral['900'],
  'received-darkmode-stroke': palette.white,
  'received-darkmode-text': palette.white,
  'received-darkmode-progress': palette.neutral['200'],

  //processing
  'processing-lightmode-fill': palette.yellow['100'],
  'processing-lightmode-stroke': palette.yellow['400'],
  'processing-lightmode-text': palette.neutral['900'],
  'processing-lightmode-progress': palette.yellow['500'],
  'processing-darkmode-fill': palette.yellow['900'],
  'processing-darkmode-stroke': palette.yellow['700'],
  'processing-darkmode-text': palette.white,
  'processing-darkmode-progress': palette.yellow['500'],

  //pending
  'pending-lightmode-fill': palette.orange['100'],
  'pending-lightmode-stroke': palette.orange['400'],
  'pending-lightmode-text': palette.neutral['900'],
  'pending-lightmode-progress': palette.orange['500'],
  'pending-darkmode-fill': palette.orange['900'],
  'pending-darkmode-stroke': palette.orange['700'],
  'pending-darkmode-text': palette.white,
  'pending-darkmode-progress': palette.orange['500'],

  //out for delivery
  'out-for-delivery-lightmode-fill': palette.primary['100'],
  'out-for-delivery-lightmode-stroke': palette.primary['400'],
  'out-for-delivery-lightmode-text': palette.neutral['900'],
  'out-for-delivery-lightmode-progress': palette.primary['400'],
  'out-for-delivery-darkmode-fill': palette.primary['900'],
  'out-for-delivery-darkmode-stroke': palette.primary['700'],
  'out-for-delivery-darkmode-text': palette.white,
  'out-for-delivery-darkmode-progress': palette.primary['400'],

  //delivered
  'delivered-lightmode-fill': palette.primary['600'],
  'delivered-lightmode-stroke': palette.primary['600'],
  'delivered-lightmode-text': palette.white,
  'delivered-lightmode-progress': palette.primary['600'],
  'delivered-darkmode-fill': palette.primary['600'],
  'delivered-darkmode-stroke': palette.primary['600'],
  'delivered-darkmode-text': palette.white,
  'delivered-darkmode-progress': palette.primary['600'],
};

export const paletteTokens = {
  colors: {
    light: {
      background: palette.white,
      text: palette.black, //Body foreground color
      'text-muted': palette.shading['400'], // Body foreground color (muted) for alternative styling
      // TODO update once we have CMS controlled theme
      primary: _primaryColor ?? palette.primary['500'], // Primary color for links, buttons, etc.
      secondary: palette.secondary['700'], //A secondary brand color for alternative styling
      highlight: palette.secondary['500'], //A highlight color for emphasizing UI
      muted: palette.tertiary['500'],
      accent: palette.tertiary['700'], //A contrast color for emphasizing UI
    },
    dark: {
      background: palette.black,
      text: palette.shading['100'], //Body foreground color
      'text-muted': palette.shading['300'], // Body foreground color (muted) for alternative styling
      // TODO update once we have CMS controlled theme
      primary: _primaryColor ?? palette.primary['600'], // Primary color for links, buttons, etc
      secondary: palette.primary['400'], //A secondary brand color for alternative styling
      highlight: palette.secondary['500'], //A highlight color for emphasizing UI
      muted: palette.tertiary['500'],
      accent: palette.tertiary['700'], //A contrast color for emphasizing UI
    },
  },
};
export type Colors = typeof palette & typeof paletteTokens & typeof colorTokens;

const colors = { ...palette, ...paletteTokens, ...colorTokens };

export default colors;
