import { AbortError } from 'p-retry';
import { FrontasticError } from './FrontasticError';
import { ResponseError } from '../fetch-api-hub';
import { getServerAzureLogoutUrl } from 'composable/helpers/utils/use-user-utils';

enum ERROR_CODES {
  ECONNRESET = 'ECONNRESET',
  NOT_AUTHORIZED = 'User not authenticated',
}

type ShamrockError = {
  code?: string;
  message?: string;
};

export const handleShamrockErrors = (error: object | string, errObj: ResponseError | undefined) => {
  const errorStatus = errObj?.getStatus();
  if (errorStatus === 401 && error === ERROR_CODES.NOT_AUTHORIZED) {
    window.location.href = getServerAzureLogoutUrl();
    return;
  }
  if (errorStatus === 401 || errorStatus >= 500) {
    const message = errorStatus === 500 ? 'Unexpected Error' : 'User not authenticated';
    throw new AbortError(new FrontasticError(message, errorStatus));
  }
  if (typeof error === 'string') {
    return;
  }
  const shamrockError = error as ShamrockError;
  if (shamrockError.code && shamrockError.code === ERROR_CODES.ECONNRESET) {
    //TODO define a better string than 'generic'
    //This is the way to force the retry
    throw new Error(`Error on Shamrock API: ${shamrockError.message || 'generic'}`);
  }
};
