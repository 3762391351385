import { ACCESS_TOKEN } from 'composable/helpers/constants';
import { useLocalStorage } from 'composable/helpers/hooks';
import { useRouter } from 'next/router';

const PUBLIC = 'public';

export const useCheckURL = () => {
  const [localStorageAccessToken] = useLocalStorage(ACCESS_TOKEN, '');
  const router = useRouter();
  const { asPath } = router;

  const isPublic = asPath.includes(PUBLIC);

  return isPublic && !localStorageAccessToken;
};

export const useShouldExecuteApi = (hook, fallBackResults: any) => {
  return (...args) => {
    //eslint-disable-next-line
    const cancelApiCall = useCheckURL();

    if (cancelApiCall) {
      // console.log('CANCELING API CALL FOR HOOK', hook.name);
      return fallBackResults;
    }

    // Execute the original hook with the provided arguments
    return hook(...args);
  };
};
