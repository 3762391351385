import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import { Customer } from '@Types/customer/Customer';
import { CAN_ACCESS_SALES_ORDER_ENTRY } from 'helpers/constants/userPermissions';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Appcues: any;
  }
}

const INDEX_FOR_WAREHOUSE = 2;

export const appcuesIdentifyAndGroupUser = (
  customerObj: Partial<Customer>,
  selectedBusinessUnit: Partial<AsAssociateBusinessUnitResult>,
  permissions: string[],
) => {
  const customFields = selectedBusinessUnit?.custom?.fields;
  const sfcBusinessUnitStores = JSON.parse(customFields?.sfc_business_unit_stores || '[]');
  const ctCustomer = customerObj;

  const { shipping, billing } = getShippingAndBillingAddresses(selectedBusinessUnit);

  const salesOrderEntry = permissions?.includes(CAN_ACCESS_SALES_ORDER_ENTRY);
  const creditHold = sfcBusinessUnitStores[0]?.OnHold || false;
  const shamrockRewardsEligible = customFields?.sfc_business_unit_rewards_eligible || false;
  const shamrockRewardsEnrolled = customFields?.sfc_business_unit_rewards_enrolled || false;
  const splitKey = sfcBusinessUnitStores[0]?.StoreKey?.split('_');
  // Construct the identification data object
  const identificationData = {
    userId: ctCustomer.email,
    firstName: ctCustomer.firstName,
    accountName: selectedBusinessUnit?.name,
    warehouse: sfcBusinessUnitStores[0]?.StoreKey,
    createdAt: ctCustomer.createdAt,
    userShamrockId: ctCustomer.key,
    creditHold,
    shippingAddress: shipping,
    accountId: selectedBusinessUnit.key,
    salesOrderEntry,
  };
  // Construct the group data object
  const groupCallData = {
    accountName: selectedBusinessUnit?.name,
    creditHold,
    shippingAddress: shipping,
    warehouse: sfcBusinessUnitStores[0]?.StoreKey,
    branchServed: splitKey[INDEX_FOR_WAREHOUSE] || undefined,
    billingAddress: billing,
    shippingZipCode: shipping?.postalCode,
    shamrockRewardsEligible,
    shamrockRewardsEnrolled,
  };

  //Make the AppCues identify call - email will be the user id
  window.Appcues.identify(ctCustomer.email, identificationData);

  //Make the group call - businessunit. key will be the group id
  window.Appcues.group(selectedBusinessUnit.key, groupCallData);
};

//get the shipping and billing address from the business unit
const getShippingAndBillingAddresses = (businessUnit: Partial<AsAssociateBusinessUnitResult>) => {
  if (!businessUnit) {
    return { shipping: null, billing: null };
  }

  const shippingAddressId = businessUnit?.defaultShippingAddressId;
  const billingAddressId = businessUnit?.defaultBillingAddressId;

  const shippingAddress = businessUnit?.addresses?.find((address) => address.id === shippingAddressId);
  const billingAddress = businessUnit?.addresses?.find((address) => address.id === billingAddressId);

  if (shippingAddress && billingAddress) {
    /* eslint-disable */
    const { company, id, title, key, ...shipping } = shippingAddress;
    const { id: billingId, title: billingTitle, key: billingKey, ...billing } = billingAddress;
    /* eslint-enable */

    return { shipping, billing };
  } else {
    return { shipping: null, billing: null };
  }
};
