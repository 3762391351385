import { ExtraProductData } from '@Types/shamrockApi/Product';
import { ProductAttributes } from 'composable/components/types';
import { getAttribute } from 'composable/helpers/utils/attribute-utils';
import { UIProduct } from 'frontastic';
import { productIsActive } from 'helpers/utils/productTypeHandler';

interface ProductTags {
  isJustInTime?: boolean;
  isSurplus?: boolean;
  isSpecialOrder?: boolean;
  isActive?: boolean;
  isPreferredPricing?: boolean;

  isDry?: boolean;
  isFrozen?: boolean;
  isRefrigerated?: boolean;
  isGlutenFree?: boolean;
  isKosher?: boolean;
  isOrganic?: boolean;
  isCwt?: boolean;
  isShamrockBrand?: boolean;
}

interface TagMapProps {
  [key: string]: string;
}

const tagMap: TagMapProps = {
  isActive: 'Discontinued',
  isCwt: 'Catch Weight',
  isDry: 'Dry Storage',
  isFrozen: 'Frozen Storage',
  isGlutenFree: 'Gluten Free',
  isJustInTime: 'Made to Order',
  isKosher: 'Kosher',
  isOrganic: 'Organic',
  isPreferredPricing: 'Preferred Pricing',
  isRefrigerated: 'Refrigerated Storage',
  isShamrockBrand: 'Shamrock Exclusive',
  isShipUponReceipt: 'Ship to Order',
  isSpecialOrder: 'Special Order',
};

/**
 * Parse the Shamrock API extended data and return the simplified flags
 * @param data ExtraProductData
 * @returns ProductTags
 */
export const getTagsFromExtendedProductData = (data: ExtraProductData): ProductTags => {
  const tags: ProductTags = {
    isJustInTime: data?.companyInfo?.data?.isJustInTime ?? false,
    isSurplus: data?.companyInfo?.data?.isShipUponReceipt ?? false,
    isSpecialOrder: data?.companyInfo?.data?.isSpecialOrder ?? false,
    isActive: productIsActive(data),
    isPreferredPricing: data?.pricingInfo?.data?.isPreferredPricing ?? false,
  };

  return tags;
};

const getTagsFromProductData = (data: ProductAttributes): Partial<ProductTags> => {
  const tags: ProductTags = {
    isDry: data?.['shamrock-standard_dry_indicator'] ?? false,
    isFrozen: data?.['shamrock-standard_frozen_indicator'] ?? false,
    isRefrigerated: data?.['shamrock-standard_refrigerated_indicator'] ?? false,
    isGlutenFree: data?.['shamrock-standard_green_indicator'] ?? false,
    isKosher: String(getAttribute(data?.['shamrock-standard_kosher_indicator'], null))?.toLowerCase() === 'y' ?? false,
    isOrganic: data?.['shamrock-standard_organic_indicator'] ?? false,
    isCwt: data?.['shamrock-standard_cwt_indicator'] ?? false,
    isShamrockBrand: data?.['shamrock-standard_shamrock_brand'] ?? false,
  };

  return tags;
};

export const getProductTags = (product: UIProduct): string[] => {
  try {
    const extendedData = product?.extraProductData;
    const tagsFromExtendedData = getTagsFromExtendedProductData(extendedData);

    const attributes = product.variants[0]?.attributes ?? {};
    const tagsFromProduct = getTagsFromProductData(attributes as ProductAttributes);

    const tags = { ...tagsFromExtendedData, ...tagsFromProduct };

    const tagList = Object.keys(tags)
      .filter((tag) => Boolean(tags[tag]))
      .map((key) => {
        if (tags[key]) {
          return tagMap[key];
        }
      })
      .sort();

    return tagList;
  } catch (error) {
    throw new Error(`Error getting product tags: ${error}`);
  }
};
