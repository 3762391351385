import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import { Customer } from '@Types/customer/Customer';
import { ALGOLIA_BASE_INDEX } from 'composable/components/general';
import { getAlgoliaKey } from 'composable/helpers/utils/user-utils';
import { DEFAULT_CURRENCY } from 'helpers/constants/eventTracking';
import aa from 'search-insights';
import { getGoogleAnalyticsUserIdFromBrowserCookie } from './extract-ga-tokens';
import {
  AlgoliaInsightsAddedToCartSearchEventProps,
  AlgoliaInsightsClickAfterSearchEventProps,
  AlgoliaInsightsClickEventProps,
  AlgoliaInsightsClickedFiltersEventProps,
  AlgoliaInsightsEventNames,
  AlgoliaInsightsProps,
  AlgoliaInsightsViewedFiltersEventProps,
  AlgoliaInsightsViewedObjectIDsEventProps,
  AlgoliaPurchasedObjectEventProps,
} from './types';

declare const window: Window & { dataLayer: Record<string, unknown>[] };

export const algoliaInitSearchInsights = async ({ user, activeAccount }: AlgoliaInsightsProps) => {
  if (!activeAccount?.key) {
    return;
  }

  const algoliaKey = getAlgoliaKey(activeAccount, user);

  if (algoliaKey && typeof algoliaKey == 'string') {
    aa('init', {
      appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
      apiKey: algoliaKey,
      useCookie: true,
    });

    const gaToken = getGoogleAnalyticsUserIdFromBrowserCookie('_ga');
    if (gaToken) {
      aa('setUserToken', gaToken);
    }

    const userToken = getAlgoliaUserToken(user, activeAccount);
    // @ts-ignore method does exist
    aa('setAuthenticatedUserToken', userToken);

    window.dataLayer?.push({
      algoliaUserToken: gaToken,
    });

    aa(
      'onUserTokenChange',
      (gaToken) => {
        window.dataLayer?.push({
          algoliaUserToken: gaToken,
        });
      },
      { immediate: true },
    );
  }
};

export function getAlgoliaUserToken(
  user: Partial<Customer>,
  activeAccount: Partial<AsAssociateBusinessUnitResult>,
): string {
  return `${user.id}-${activeAccount?.key}`;
}

export function algoliaInsightClickAfterSearchEvent({
  user,
  activeAccount,
  indexName,
  objectID,
  queryID,
  position,
}: AlgoliaInsightsClickAfterSearchEventProps) {
  // @ts-ignore
  aa('clickedObjectIDsAfterSearch', {
    userToken: getGoogleAnalyticsUserIdFromBrowserCookie('_ga'),
    // @ts-ignore
    authenticatedUserToken: getAlgoliaUserToken(user, activeAccount),
    index: indexName,
    eventName: AlgoliaInsightsEventNames.PRODUCT_CLICKED_AFTER_SEARCH,
    objectIDs: [objectID],
    queryID: queryID,
    positions: [position],
  });
}

export function algoliaInsightClickEvent({
  user,
  activeAccount,
  objectIDs,
  customData,
}: AlgoliaInsightsClickEventProps) {
  // @ts-ignore
  aa('clickedObjectIDs', {
    userToken: getGoogleAnalyticsUserIdFromBrowserCookie('_ga'),
    authenticatedUserToken: getAlgoliaUserToken(user, activeAccount),
    index: ALGOLIA_BASE_INDEX,
    eventName: AlgoliaInsightsEventNames.PRODUCT_CLICKED,
    objectIDs,
    ...customData,
  });
}

export function algoliaInsightAddedToCartAfterSearch({
  user,
  activeAccount,
  indexName,
  queryID,
  objectIDs,
  objectData,
  totalValue,
  customData,
}: AlgoliaInsightsAddedToCartSearchEventProps) {
  // @ts-ignore
  aa('addedToCartObjectIDsAfterSearch', {
    userToken: getGoogleAnalyticsUserIdFromBrowserCookie('_ga'),
    authenticatedUserToken: getAlgoliaUserToken(user, activeAccount),
    eventName: AlgoliaInsightsEventNames.PRODUCT_ADDED_TO_CART_AFTER_SEARCH,
    index: indexName,
    queryID,
    objectIDs,
    objectData,
    value: totalValue,
    currency: DEFAULT_CURRENCY,
    ...customData,
  });
}

export function algoliaInsightAddedToCart({
  user,
  activeAccount,
  objectIDs,
  objectData,
  totalValue,
  customData,
}: AlgoliaInsightsAddedToCartSearchEventProps) {
  // @ts-ignore
  aa('addedToCartObjectIDs', {
    userToken: getGoogleAnalyticsUserIdFromBrowserCookie('_ga'),
    authenticatedUserToken: getAlgoliaUserToken(user, activeAccount),
    eventName: AlgoliaInsightsEventNames.PRODUCT_ADDED_TO_CART,
    index: ALGOLIA_BASE_INDEX,
    objectIDs,
    objectData,
    value: totalValue,
    currency: DEFAULT_CURRENCY,
    ...customData,
  });
}

export function algoliaInsightPurchasedObjectIDsAfterSearch({
  user,
  activeAccount,
  indexName,
  queryID,
  objectIDs,
  objectData,
  totalValue,
  customData,
}: AlgoliaPurchasedObjectEventProps) {
  // @ts-ignore
  aa('purchasedObjectIDsAfterSearch', {
    userToken: getGoogleAnalyticsUserIdFromBrowserCookie('_ga'),
    authenticatedUserToken: getAlgoliaUserToken(user, activeAccount),
    eventName: AlgoliaInsightsEventNames.PRODUCT_PURCHASED_AFTER_SEARCH,
    index: indexName,
    queryID,
    objectIDs,
    objectData,
    value: totalValue,
    currency: DEFAULT_CURRENCY,
    ...customData,
  });
}

export function algoliaInsightPurchasedObjectIDs({
  user,
  activeAccount,
  objectIDs,
  objectData,
  totalValue,
  customData,
}: AlgoliaPurchasedObjectEventProps) {
  // @ts-ignore
  aa('purchasedObjectIDs', {
    userToken: getGoogleAnalyticsUserIdFromBrowserCookie('_ga'),
    authenticatedUserToken: getAlgoliaUserToken(user, activeAccount),
    eventName: AlgoliaInsightsEventNames.PRODUCT_PURCHASED,
    index: ALGOLIA_BASE_INDEX,
    objectIDs,
    objectData,
    value: totalValue,
    currency: DEFAULT_CURRENCY,
    ...customData,
  });
}

export function algoliaInsightViewedObjectIDs({
  user,
  activeAccount,
  objectIDs,
  customData,
}: AlgoliaInsightsViewedObjectIDsEventProps) {
  // @ts-ignore
  aa('viewedObjectIDs', {
    userToken: getGoogleAnalyticsUserIdFromBrowserCookie('_ga'),
    // @ts-ignore
    authenticatedUserToken: getAlgoliaUserToken(user, activeAccount),
    eventName: AlgoliaInsightsEventNames.PRODUCT_VIEWED,
    index: ALGOLIA_BASE_INDEX,
    objectIDs,
    ...customData,
  });
}

export function algoliaInsightViewedFilters({
  user,
  activeAccount,
  customData,
  indexName,
  filters,
}: AlgoliaInsightsViewedFiltersEventProps) {
  // @ts-ignore
  aa('viewedFilters', {
    userToken: getGoogleAnalyticsUserIdFromBrowserCookie('_ga'),
    authenticatedUserToken: getAlgoliaUserToken(user, activeAccount),
    eventName: AlgoliaInsightsEventNames.VIEWED_FILTERS,
    index: indexName,
    filters,
    ...customData,
  });
}

export function algoliaInsightClickedFilters({
  user,
  activeAccount,
  indexName,
  filters,
  customData,
}: AlgoliaInsightsClickedFiltersEventProps) {
  // @ts-ignore
  aa('clickedFilters', {
    userToken: getGoogleAnalyticsUserIdFromBrowserCookie('_ga'),
    authenticatedUserToken: getAlgoliaUserToken(user, activeAccount),
    eventName: AlgoliaInsightsEventNames.CLICKED_FILTERS,
    index: indexName,
    filters,
    ...customData,
  });
}
