import { FunctionComponent } from 'react';
import { HStack, Icon, Text, useToast } from '@chakra-ui/react';
import ExclamationIcon from '@heroicons/react/solid/ExclamationIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import { useOfflineDetector } from 'helpers/hooks/useOfflineDetector';
import { TOAST_ICON } from 'composable/components/general';

export const OfflineDetector: FunctionComponent = () => {
  const toast = useToast();

  const { formatMessage } = useFormat({ name: 'common' });

  const onReconnect = () =>
    toast({
      duration: 5000,
      status: 'success',
      title: formatMessage({ id: 'offlineDetector.online' }),
      icon: TOAST_ICON.success,
    });

  const detector = useOfflineDetector({
    onReconnect,
  });

  return detector.isOffline ? (
    <HStack
      alignItems={{ base: 'flex-start', lg: 'center' }}
      bg="yellow.400"
      justifyContent="center"
      px={5}
      py={2}
      spacing={2}
    >
      <Icon as={ExclamationIcon} boxSize={6} color="neutral.900" />
      <Text fontSize={{ base: 'xs', lg: 'sm' }} color="neutral.900">
        {formatMessage({ id: 'offlineDetector.offline' })}
      </Text>
    </HStack>
  ) : (
    <></>
  );
};
