/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { SWR_OPTIONS } from 'composable/components/order-guide/helpers';
import { orderGuidesByCustomerNumbers } from 'composable/components/order-guide-edit/components/utils/fetchers';
import {
  SORTS_KEYS_OGM,
  useLocalStorageOrderGuideManagement,
} from 'composable/components/order-guide-management/components/helpers';
import { KEY_ALL_ACCOUNTS } from 'composable/helpers/hooks';
import { getAccountOptions } from 'composable/helpers/utils/account-utils';
import { SHAMROCK_API_URL } from 'helpers/constants/environment';
import { useFormat } from 'helpers/hooks';
import every from 'lodash/every';
import values from 'lodash/values';
import useSWR from 'swr';
import { orderGuideListInitialState, reduceOrderGuideList } from './reduce-order-guide-list';
import { OrderGuideListGlobalStateActions, UseOrderGuideListGlobalResponse } from './types';
import { UseUserGlobalResponse } from '../use_privateUserGlobal/types';

const URL_ORDER_GUIDES_BY_CUSTOMER_NUMBERS = `${SHAMROCK_API_URL}/order-guides/by-customer-numbers`;

export const use_privateOrderGuideListGlobal = (
  useUserGlobal: UseUserGlobalResponse,
  avoidFetch: boolean, // we should not fetch OG data on public pages or printback page
): UseOrderGuideListGlobalResponse => {
  const [state, dispatch] = useReducer(reduceOrderGuideList, orderGuideListInitialState);

  const { formatMessage } = useFormat({ name: 'common' });

  const {
    state: { loading: isLoadingUserData, activeWarehouse, accessToken, activeAccount, accountList },
    logout,
  } = useUserGlobal;

  const accountOptions = getAccountOptions(accountList, formatMessage);

  const {
    ogmLocalStorageValues: { selectedSortOptionOrderGuideManagement },
  } = useLocalStorageOrderGuideManagement();

  const payload = useMemo(() => {
    if (
      avoidFetch ||
      isLoadingUserData ||
      !activeWarehouse ||
      !accessToken ||
      !activeAccount ||
      Object.keys(accountOptions).length === 0
    ) {
      return {};
    }
    return {
      warehouseNumber: activeWarehouse.warehouseNumber,
      businessUnitName: activeWarehouse.businessUnit,
      businessSegmentName: activeWarehouse.businessSegment,
      includeDrafts: true,
      customerNumbers: Object.keys(accountOptions).filter((key) => key !== KEY_ALL_ACCOUNTS),
    };
  }, [accessToken, accountOptions, activeAccount, activeWarehouse, isLoadingUserData]);

  const isValidPayload =
    Object.keys(payload).length > 0 &&
    every(values(payload), (val) => val !== '' && !(Array.isArray(val) && Array.from(val).length === 0));

  const {
    data: allOrderGuides,
    isLoading,
    isValidating,
    mutate,
  } = useSWR(
    () => {
      if (!isValidPayload) {
        return null;
      }
      return [
        URL_ORDER_GUIDES_BY_CUSTOMER_NUMBERS,
        {
          payload,
        },
      ];
    },
    ([url, payload]) => orderGuidesByCustomerNumbers([url, { ...payload, accessToken, logout }]),
    {
      ...SWR_OPTIONS,
      errorRetryCount: 2,
      onError: async (error) => {
        if (error.response?.status === 403) {
          await logout(true, 'unauthorized');
        } else {
          console.error(error);
        }
      },
    },
  );

  const allOrderGuidesInfo = useCallback(async () => {
    if (avoidFetch || allOrderGuides === undefined || allOrderGuides.length == 0) {
      dispatch({ type: OrderGuideListGlobalStateActions.SET_OG_LIST, payload: { ogList: [], loading: false } });
    }

    let ogList = allOrderGuides;

    const selectedSortOption = selectedSortOptionOrderGuideManagement.selectedSort;

    if ([SORTS_KEYS_OGM.asc, SORTS_KEYS_OGM.desc].includes(selectedSortOption) && allOrderGuides) {
      ogList = allOrderGuides.sort((a, b) => {
        if (selectedSortOption === SORTS_KEYS_OGM.asc) {
          return a.name.localeCompare(b.name);
        } else {
          return b.name.localeCompare(a.name);
        }
      });
    }

    if (
      [SORTS_KEYS_OGM.highestItemCount, SORTS_KEYS_OGM.lowestItemCount].includes(selectedSortOption) &&
      allOrderGuides
    ) {
      ogList = allOrderGuides.sort((a, b) => {
        if (selectedSortOption === SORTS_KEYS_OGM.highestItemCount) {
          return b?.productCount - a?.productCount;
        } else {
          return a?.productCount - b?.productCount;
        }
      });
    }

    dispatch({
      type: OrderGuideListGlobalStateActions.SET_OG_LIST,
      payload: { ogList: ogList ?? [], loading: false },
    });
  }, [allOrderGuides, selectedSortOptionOrderGuideManagement?.selectedSort]);

  useEffect(() => {
    if (isValidPayload) {
      const isLoadingOg = isLoading || isValidating;
      dispatch({ type: OrderGuideListGlobalStateActions.SET_LOADING, payload: { loading: isLoadingOg } });
    }
  }, [isLoading, isValidPayload, isValidating]);

  useEffect(() => {
    if (isValidPayload) {
      allOrderGuidesInfo();
    }
  }, [allOrderGuidesInfo, isValidPayload]);

  return { state, dispatch, listOrderGuides: () => mutate() };
};
