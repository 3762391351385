import { useEffect, useRef, useState } from 'react';
import { Product } from '@Types/product/Product';
import { query } from 'frontastic/actions/product';
import { useToast } from '@chakra-ui/react';
import { useFormat } from 'helpers/hooks';

let apiResponseTime: number | null = null;

/**
 * Get CT products information by SKU
 * @param skus Array of skus
 * @returns products and isLoading
 */
export const useGetCtProducts = (skus: string[]) => {
  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState<Product[]>([]);
  const skuString = skus?.join();

  useEffect(() => {
    const fetchData = async () => {
      if (!!skuString) {
        const startTime = performance.now();
        const data = await query({ skus, limit: skus.length }, toast, formatMessage({ id: 'app.generic.error' }));
        const endTime = performance.now();
        apiResponseTime = endTime - startTime;

        setIsLoading(false);
        setProducts(data);
      }
    };
    fetchData();
  }, [skuString]);

  return { products, isLoading };
};

export const getCtProductsResponseTime = (): number | null => {
  return apiResponseTime;
};