const QuantityPicker = {
  baseStyle: {
    _container: {
      bg: 'neutral.50',
      borderColor: 'neutral.200',
      borderRadius: '6px',
      borderWidth: 1,
      p: 2,
      h: 10,
    },
    _numberInputField: {
      color: 'neutral.900',
      fontSize: 'base',
    },
    _quantityPickerButton: {
      h: 6,
      minW: 6,
      _icon: {
        boxSize: 3,
      },
      borderRadius: '4px',
    },
  },
  sizes: {
    xl: {
      _container: {
        h: 12,
      },
      _numberInputField: {
        fontSize: 'lg',
      },
      _quantityPickerButton: {
        h: 8,
        minW: 8,
        _icon: {
          boxSize: 4,
        },
      },
    },
  },
  variants: {
    outline: {
      _container: {
        bg: 'transparent',
      },
    },
  },
};

export default QuantityPicker;
