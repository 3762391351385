import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import { KEY_ALL_ACCOUNTS } from '../hooks';

export const isEligibleForALaCart = (eligibility: string | string[]) => {
  const matches = ['alacarte', 'flexmetro', 'flxmetro'];

  if (typeof eligibility === 'string') {
    return matches.includes(eligibility.toLowerCase());
  }

  return eligibility.some((e) => matches.includes(e.toLowerCase()));
};

export const isEligibleForPickupDelivery = (eligibility: string | string[]) => {
  const matches = ['willcall', 'routedwillcall'];

  if (typeof eligibility === 'string') {
    return matches.includes(eligibility.toLowerCase());
  }

  return eligibility.some((e) => matches.includes(e.toLowerCase()));
};

export const getAccountOptions = (accountList: AsAssociateBusinessUnitResult[], formatMessage) => {
  if (accountList.length === 1) {
    return { [accountList[0].key]: accountList[0].name };
  }
  return (accountList || []).reduce(
    (acc: Record<string, string>, businessUnit: AsAssociateBusinessUnitResult) => {
      acc[businessUnit.key] = businessUnit.name;
      return acc;
    },
    { [KEY_ALL_ACCOUNTS]: formatMessage({ id: 'order.guide.management.allAccounts' }) },
  );
};

export const getAccountsInformation = (accountList: AsAssociateBusinessUnitResult[]) => {
  if (accountList.length === 0) {
    return [];
  }
  return accountList
    .map((businessUnit: AsAssociateBusinessUnitResult) => {
      const billingAddressId = businessUnit.defaultBillingAddressId || '';
      const billingAddress = businessUnit.addresses?.find((address) => address.id === billingAddressId);
      return {
        accountName: businessUnit.name,
        accountNumber: businessUnit.key,
        address: billingAddress
          ? `${billingAddress.streetName}, ${billingAddress.city}, ${billingAddress.state} ${billingAddress.postalCode}`
          : '',
      };
    })
    .sort((a, b) => a.accountName.localeCompare(b.accountName));
};
