import { useMemo } from 'react';
import { useToast } from '@chakra-ui/react';
import { ReturnRow } from '@Types/shamrockApi/Order';
import { useGlobal } from 'components/globalProvider';
import { FEATURE_FLAG_MULTI_ACCOUNT, GENERIC_TOAST_ERROR_ID, TOAST_ICON } from 'composable/components/general';
import { SWR_OPTIONS } from 'composable/components/order-guide/helpers';
import { getPayloadByWarehouses } from 'helpers/components/accountsOverviewHelper';
import { useFormat } from 'helpers/hooks';
import useSWR from 'swr';
import { allAccountOrderReturns } from 'frontastic/tastics/composable/orders/utils/fetchers';

type useGetOrderReturnsProps = {
  period: number;
  isAllReturns: boolean;
  customerNumbers: string[] | null;
};

export const useGetOrderReturns = ({ period = 7, isAllReturns = false, customerNumbers }: useGetOrderReturnsProps) => {
  const {
    state: { accountList, accessToken },
    logout,
  } = useGlobal().useUserGlobal;
  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });

  const payload = useMemo(() => {
    if (!customerNumbers) {
      return getPayloadByWarehouses(accountList);
    }

    const selectedBus = accountList.filter((bu) => customerNumbers.includes(bu.key));
    return getPayloadByWarehouses(selectedBus);
  }, [customerNumbers, accountList]);

  //TODO: This fetcher should be moved to a its own file
  const fetcher = async () => {
    if (isAllReturns && FEATURE_FLAG_MULTI_ACCOUNT) {
      try {
        const data = await allAccountOrderReturns([
          payload,
          period,
          { accessToken, logout },
          toast,
          formatMessage({ id: 'app.generic.error' }),
        ]);
        return (data ?? []) as ReturnRow[];
      } catch (error) {
        console.error('Error API call: allAccountOrderReturns', error);
        if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
          toast({
            duration: 5000,
            status: 'error',
            title: formatMessage({ id: 'app.generic.error' }),
            id: GENERIC_TOAST_ERROR_ID,
            icon: TOAST_ICON.error,
          });
        }
      }
    }
  };

  const {
    data: orderReturns,
    error,
    isValidating,
    isLoading,
    mutate,
  } = useSWR(
    FEATURE_FLAG_MULTI_ACCOUNT && isAllReturns && payload ? ['useGetOrderReturns', period, payload] : null,
    fetcher,
    { ...SWR_OPTIONS, errorRetryCount: 2 },
  );

  const isLoadingOrderReturns = isValidating || isLoading;

  return { orderReturns: orderReturns ?? [], isLoadingOrderReturns, error, orderReturnsMutation: mutate };
};
