import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import { cssVar } from '@chakra-ui/theme-tools';

const $arrowBg = cssVar('popper-arrow-bg');

const baseStyle = defineStyle((props) => ({
  bg: 'text',
  color: 'background',
  [$arrowBg.variable]: cssVar('chakra-colors-text').reference,
}));

export default defineStyleConfig({
  baseStyle,
  variants: {
    dark: {
      bg: cssVar('chakra-colors-text').reference,
      color: 'background',
      [$arrowBg.variable]: cssVar('chakra-colors-text').reference,
    },
    light: {
      bg: 'background',
      color: 'text',
      [$arrowBg.variable]: cssVar('chakra-colors-background').reference,
    },
    highlight: {
      bg: 'highlight',
      color: 'text',
      [$arrowBg.variable]: cssVar('chakra-colors-highlight').reference,
    },
  },
});
