export enum ShamrockApi {
  InitializePriceCache = 'initializePriceCache',
  GetCustomerOrders = 'getCustomerOrders',
  GetCustomerOrderDetail = 'getCustomerOrderDetail',
  UpdateCustomerOrderDetail = 'updateCustomerOrderDetail',
  GetSpecificOrderGuide = 'getSpecificOrderGuide',
  GetAllOrderGuide = 'getAllOrderGuide',
  GetShamrockUser = 'getShamrockUser',
  GetExtraProductData = 'getExtraProductData',
  DeleteOrderGuide = 'deleteOrderGuide',
  CancelOrder = 'cancelOrder',
  GetDeliveryOptionsEligibilities = 'getDeliveryOptionsEligibilities',
  GetDeliveryOptionsSoftCutoffs = 'getDeliveryOptionsSoftCutoffs',
  GetProductStockNotifications = 'getProductStockNotifications',
  CreateProductStockNotifications = 'createProductStockNotifications',
  DeleteProductStockNotifications = 'deleteProductStockNotifications',
  PostProductInterest = 'postProductInterest',
  SendProductFeedback = 'sendProductFeedback',
}
