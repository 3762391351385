import { fetchApiHub, ResponseError } from 'frontastic/lib/fetch-api-hub';
import { GetTokensResponse } from './types';

/**
 * Use the received "code" to fetch the access and refresh token from AzureB2C
 * @param code string: code received from AzureB2C
 * @returns GetTokensResponse
 */
export const azureGetTokens = async (code: string): Promise<GetTokensResponse> => {
  try {
    const response = await fetchApiHub(
      '/action/azure/getTokens',
      { method: 'POST' },
      {
        code,
      },
    );

    const res: GetTokensResponse = response;
    res.success = true;

    return res;
  } catch (error) {
    if (error instanceof ResponseError) {
      console.error('Get Token error', error.getResponse());
    }

    return { success: false, access_token: '', refresh_token: '', expires_on: 0 };
  }
};

/**
 * Keep the user logged by fetching new access and refresh tokens from AzureB2C
 * @param refresh_token string: refresh token from AzureB2C fetched during the login or previous refresh
 * @returns GetOkensResponse
 */
export const azureRefreshTokens = async (refresh_token: string): Promise<GetTokensResponse> => {
  try {
    const response = await fetchApiHub(
      '/action/azure/refreshTokens',
      { method: 'POST' },
      {
        refresh_token,
      },
    );

    const res: GetTokensResponse = response;
    res.success = true;

    return res;
  } catch (error) {
    if (error instanceof ResponseError) {
      console.error('Refresh Token error', error.getResponse());
    }

    return { success: false, access_token: '', refresh_token: '', expires_on: 0 };
  }
};
