import axios from 'axios';
import { ACCESS_TOKEN, HAS_SHAMROCK_USER_LOGIN_BEEN_TRACKED } from 'composable/helpers/constants';
import { trackLogin } from 'frontastic/actions/account';
import { detectPlatform } from './detectBrowser';

const pingInternalShamrockAPI = async () => {
  try {
    await axios.get('https://svc.shamrockfoods.com/cxs/images/accent.png');
    return true;
  } catch (error) {
    return false;
  }
};

export const trackShamrockUserLogin = async ({ accessToken, clientIp }: { accessToken: string; clientIp: string }) => {
  try {
    const hasShamrockUserLoginBeenTracked = window.localStorage.getItem(HAS_SHAMROCK_USER_LOGIN_BEEN_TRACKED);

    const shouldTrackShamrockUserLogin = !(hasShamrockUserLoginBeenTracked === 'true');

    if (shouldTrackShamrockUserLogin) {
      const isOnCompanyNetwork = await pingInternalShamrockAPI();

      const { os, browser, version } = detectPlatform();

      const payload = {
        ipAddress: clientIp,
        platform: os,
        appVersion: process.env.NEXT_PUBLIC_APPLICATION_VERSION ?? '',
        browser,
        browserVersion: version,
        isOnCompanyNetwork,
      };

      const response = await trackLogin({ accessToken, ...payload });

      if (response?.data?.success === true) {
        window.localStorage.setItem(HAS_SHAMROCK_USER_LOGIN_BEEN_TRACKED, 'true');
      }
    }
  } catch (error) {
    console.error('Error while trying to track user login', error);
  }
};
