import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const base = definePartsStyle({
  label: defineStyle({
    opacity: '1 !important',
  }),
  control: defineStyle({
    _checked: {
      bg: 'primary.200',
      color: 'neutral.900',
      borderColor: 'primary.500',
      _icon: {
        as: 'svg',
        height: 4,
        width: 4,
      },
      _hover: {
        boxShadow: '0 0 0 2px #9BCD65',
        bg: 'primary.200',
        color: 'neutral.900',
        borderColor: 'primary.500',
      },
      _focus: {
        boxShadow: '0 0 0 2px #9BCD65 !important',
        bg: 'primary.200',
        color: 'neutral.900',
        borderColor: 'primary.500',
      },
    },
    bg: 'neutral.50',
    borderColor: 'neutral.300',
    _hover: {
      boxShadow: '0 0 0 2px #9BCD65 !important',
    },
    _disabled: {
      bg: 'neutral.300',
      borderColor: 'neutral.400',
      _hover: {
        bg: 'neutral.300',
        borderColor: 'neutral.400',
        boxShadow: 'none !important',
      },
    },
  }),
});

const orderGuideVariant = definePartsStyle({
  label: defineStyle({
    opacity: '1 !important',
  }),
  control: defineStyle({
    bg: '#474852',
    borderColor: '#777788',
    borderWidth: '1.5px',
    _checked: {
      bg: '#12875E',
      color: '#fff',
      borderColor: '#40B781',
      _hover: {
        bg: '#12875E',
        color: '#fff',
        borderColor: '#40B781',
      },
      _focus: {
        bg: '#12875E',
        color: '#fff',
        borderColor: '#40B781',
      },
    },
    _disabled: {
      bg: '#2F3037',
      borderColor: '#474852',
      _hover: {
        bg: '#2F3037',
        borderColor: '#474852',
        boxShadow: 'none !important',
      },
    },
    _focus: {
      outlineColor: '#8068D0',
      outlineWidth: '2px',
      outlineStyle: 'solid',
      outlineOffset: '2px',
    },
    _focusVisible: {
      outlineColor: '#8068D0',
      outlineWidth: '2px',
      outlineStyle: 'solid',
      outlineOffset: '2px',
      boxShadow: 'none',
    },
  }),
});

const checkboxTheme = defineMultiStyleConfig({
  variants: { base, 'order-guide': orderGuideVariant },
});

export default checkboxTheme;
