import { AsAssociateBusinessUnitResult } from '@Types/business-unit/BusinessUnit';
import { OrderStatus, scheduledDeliveryWindow, OrderDetailLineItem, ReturnRow } from '@Types/shamrockApi/Order';
import { ExtraProductData } from '@Types/shamrockApi/Product';
import colors from 'composable/chakra/theme/foundations/colors';
import {
  KEY_A_LA_CARTE,
  KEY_PICK_UP,
  KEY_SCHEDULED_DELIVERY,
  ORDER_TYPE_FOR_A_LA_CARTE,
  ORDER_TYPE_FOR_PICK_UP,
  ORDER_TYPE_FOR_SCHEDULED_DELIVERY,
} from 'composable/components/checkout/utils/constants';
import { getTagsFromExtendedProductData } from 'composable/components/pdp/components/parts/tags';
import {
  isCancelled,
  isCompleted,
  isDeliveredComplete,
  isError,
  isOutForDelivery,
  isProcessing,
  isReadyForPickup,
  isSubmitted,
} from 'composable/helpers/utils/order-status';
import { filterDeliveryScheduleByDate, getDeliveryPlanForActiveWarehouse } from 'functions/deliverySchedule';
import { DateTime, IANAZone } from 'luxon';
import moment from 'moment-timezone';
import { isPastCutoffTime } from 'frontastic/tastics/composable/edit-order/utils';
import { InventoryStatus, getInventoryInfo } from './inventory-utils';
import { isProductOrderable } from './is-product-orderable';
import { EditOrder } from './use-user-utils/types';
import { getActiveAccountDeliveryDates, getDateWithCutoff } from '../hooks';

export const getStatusBadgeColors = (
  orderStatus: OrderStatus,
  orderNumber?: string,
): { border: string; background: string; text: string } => {
  if (!orderStatus || orderStatus === '' || isCancelled(orderStatus)) {
    return { border: colors.red[500], background: colors.red[100], text: colors.neutral[900] };
  }

  if (isDeliveredComplete(orderStatus) || isCompleted(orderStatus)) {
    return { border: colors.primary[600], background: colors.primary[600], text: colors.white };
  }

  if (isSubmitted(orderStatus)) {
    return { border: colors.yellow[400], background: colors.yellow[100], text: colors.neutral[900] };
  }

  if (isProcessing(orderStatus)) {
    return { border: colors.orange[500], background: colors.orange[200], text: colors.neutral[900] };
  }

  if (isOutForDelivery(orderStatus)) {
    return { border: colors.primary[400], background: '#D6FADF', text: colors.neutral[900] };
  }

  if (isReadyForPickup(orderStatus)) {
    return { border: colors.primary[400], background: '#D6FADF', text: colors.neutral[900] };
  }

  if (orderNumber && isError(orderStatus, orderNumber)) {
    return { border: colors.red[500], background: colors.red[100], text: colors.black };
  }

  // just to be safe in case order status is not recognized
  return { border: colors.red[500], background: colors.red[100], text: colors.neutral[900] };
};

export const getStatusBadgeCopyKey = ({
  orderStatus,
  isReturns,
  orderNumber,
}: {
  orderStatus: OrderStatus;
  orderNumber?: string;
  isReturns?: boolean;
}): string | null => {
  if (!orderStatus || orderStatus === '') {
    return null;
  }

  if (isReturns) {
    if (isCompleted(orderStatus)) {
      return 'orders.allReturns.status.requestCompleted';
    }

    if (isSubmitted(orderStatus)) {
      return 'orders.allReturns.status.requestSubmitted';
    }

    if (isCancelled(orderStatus)) {
      return 'orders.allReturns.status.requestCancelled';
    }

    return null;
  }

  if (isDeliveredComplete(orderStatus)) {
    return 'orders.filters.sort.deliveredComplete';
  }

  if (isCancelled(orderStatus)) {
    return 'orders.filters.sort.cancelled';
  }

  if (isSubmitted(orderStatus)) {
    return 'orders.filters.sort.submitted';
  }

  if (isProcessing(orderStatus)) {
    return 'orders.filters.sort.processing';
  }

  if (isOutForDelivery(orderStatus)) {
    return 'orders.filters.sort.outForDelivery';
  }

  if (isReadyForPickup(orderStatus)) {
    return 'orders.filters.sort.readyForPickup';
  }

  if (orderNumber && isError(orderStatus, orderNumber)) {
    return 'orders.filters.sort.error';
  }

  return null;
};

export const getDeliveryStatusCopyKey = (
  orderStatus: OrderStatus,
  scheduledDeliveryWindow?: scheduledDeliveryWindow,
): string => {
  if (!orderStatus) {
    return '-';
  }

  if (isCancelled(orderStatus)) {
    return 'orders.filters.sort.cancelled';
  }

  if (isDeliveredComplete(orderStatus)) {
    return 'account.orders.status.delivered';
  }

  if (isSubmitted(orderStatus)) {
    return 'orders.delivery.pending';
  }

  if (isProcessing(orderStatus)) {
    return 'orders.delivery.scheduled';
  }

  if (isOutForDelivery(orderStatus) || isReadyForPickup(orderStatus)) {
    if (!scheduledDeliveryWindow) {
      return '';
    }
    if (scheduledDeliveryWindow?.isOvernight) {
      return 'orders.delivery.estimated.tomorrow';
    }
    return 'orders.delivery.estimated.today';
  }
};

export const getDeliveryTimeOutput = (
  orderStatus: OrderStatus,
  deliveryDate: string,
  scheduledDeliveryWindow?: scheduledDeliveryWindow,
): string => {
  if (!orderStatus || orderStatus === '') {
    return '';
  }

  const timezone = DateTime.local().zoneName;
  let deliveryDateFixed = deliveryDate;

  if (deliveryDate.indexOf('/') > -1) {
    deliveryDateFixed = moment(deliveryDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
  }

  if (isDeliveredComplete(orderStatus) || isSubmitted(orderStatus) || isProcessing(orderStatus)) {
    const delDateNewDate = moment(deliveryDateFixed).utc(true).tz(timezone);

    const timezoneOffsetHours = Math.abs(delDateNewDate.utcOffset() / 60); // Convert minutes to hours for timezone offset
    delDateNewDate.add(timezoneOffsetHours, 'hours');

    return delDateNewDate.format('L');
  }

  if (isOutForDelivery(orderStatus) || isReadyForPickup(orderStatus)) {
    if (!scheduledDeliveryWindow) {
      return '';
    }

    const startTime = moment(`${deliveryDateFixed}T${scheduledDeliveryWindow.deliveryWindowStartTime}`)
      .utc(true)
      .tz(timezone);
    const endTime = moment(`${deliveryDateFixed}T${scheduledDeliveryWindow.deliveryWindowEndTime}`)
      .utc(true)
      .tz(timezone);

    const timezoneAbbreviation = IANAZone.create(timezone).offsetName(DateTime.local().valueOf(), { format: 'short' });
    return `${startTime.format('LT')} - ${endTime.format('LT')} ${timezoneAbbreviation}`;
  }

  return !!deliveryDateFixed ? moment(deliveryDateFixed).utc(true).tz(timezone).format('L') : '';
};

export const getOrderTypeCopyKey = (status: OrderStatus): string => {
  if (isReadyForPickup(status)) {
    return 'orderDetails.type.warehousePickUp';
  }
  return 'orderDetails.type.standardDelivery';
};

export const isStandardDeliveryType = (deliveryType?: string): boolean => {
  if (!deliveryType) {
    return false;
  }
  return [
    'standard',
    'pulloff',
    'relay',
    'relay *',
    'relay_pl',
    'relay_pk',
    'recovery',
    'compvan',
    'autoship',
    'merge',
    'aop',
    'sameday',
    'samples',
    'courier/xcourrier',
    'courier/xcourier',
    'courier',
    'xcourier',
  ].includes(deliveryType.toLowerCase());
};

export const isALaCartDeliveryType = (deliveryType?: string): boolean => {
  if (!deliveryType) {
    return false;
  }
  return ['flxmetro'].includes(deliveryType.toLowerCase());
};

export const isPickupDeliveryType = (deliveryType?: string): boolean => {
  if (!deliveryType) {
    return false;
  }
  return ['rtd_wc', 'willcall'].includes(deliveryType.toLowerCase());
};

export const isCustomerDirectDeliveryType = (deliveryType?: string): boolean => {
  if (!deliveryType) {
    return false;
  }
  return ['dropship'].includes(deliveryType.toLowerCase());
};

export const isInvoiceReissueDeliveryType = (deliveryType?: string): boolean => {
  if (!deliveryType) {
    return false;
  }
  return ['rebill'].includes(deliveryType.toLowerCase());
};

export const getDeliveryTypeCopyKey = (deliveryType?: string): string => {
  if (!deliveryType) {
    return '';
  }
  if (deliveryType.toLowerCase().includes('relay')) {
    return 'orders.delivery.type.standard';
  }

  if (isStandardDeliveryType(deliveryType)) {
    return 'orders.delivery.type.standard';
  }

  if (isALaCartDeliveryType(deliveryType)) {
    return 'orders.delivery.type.aLaCarte';
  }

  if (isPickupDeliveryType(deliveryType)) {
    return 'orders.delivery.type.pickup';
  }

  if (isCustomerDirectDeliveryType(deliveryType)) {
    return 'orders.delivery.type.customerDirect';
  }

  if (isInvoiceReissueDeliveryType(deliveryType)) {
    return 'orders.delivery.type.invoiceReissue';
  }

  return '';
};

/**
 * Gives a value for each status. Lowest values are displayed first
 * @param status Return status
 * @returns number value to use for comparison.
 */
const returnStatusSortingValues = (status: string): number => {
  if (isSubmitted(status)) {
    return 3;
  }

  if (isCancelled(status)) {
    return 2;
  }

  if (isCompleted(status)) {
    return 1;
  }

  // No Status itens should be displayed before canceled
  return 4;
};

const sortReturnsByDateCreated = (a: string, b: string) => (moment(a).valueOf() > moment(b).valueOf() ? -1 : 1);

const sortReturnsByStatus = (a: string, b: string) =>
  returnStatusSortingValues(a) > returnStatusSortingValues(b) ? -1 : 1;

/**
 * Sort returns based on Status and time.
 * @param returns returns array
 * @returns returns array sorted
 */
export const sortReturns = (returns: ReturnRow[], sortBy: string): ReturnRow[] => {
  return returns.sort((a, b) => {
    if (sortBy === 'dateCreated') {
      if (a.createdDateTime.substring(0, 10) !== b.createdDateTime.substring(0, 10)) {
        return sortReturnsByDateCreated(a.createdDateTime, b.createdDateTime);
      } else if (a.status !== b.status) {
        return sortReturnsByStatus(a.status, b.status);
      }
    } else if (sortBy === 'orderNumber') {
      return parseInt(a.orderNumber) < parseInt(b.orderNumber) ? 1 : -1;
    } else if (sortBy === 'creditAmountHighToLow') {
      return a.creditAmount < b.creditAmount ? 1 : -1;
    } else if (sortBy === 'requestAmountHighToLow') {
      return a.requestedAmount < b.requestedAmount ? 1 : -1;
    } else if (sortBy === 'status') {
      if (a.status !== b.status) {
        return sortReturnsByStatus(a.status, b.status);
      } else if (a.createdDateTime.substring(0, 10) !== b.createdDateTime.substring(0, 10)) {
        return sortReturnsByDateCreated(a.createdDateTime, b.createdDateTime);
      }
    }

    return parseInt(a.returnNumber) < parseInt(b.returnNumber) ? 1 : -1;
  });
};

/**
 * Checks if a line item is NOT refundable.
 * @param lineItem - The line item to check.
 * @returns Returns true if current date is greater than JIT edit cutoff.
 */
export const isNonRefundable = (lineItem: OrderDetailLineItem) => {
  if (!lineItem?.justInTimeEditCutoff) {
    return false;
  }

  const currentDate = moment();
  const jitDate = moment(lineItem?.justInTimeEditCutoff);

  if (currentDate >= jitDate) {
    return true;
  }

  return false;
};

export const getOrderTypeByShippingMethod = (shippingMethod: string): string => {
  switch (shippingMethod) {
    case KEY_PICK_UP:
      return ORDER_TYPE_FOR_PICK_UP;
    case KEY_A_LA_CARTE:
      return ORDER_TYPE_FOR_A_LA_CARTE;
    case KEY_SCHEDULED_DELIVERY:
    default:
      return ORDER_TYPE_FOR_SCHEDULED_DELIVERY;
  }
};

export const getShippingMethodByOrderType = (orderType: string): string => {
  switch (orderType) {
    case ORDER_TYPE_FOR_PICK_UP:
      return KEY_PICK_UP;
    case ORDER_TYPE_FOR_A_LA_CARTE:
      return KEY_A_LA_CARTE;
    case ORDER_TYPE_FOR_SCHEDULED_DELIVERY:
    default:
      return KEY_SCHEDULED_DELIVERY;
  }
};

export type ShouldDisableProps = {
  isDisabled: boolean;
  warningMessage: string;
};

export const getLineItemsSkus = (lineItems: OrderDetailLineItem[]) =>
  (lineItems ?? []).map((item) => item.productNumber);

type IsLineItemDisabled = (data: {
  ctBusinessUnit: Partial<AsAssociateBusinessUnitResult>;
  ctProduct: OrderDetailLineItem;
  editOrder?: EditOrder;
  extraProductData: ExtraProductData;
}) => {
  isDisabled: boolean;
  warningMessage?: string;
};

export const isLineItemDisabled: IsLineItemDisabled = ({ ctBusinessUnit, ctProduct, editOrder, extraProductData }) => {
  let isDisabled = false;
  let warningMessage: string;

  const companyInfo = extraProductData?.companyInfo?.data;

  // ------------------------------------------------
  // frontend/composable/helpers/utils/order-utils.ts:orderEditShouldDisableLineItem()
  if (editOrder?.isEditOrder) {
    const currentDate = new Date();
    // Get cutoff for deliveryDate
    const deliveryDateStr = editOrder?.order?.estimatedDeliveryDate;
    const deliveryDate = moment(deliveryDateStr).toDate();
    const deliveryPlan = getDeliveryPlanForActiveWarehouse(ctBusinessUnit as AsAssociateBusinessUnitResult);
    const schedule = filterDeliveryScheduleByDate(deliveryPlan.DeliverySchedules, deliveryDate);
    const cutoffDate = getDateWithCutoff(deliveryDate, schedule.LeadDays, schedule.CutoffTime);

    if (!isProductOrderable(extraProductData?.status)) {
      return {
        isDisabled: true,
        warningMessage: '',
      };
    }

    // Newly added items are always editable
    if (!ctProduct.hasOwnProperty('lineNumber')) {
      // console.log(`Product (${product.productNumber}) editable because is new addition`);
      return {
        isDisabled: false,
        warningMessage: '',
      };
    }

    // All items are disabled if past business cutoff
    if (currentDate > cutoffDate) {
      // console.log(`Product (${product.productNumber}) not editable because cutoffDate is already past`);
      isDisabled = true;
    }

    // SpecialOrder (added by sales rep) products are never editable
    const productTags = getTagsFromExtendedProductData(extraProductData);
    if (productTags.isSpecialOrder) {
      // console.log(`Product (${product.productNumber}) not editable because is SpecOrd`);
      warningMessage = 'specialOrderProductWarning';
      isDisabled = true;
    }

    if (!productTags?.isActive) {
      // console.log(`Product (${product.productNumber}) not editable because is not Active`);
      warningMessage = 'inactiveProductWarning';
      isDisabled = true;
    }

    //Cutoff time for all products
    const cutoffProductTime = companyInfo?.cutoffs?.daysOfWeek;
    const cutOffDayOfWeek = cutoffProductTime?.find((cutoff) => cutoff.dayOfWeek === deliveryDate.getUTCDay());
    if (cutOffDayOfWeek) {
      const productDateCutOff = DateTime.fromISO(`${deliveryDateStr}T${cutOffDayOfWeek.cutoffTime}`, {
        zone: 'utc',
      })
        .minus({ days: Math.abs(cutOffDayOfWeek.leadDays) })
        .toJSDate();
      isDisabled = currentDate > productDateCutOff;
      if (isDisabled) {
        warningMessage = 'productCutOffPassedWarning';
      }
    }
  }

  // ------------------------------------------------
  // frontend/composable/helpers/utils/order-utils.ts:shouldDisableLineItem()
  const isSurOrJitItem = (companyInfo?.isJustInTime || companyInfo?.isShipUponReceipt) && isNonRefundable(ctProduct);
  const deliveryDates = getActiveAccountDeliveryDates({ activeAccount: ctBusinessUnit });

  if (
    (ctProduct.disableLogic?.isDisabled ||
      isPastCutoffTime(ctBusinessUnit, deliveryDates, extraProductData) ||
      isSurOrJitItem) &&
    ctProduct.hasOwnProperty('lineNumber') // Avoid disabling new items
  ) {
    isDisabled = true;
  }

  // ------------------------------------------------
  // frontend/composable/helpers/utils/inventory-utils.ts:shouldDisableQuantityPicker()
  const { status } = getInventoryInfo(extraProductData);

  if ([InventoryStatus.INACTIVE, InventoryStatus.NOT_FOUND, InventoryStatus.NULL].includes(status)) {
    isDisabled = true;
  }

  return { isDisabled, warningMessage };
};
