import { AsAssociateBusinessUnitResult, DeliveryPlan } from '@Types/business-unit/BusinessUnit';
import { Attributes } from '@Types/product/Attributes';
import { Product } from '@Types/product/Product';
import { ExtraProductData } from '@Types/shamrockApi/Product';
import { CATEGORY_SEPARATOR, CURRENT_ALGOLIA_LOCALE } from 'composable/components/plp/constants';
import { getValueByLocale } from 'composable/components/plp/utils';
import { PageVariant, ProductAttributeKeys } from 'composable/components/types';
import { getDateWithCutoff, getPlanByStoreKey } from 'composable/helpers/hooks/useGetDeliveryDates';
import { getPrice } from 'composable/helpers/utils/get-price';
import { addDays, isBefore, isSameDay, startOfWeek } from 'date-fns';
import { CurrencyHelpers } from 'helpers/currencyHelpers';

type DeliveryCutoff = {
  dayOfWeek: number;
  leadDays: number;
  cutoffTime: string;
};

const IMAGE_LABEL_INSPIRATION = 'productinspirationthumbnail';

export const filterProductDeliveryDays = (plans: DeliveryPlan[], deliveryCutoffs: DeliveryCutoff[]) => {
  const deliveryDaysFromPlan = plans[0].DeliverySchedules.map((schedule) => {
    return schedule.DeliveryDays.map((day) => {
      return day;
    });
  })
    .flat()
    .sort((a, b) => a - b);

  const filteredDeliveryCutoffs = deliveryCutoffs?.filter((cutoff) => {
    return deliveryDaysFromPlan.includes(cutoff.dayOfWeek);
  });

  return filteredDeliveryCutoffs;
};

export const searchProductDeliveryDays = (deliveryCutoffs: DeliveryCutoff[], businessUnitDelDate: Date) => {
  const current = new Date();
  let startOfCurrentWeek = startOfWeek(current, { weekStartsOn: 1 }); // Start of the week, assuming Monday as the first day of the week
  let i = 1;

  const maxIterations = 10; // breaking after x (weeks) iterations to avoid possible infinite loops with bad data

  while (i < maxIterations) {
    for (const day of deliveryCutoffs) {
      // Calculate the difference in days between the current day and the target day
      const dayDifference = (day.dayOfWeek + 7 - startOfCurrentWeek.getDay()) % 7;
      const dayToCheck = addDays(startOfCurrentWeek, dayDifference);

      const dateWCutoff = getDateWithCutoff(dayToCheck, day.leadDays, day.cutoffTime) as Date;

      const isCurrentBeforeCutoff = isBefore(current, dateWCutoff);

      const isDayToCheckBeforeBusinessUnitDelDate = isBefore(dayToCheck, businessUnitDelDate);
      const isDayToCheckSameAsBusinessUnitDelDate = isSameDay(dayToCheck, businessUnitDelDate);

      // console.log('ITERATION', i, {
      //   current,
      //   businessUnitDelDate,
      //   dayToCheck,
      //   dateWCutoff,
      //   isCurrentBeforeCutoff,
      //   isDayToCheckBeforeBusinessUnitDelDate,
      //   isDayToCheckSameAsBusinessUnitDelDate,
      // });

      if (isCurrentBeforeCutoff) {
        if (isDayToCheckBeforeBusinessUnitDelDate && !isDayToCheckSameAsBusinessUnitDelDate) {
          continue;
        } else {
          return {
            deliveryDay: dayToCheck,
            cutoffDate: dateWCutoff,
          };
        }
      }
    }

    // If no match is found in the current week, move to the next week
    startOfCurrentWeek = addDays(startOfCurrentWeek, 7);
    i++;
  }
};

export const getProductPrice = ({
  attributes,
  extraProductData,
  useAlgoliaLocale,
}: {
  attributes: Attributes;
  extraProductData: ExtraProductData;
  useAlgoliaLocale?: boolean;
}) => {
  let portionUom = 'Unit';
  let portionQty = 1;
  let netWeight = 1;
  let priceUom = 'Unit';
  let isCatchWeightItem = false;

  if (useAlgoliaLocale) {
    priceUom = getValueByLocale(attributes, 'shamrock-standard_price_uom', CURRENT_ALGOLIA_LOCALE);
    netWeight = getValueByLocale(attributes, 'shamrock-standard_net_weight', CURRENT_ALGOLIA_LOCALE);
  }

  portionUom = attributes?.['shamrock-standard_portion_uom']?.toLocaleLowerCase();
  portionQty = attributes?.['shamrock-standard_portion_qty'];
  netWeight = attributes?.['shamrock-standard_net_weight'];
  priceUom = attributes?.['shamrock-standard_price_uom'];
  isCatchWeightItem = attributes?.['shamrock-standard_cwt_indicator'];

  let currentPrice = extraProductData?.pricingInfo?.data?.currentPrice ?? 0;
  let discountedPrice = extraProductData?.pricingInfo?.data?.discountedPrice ?? 0;
  priceUom = priceUom?.toLocaleLowerCase();

  /*
  For catch-weight items we use the case price as the current/discounted prices
  are the price per pound, but the user can't select how many pounds to buy.
  */
  if (priceUom === 'lb') {
    currentPrice = extraProductData?.pricingInfo?.data?.currentCasePrice ?? 0;
    discountedPrice = extraProductData?.pricingInfo?.data?.discountedCasePrice ?? 0;
  }

  let productPrice = getPrice(currentPrice, discountedPrice);

  //divide the price with portion qty for non catch weight items
  //and divide by netweight for catch weight items
  let pricePerUnit = CurrencyHelpers.formatCostInCentsToUSDWith4Decimals(
    productPrice,
    isCatchWeightItem ? netWeight : portionQty,
  );

  return {
    isCatchWeightItem,
    pricePerUnit,
    priceUom,
    portionUom,
    currentPrice,
    discountedPrice,
    productPrice,
    portionQty,
  };
};

export const getProductHierarchy = (product: Product, informationVariant: PageVariant) => {
  if (informationVariant === PageVariant.PLP && product.categories && product.categories[CURRENT_ALGOLIA_LOCALE]) {
    const categories = Object.values(product.categories[CURRENT_ALGOLIA_LOCALE]);
    return categories && typeof categories === 'object' && categories?.length
      ? Object.values(categories).at(-1)?.[0]
      : null;
  }
  return product.categories?.reduce((acc, curr, index, array) => {
    if (index === array.length - 1) {
      return acc + curr.name;
    }
    return acc + curr.name + CATEGORY_SEPARATOR;
  }, '');
};

export const getProductDetails = (
  product: Product,
  pageName: PageVariant,
  extraProductData: ExtraProductData,
  imageInspiration?: boolean,
) => {
  let productName = 'ProductName';
  let brand = 'ProductBrand';
  let slug = 'Slug';
  let packSize = 'PackSize';
  let imageURL = '';
  let netWeight = '';
  const attributes = pageName === PageVariant.PLP ? product.attributes : product?.variants?.[0]?.attributes;

  const {
    isCatchWeightItem,
    pricePerUnit,
    priceUom,
    portionUom,
    currentPrice,
    discountedPrice,
    productPrice,
    portionQty,
  } = getProductPrice({
    attributes,
    extraProductData,
    useAlgoliaLocale: pageName === PageVariant.PLP,
  });

  if (pageName === PageVariant.PLP) {
    productName = getValueByLocale(product, 'name', CURRENT_ALGOLIA_LOCALE);
    slug = getValueByLocale(product, 'slug', CURRENT_ALGOLIA_LOCALE);
    brand = getValueByLocale(product.attributes, ProductAttributeKeys.BrandLongDescription, CURRENT_ALGOLIA_LOCALE);
    imageURL = product?.images?.[0];
  } else {
    const firstImageUrl = product?.variants?.[0]?.images?.[0]?.url;
    productName = product?.name;
    slug = product?.slug;
    brand = product?.variants?.[0]?.attributes?.[ProductAttributeKeys.BrandLongDescription];
    imageURL = imageInspiration
      ? product?.variants?.[0]?.images.find((img) => img.label.includes(IMAGE_LABEL_INSPIRATION))?.url || firstImageUrl
      : firstImageUrl;
    netWeight = product?.variants?.[0]?.attributes?.[ProductAttributeKeys.NetWeight] || 1;
  }
  packSize = attributes?.[ProductAttributeKeys.PackSize];
  const category = getProductHierarchy(product, pageName);

  const sku = slug;

  return {
    productName,
    brand,
    slug,
    sku,
    packSize,
    portionUom,
    portionQty,
    imageURL,
    currentPrice,
    discountedPrice,
    productPrice,
    pricePerUnit,
    priceUom,
    isCatchWeightItem,
    netWeight,
    category,
  };
};

export const getDeliveryCutoff = (
  extraProductData: ExtraProductData,
  businessUnit: Partial<AsAssociateBusinessUnitResult> | null,
  businessUnitDelDate: Date,
) => {
  const deliveryCutoffs = extraProductData?.companyInfo?.data?.cutoffs?.daysOfWeek;
  const plans = getPlanByStoreKey(businessUnit);

  const filteredDeliveryCutoffs =
    plans?.length && deliveryCutoffs?.length ? filterProductDeliveryDays(plans, deliveryCutoffs) : [];

  if (filteredDeliveryCutoffs && filteredDeliveryCutoffs.length) {
    const date = searchProductDeliveryDays(filteredDeliveryCutoffs, businessUnitDelDate);
    return date;
  }

  return { deliveryDay: null, cutoffDate: null };
};
