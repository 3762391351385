import { useRouter } from 'next/router';
import { OGM_LOCAL_STORAGE } from 'composable/helpers/constants/localStorageKeys';
import { useLocalStorage } from 'composable/helpers/hooks/useLocalStorage';
import { OGMLocalStorageValues } from './../types';
import { OGM_LOCAL_STORAGE_INITIAL_STATE } from './constants';

const ALLOWED_ROUTES_TO_SAVE_SEARCH_TERMS = ['/order-guide/management'];

export const useLocalStorageOrderGuideManagement = () => {
  const { asPath } = useRouter();
  const [ogmLocalStorageValues, setOGMLocalStorageValues] = useLocalStorage<OGMLocalStorageValues>(
    OGM_LOCAL_STORAGE,
    OGM_LOCAL_STORAGE_INITIAL_STATE,
  );

  const setValue = (key: keyof OGMLocalStorageValues, value: any) => {
    if (key === 'searchTerm' && !ALLOWED_ROUTES_TO_SAVE_SEARCH_TERMS.includes(asPath)) {
      return;
    }
    const valueFromLocalStorage = JSON.parse(window.localStorage.getItem(OGM_LOCAL_STORAGE));
    setOGMLocalStorageValues({
      ...(valueFromLocalStorage ? valueFromLocalStorage : OGM_LOCAL_STORAGE_INITIAL_STATE),
      [key]: value,
    });
  };

  const setAllValues = (values: OGMLocalStorageValues) => {
    setOGMLocalStorageValues({ ...values });
  };

  return {
    ogmLocalStorageValues,
    setOGMLocalStorageValues,
    setValue,
    setAllValues,
  };
};
