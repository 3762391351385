import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { FEATURE_FLAG_BACK_REFRESH_POC } from 'composable/components/general';
import { isWindowDefined } from 'swr/_internal';

export const useRouterPopState = () => {
  const router = useRouter();

  const preventDefaultEvents = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    if (FEATURE_FLAG_BACK_REFRESH_POC) {
      if (isWindowDefined) {
        router.beforePopState((route) => {
          window.history.pushState('/', '');
          router.push(route.as);
          return false;
        });
        window.onpopstate = preventDefaultEvents;

        // firefox seems to work fine, but other browsers need extra handle
        if (!navigator.userAgent.toLowerCase().includes('firefox')) {
          window.onbeforeunload = preventDefaultEvents;
        }
      }

      return () => {
        if (isWindowDefined) {
          window.onpopstate = null;
          if (!navigator.userAgent.toLowerCase().includes('firefox')) {
            window.onbeforeunload = null;
          }
        }
        router.beforePopState(() => {
          return true;
        });
      };
    }
  }, [isWindowDefined]);
};
