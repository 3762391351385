const breakpoints = {
  xs: '20em', // 320px
  sm: '40em', // 640px
  md: '48em', // 768px
  lg: '64em', // 1024px
  xl: '79.3125em', // 1269px -> Shamrock custom breakpoint, might change in the future
  '1440px': '90em', // 1440px -> OX Team custom breakpoint
  '2xl': '96em', // 1536px
  '3xl': '120em', //1920px
  '4xl': '128em', //2048px
};

export default breakpoints;
