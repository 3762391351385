import { ProductRecommendations } from 'helpers/services/shamrock';

export type TrendingItemsGlobalType = {
  trendingItemsList: ProductRecommendations.TransformedData[] | null;
  loading: boolean;
};

export enum TrendingLineItemsActionType {
  SET_TRENDING_LINEITEMS,
  SET_LOADING,
}

export type TrendingLineItemsAction =
  | {
      type: TrendingLineItemsActionType.SET_TRENDING_LINEITEMS;
      payload: ProductRecommendations.TransformedData[];
    }
  | {
      type: TrendingLineItemsActionType.SET_LOADING;
      payload: boolean;
    };
