import { ExtraProductData } from '@Types/shamrockApi/Product';

type PartitionProductsByCutoffProps = {
  productsWithoutCutoff: ExtraProductData[];
  productsWithCutoff: ExtraProductData[];
};

export function partitionProductsByCutoff(extraProductData: ExtraProductData[]) {
  const { productsWithoutCutoff, productsWithCutoff } = extraProductData.reduce<PartitionProductsByCutoffProps>(
    (acc, product) => {
      const hasCutoff = product.companyInfo?.data?.cutoffs?.daysOfWeek?.length > 0;
      if (hasCutoff) {
        acc.productsWithCutoff.push(product);
      } else {
        acc.productsWithoutCutoff.push(product);
      }
      return acc;
    },
    { productsWithoutCutoff: [], productsWithCutoff: [] },
  );
  return { productsWithoutCutoff, productsWithCutoff };
}
