import { CartGlobalAction, CartGlobalStateActions, CartState } from './types';

export const cartInitialState: CartState = {
  cart: null,
  shippingMethods: null,
  loading: true,
  error: null,
};

export function reduceCart(state: CartState, action: CartGlobalAction): CartState {
  switch (action.type) {
    case CartGlobalStateActions.UPDATE:
      return {
        ...state,
        error: null,
        cart: action.payload.cart,
        loading: false,
      };
    case CartGlobalStateActions.SET_LOADING:
      return { ...state, loading: action.payload.loading };
    case CartGlobalStateActions.SET_ERROR:
      return { ...state, error: action.payload.error };
    default:
      return state;
  }
}
