import { createContext, FunctionComponent, PropsWithChildren, SetStateAction, useContext, useState } from 'react';

type SearchInputContextType = {
  setSearchInput: React.Dispatch<SetStateAction<string>>;
  searchInput: string;
};

const SearchInputContext = createContext<SearchInputContextType>(null!);
export const useSearchInputContext = () => {
  const context = useContext(SearchInputContext);

  if (!context) {
    throw new Error('useSearchInputContext must be used within a SearchInputProvider');
  }

  return context;
};

export const SearchInputProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [searchInput, setSearchInput] = useState('');

  return <SearchInputContext.Provider value={{ setSearchInput, searchInput }}>{children}</SearchInputContext.Provider>;
};
