export const spacing = {
  xxxxs: '0.125rem', //2
  xxxs: '0.25rem', //4
  xxs: '0.5rem', //8
  xs: '0.75rem', //12
  sm: '1rem', //16
  md: '1.5rem', //24
  lg: '2rem', //32
  xl: '3rem', //48
  xxl: '4rem', //64
  xxxl: '6rem', //96
  xxxxl: '8rem', //128

  //grid
  gridGap: '20px',
  gridRowGap: '20px',
  gridColGap: '20px',

  //chakra-ui default
  px: '1px',
  0.5: '0.125rem',
  1: '0.25rem',
  1.5: '0.375rem',
  2: '0.5rem',
  2.5: '0.625rem',
  3: '0.75rem',
  3.5: '0.875rem',
  4: '1rem',
  4.5: '1.125rem',
  5: '1.25rem',
  6: '1.5rem',
  7: '1.75rem',
  7.5: '1.875rem',
  8: '2rem',
  8.5: '2.187',
  9: '2.25rem',
  10: '2.5rem',
  11: '2.75rem',
  11.25: '2.8125rem',
  12: '3rem',
  14: '3.5rem',
  14.5: '3.625rem',
  15: '3.75rem',
  15.5: '3.875rem',
  16: '4rem',
  18: '4.5rem',
  20: '5rem',
  23: '5.75rem',
  23.5: '5.875rem',
  24: '6rem',
  24.5: '6.125rem',
  25: '6.25rem',
  26: '6.5rem',
  28: '7rem',
  29: '7.18rem',
  32: '8rem',
  36: '9rem',
  38: '9.5rem',
  40: '10rem',
  42: '10.5rem',
  44: '11rem',
  46: '11.75rem',
  48: '12rem',
  52: '13rem',
  56: '14rem',
  60: '15rem',
  62: '15.5rem',
  64: '16rem',
  68: '17rem',
  72: '18rem',
  80: '20rem',
  84: '21rem',
  85: '21.25rem',
  88: '22rem',
  96: '24rem',
  97.5: '24.68rem',
  100: '26.3rem',
  110: '29.5rem',
  112: '30rem',
  120: '32rem',
  150: '37.5rem',
};

export type Spacing = typeof spacing;
