import { mode, Styles } from '@chakra-ui/theme-tools';
import borders from '../foundations/borders';
import borderStyles from '../foundations/borderStyles';

export default {
  // Styles for the base style
  parts: ['tab'],
  baseStyle: {
    /**
     * Styles set within { variants } will override base styles
     * Styles set within { sizes } will override base styles
     * The Input component uses "md" size and "outline" variant by default.
     *
     * You can unset those defaults by using null in defaultProps:
     *    defaultProps: {
     *      size: null,
     *      variant: null
     *    },
     *
     * You will lose all default styles this way, including things like focus.
     */
  },
  variants: {
    line: (props: Styles) => {
      return {
        tab: {
          color: 'gray.400',
          _focus: {
            borderColor: 'primary',
            color: 'primary',
          },
          _selected: {
            borderColor: 'primary',
            color: 'primary',
            bg: 'transparent',
          },
        },
        tablist: {
          borderColor: 'transparent',
        },
      };
    },
    'soft-rounded': (props: Styles) => {
      return {
        tab: {
          borderColor: 'gray.400',
          color: 'gray.400',
          borderWidth: borders.sm,
          borderStyle: borderStyles.normal,
          bg: 'transparent',
          _focus: {
            borderColor: 'primary',
            color: 'primary',
          },
          _selected: {
            borderColor: 'primary',
            color: 'primary',
            bg: 'transparent',
          },
        },
      };
    },
    'solid-rounded': (props: Styles) => {
      return {
        tab: {
          borderColor: 'transparent',
          bg: 'transparent',
          color: 'gray.400',
          borderWidth: borders.sm,
          borderStyle: borderStyles.normal,
          _focus: {
            borderColor: 'primary',
            bg: 'primary',
            color: 'white',
          },
          _selected: {
            borderColor: 'primary',
            bg: 'primary',
            color: 'white',
          },
        },
      };
    },
    highlight: (props: Styles) => {
      return {
        tab: {
          color: 'text',
          _selected: {
            color: 'primary',
          },
        },
      };
    },
  },
};
