import { Address } from '@Types/account/Address';
import { ExtraProductData } from '@Types/shamrockApi/Product';
import { ProductAttributeKeys } from 'composable/components/types';
import { RemainingDays } from 'composable/helpers/hooks/useGetDeliveryDates';

export namespace OrderGuide {
  export interface Payload {
    warehouseNumber: string;
    customerNumber: string;
    businessUnitName: string;
    businessSegmentName: string;
    companyDetailId: number;
    //This should be always null, Shamrock assigns the number
    orderGuideNumber: string | number;
    name: string;
    description?: string;
    type: Type;
    isShared: boolean;
    isDraft: boolean;
    categoryTemplateNumber?: string;
    sortOrder?: string;
    products: Product[];
    productCount?: number;
    isContract?: boolean;
  }

  export enum CategoryTemplateNumber {
    NONE = 'A000004',
    PRODUCT_FAMILY = 'A000003',
    PRODUCT_FAMILY_LINES = 'A000002',
    PRODUCT_LINES = 'A000001',
    PRODUCT_PRICE_CODES = 'A000000',
  }

  export enum Type {
    Custom = 'Custom',
    Contract = 'Contract',
  }

  export interface Product extends ExtraProductData {}

  export interface OrderGuideTemplateCategory {
    categoryTemplateNumber: string;
    majorCategory: Category;
    subCategories: Category[];
  }

  export interface Category {
    categorySequence: number;
    categoryNumber: string;
    categoryDescription: string;
    categoryExtendedDescription: string;
  }

  export type Response = {
    products: {
      status: string;
      data: Product[];
    };
    orderGuideNumber: string;
    name: string;
    description: string;
    productCount: number;
    isDraft: boolean;
    isContract: boolean;
    isShared: boolean;
    isLinked: boolean;
    lastModifiedDate: string;
    categoryTemplateNumber: string;
    sortOrder: string;
  };

  export interface FormattedOrderGuide {
    id: string;
    value: string;
  }
}

export enum TypeOfDocument {
  GET_FILE_PDF = 'getFilePdf',
  PURCHASE_HISTORY = 'purchaseHistory',
  ORDER_CONFIRMATION = 'orderConfirmation',
  ORDER_GUIDE_INVENTORY_SHEET = 'orderGuideInventorySheet',
  ORDER_GUIDE_PRICE_LIST = 'orderGuidePriceList',
  ORDER_GUIDE_ORDER_SHEET = 'OrderGuideOrderSheet',
  ACCOUNT_FINANCIAL_OVERVIEW = 'AccountFinancialOverview',
  PRODUCT_DATA_SHEET = 'ProductDataSheet',
}

export enum DocumentFormat {
  FSW_INVOICE = 'fswinvoice',
  INVOICE = 'invoice',
  ORG_INVOICE = 'orginvoice',
  STATEMENT = 'statement',
  PDF = 'pdf',
  EXCEL = 'excel',
  CSV = 'csv',
  WORD = 'word',
}

export enum DocumentFormatFileExtension {
  fswinvoice = 'txt',
  invoice = 'txt',
  orginvoice = 'txt',
  statement = 'txt',
  // above to be changed later when the api's related to them are implemented
  pdf = 'pdf',
  excel = 'xls',
  csv = 'csv',
  word = 'doc',
}
export namespace DeliveryWindow {
  export type DeliveryWindow = {
    leadDays: number;
    cutOffTime: string;
    canAllProductsFit: boolean;
    productNumbers: string[];
    isAvailable: boolean;
    maxCasesPerOrder: number | null;
    nonAvailabilityReason: string | null;
    geocode: string;
    deliveryWindowStartTime: string;
    deliveryWindowEndTime: string;
    pickupAddress: null | PickupAddress;
  };

  export type PickupAddress = {
    name: string;
    address1: string;
    address2: string | null;
    address3: string | null;
    city: string;
    state: string;
    postalCode: string;
    primaryPhoneNumber: string;
    primaryPhoneNumberExtension: string | null;
  };

  export type DeliveryOption = {
    deliveryDate: string;
    deliveryType: string;
    deliveryWindows: DeliveryWindow[];
    deliveryMinimums: DeliveryMinimum[];
  };

  export type DeliveryMinimum = {
    chargeAmount: number;
    effectiveDate: string;
    expirationDate: string;
    orderType: string;
    totalCostThreshold: number;
    totalCaseThreshold: number;
  };

  export type Response = {
    geocodeCutoffDate?: string;
    geocodeCutoffTime?: string;
    deliveryOptions: DeliveryOption[];
    deliveryMinimums: DeliveryMinimum[];
  };

  export type Payload = {
    warehouseNumber: string;
    customerNumber: string;
    businessUnitName: string;
    businessSegmentName: string;
    deliveryMethod: 'WillCall' | 'ALaCarte';
    products: {
      productNumber: string;
      quantity: number;
    }[];
    orderNumber?: string;
  };
}

export type FormattedTimeWindow = {
  deliveryWindowStartTime: string;
  deliveryWindowEndTime: string;
  formattedStartTimeLocal: string;
  formattedEndTimeLocal: string;
  remainingDays: RemainingDays | null;
  radioKey: string;
  warehouseAddress: Partial<Address>;
  geocode: string;
  isAvailable: boolean;
  isUnavailableByCases: boolean;
  maxCasesPerOrder: number;
};

export type FormattedDeliveryMinimum = {
  chargeAmount: number;
  effectiveDate: string;
  expirationDate: string;
  orderType: string;
  totalCaseThreshold: number;
  totalCostThreshold: number;
};

export type FormattedDeliveryOption = {
  deliveryDate: string;
  deliveryDateWithTime: string;
  deliveryWindows: FormattedTimeWindow[];
  deliveryMinimums: FormattedDeliveryMinimum[];
  deliveryType: string;
};

export namespace ContainingProduct {
  export type Params = CommonPayload & {
    productNumber: string;
  };

  export type Response = {
    orderGuideNumbers: string[];
  };
}

export type CommonPayload = {
  customerNumber: string;
  warehouseNumber: string;
  businessUnitName: string;
  businessSegmentName: string;
};

export enum DeliveryMethod {
  PICK_UP = 'WillCall',
  A_LA_CARTE = 'ALaCarte',
}

export type MultipleAccountsPayload = {
  customerNumber: string[];
  warehouseNumber: string;
  businessUnitName: string;
  businessSegmentName: string;
};

export interface ReturnRow {
  businessSegmentName: string;
  businessUnitName: string;
  createdDateTime: string;
  creditAmount: number;
  creditNumber: string;
  creditQuantity: number;
  customerNumber: string;
  orderNumber: string;
  referenceNumber: string;
  requestedAmount: number;
  requestedQuantity: number;
  returnNumber: string;
  status: string;
  warehouseNumber: string;
  creditDateTime?: string;
  returnReferenceNumber?: string;
}

export type DeliveryOptionsEligibility = {
  customerNumber: string;
  businessUnitName: string;
  businessSegmentName: string;
  warehouseNumber: string;
  eligibleDeliveryOptions: string[];
};

export type ProductStockNotification = {
  notification: {
    notificationId: Number;
    productNumber: string;
    isSubscribed: boolean;
  };
};

export type ProductFeedback = {
  feedback: {
    isSuccess: boolean;
  };
};

export namespace AddProductToOrderGuide {
  export interface Payload extends CommonPayload {
    orderGuideNumber: string;
    productNumber: string;
    isDraft: boolean;
    saveAsDraft: boolean;
  }

  export interface Response {
    success: boolean;
    errorMessage: string;
  }
}

export type FullCommonPayload = {
  customerNumber: string;
  warehouseNumber: string;
  businessUnitName: string;
  businessSegmentName: string;
  accessToken: string;
};

export namespace ProductRecommendations {
  export type Params = Omit<FullCommonPayload, 'accessToken'> & {
    productNumber?: string;
    includeTrending?: boolean;
    includeSimilar?: boolean;
    includeMightAlsoLike?: boolean;
  };

  export type Response = {
    trendingProducts?: ExtraProductData[] | null;
    similarProducts?: ExtraProductData[] | null;
    mightAlsoLikeProducts?: ExtraProductData[] | null;
  };

  export type TransformedDataResponse = {
    trendingProducts?: TransformedData[];
    similarProducts?: TransformedData[];
    mightAlsoLikeProducts?: TransformedData[];
  };

  export interface TransformedData {
    productId: string;
    version: string;
    name: string;
    slug: string;
    description: string;
    categories: Category[];
    variants: Variant[];
    _url: string;
    extraInformation: ExtraInformation;
  }
}

export interface Category {
  categoryId: string;
  depth: number;
  id: string;
  name: string;
  slug: string;
  orderHint: string;
  ancestors: Ancestor[];
  parent?: Parent;
}

export interface Ancestor {
  typeId: TypeID;
  id: string;
  obj?: Obj;
}

export interface Obj {
  id: string;
  version: number;
  versionModifiedAt: Date;
  lastMessageSequenceNumber: number;
  createdAt: Date;
  lastModifiedAt: Date;
  lastModifiedBy: EdBy;
  createdBy: EdBy;
  key: string;
  name: Description;
  slug: Description;
  description: Description;
  ancestors: Parent[];
  orderHint: string;
  externalId: string;
  assets: any[];
  parent?: Parent;
}

export interface Parent {
  typeId: TypeID;
  id: string;
}

export enum TypeID {
  Category = 'category',
  User = 'user',
}

export interface EdBy {
  isPlatformClient: boolean;
  user: Parent;
}

export interface Description {
  en: string;
}

export interface ExtraInformation {
  productNumber: string;
  sequence: null;
  parQuantity: null;
  parComment: null;
  isNew: boolean;
  isVisible: boolean;
  status: string;
  majorCategory: null;
  subCategory: null;
  pricingInfo: PricingInfo;
  inventoryInfo: null;
  companyInfo: CompanyInfo;
  nextDeliveryDueDates: null;
  lastPurchasedDates: null;
  purchaseHistory: null;
  productSubstitutions: null;
}

export interface CompanyInfo {
  status: string;
  data: CompanyInfoData;
}

export interface CompanyInfoData {
  status: string;
  palletTier: number;
  palletHigh: number;
  palletQuantity: number;
  isCrossdock: boolean;
  isSpecialOrder: boolean;
  isJustInTime: boolean;
  isShipUponReceipt: boolean;
  isFish: boolean;
  isPromotion: boolean;
  isHighlyPerishable: boolean;
  cutoffs: Cutoffs;
}

export interface Cutoffs {
  daysOfWeek: any[];
  holidays: any[];
}

export interface PricingInfo {
  status: string;
  data: PricingInfoData;
}

export interface PricingInfoData {
  isPreferredPricing: boolean;
  priceMethod: string;
  currentPrice: number;
  currentCasePrice: number;
  customerPrice: null;
  suggestedPrice: number;
  discountedPrice: number;
  discountedCasePrice: number;
  priceUnitOfMeasure: string;
  showBank: boolean;
  bank: number;
}

export interface Variant {
  id: string;
  sku: string;
  images: Image[];
  attributes: ProductAttributeKeys;
}

export interface Image {
  url: string;
  label: string;
}

export type SoftCutoffsPayload = {
  'warehouse-number': string;
  'business-unit-name': string;
  'business-segment-name': string;
  'customer-number': string;
};

export type SoftCutoffsResponse = {
  primaryCutoffTime?: string; // 'hh:mm:ss'
  dailyCutoffDetails?: Array<DaySoftCutoffDetail>;
};

export type DaySoftCutoffDetail = {
  dayOfWeek: string; //"Sunday"
  softCutoffTime?: string; // 'hh:mm:ss'
  leadDays: number;
};
