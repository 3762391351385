import { Order } from '@Types/shamrockApi/Order';
import { isError } from 'composable/helpers/utils/order-status';

type businessUnitOptions = {
  value: string;
  label: string;
};

export const sortBusinessUnitsByName = (businessUnits: businessUnitOptions[]) => {
  const sortedBusinessUnits = [...businessUnits].sort((a, b) => {
    // Convert both names to lowercase for case-insensitive sorting
    const businessUnitA = a.label.toLowerCase();
    const businessUnitB = b.label.toLowerCase();

    // Use localeCompare for alphanumeric sorting
    return businessUnitA.localeCompare(businessUnitB);
  });

  return sortedBusinessUnits;
};

export function isSubmittedDateTimePast48Hours(submittedDateTime: string): boolean {
  const submittedDate = new Date(submittedDateTime);
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const diffInMs = currentDate.getTime() - submittedDate.getTime();

  // Convert milliseconds to hours
  const diffInHours = diffInMs / (1000 * 60 * 60);

  return diffInHours > 48;
}

export const getOrdersSortedWithErrors = (orders: Order[]) => {
  const sortedOrders = orders.reduce(
    (acc, order) => {
      if (isError(order.status, order.orderNumber)) {
        isSubmittedDateTimePast48Hours(order.submittedDateTime) ? acc.oldErrors.push(order) : acc.newErrors.push(order);
      } else {
        acc.restOfOrders.push(order);
      }
      return acc;
    },
    { newErrors: [], oldErrors: [], restOfOrders: [] },
  );

  const { newErrors, oldErrors, restOfOrders } = sortedOrders;
  return [...newErrors, ...restOfOrders, ...oldErrors];
};
