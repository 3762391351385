import { createRef } from 'react';
import { getDeliveryCutoff } from 'composable/components/product-card-v2/helpers';
import moment from 'moment-timezone';
import { EditOrderCutoffTimeModalElement } from './EditOrderCutoffTimeModal';
import { EditOrderValidationModalElement } from './EditOrderValidationModal';

export const refEditOrderCutoffTimeModal = createRef<EditOrderCutoffTimeModalElement>();
export const refEditOrderValidationModal = createRef<EditOrderValidationModalElement>();

export const isPastCutoffTime = (selectedBusinessUnit, deliveryDates, extendedData) => {
  if (deliveryDates?.dates?.length) {
    const delivery = getDeliveryCutoff(extendedData, selectedBusinessUnit, deliveryDates.dates[0]);
    if (delivery && delivery?.cutoffDate) {
      const cutoffdate = moment(delivery.cutoffDate);
      return cutoffdate.isBefore(moment());
    }
  }
  return false;
};

export const sleep = (ms: number) => new Promise<void>((resolve) => setTimeout(resolve, ms));
