import { defineStyleConfig } from '@chakra-ui/react';

export default defineStyleConfig({
  sizes: {
    'ds-xs': {
      lineHeight: '0.75rem' /* 12px */,
      fontSize: '0.75rem' /* 12px */,
    },
    'ds-sm': {
      lineHeight: '0.875rem' /* 14px */,
      fontSize: '0.875rem' /* 14px */,
    },
    'ds-md': {
      lineHeight: '1rem' /* 16px */,
      fontSize: '1rem' /* 16px */,
    },
    'ds-lg': {
      lineHeight: '1.25rem' /* 20px */,
      fontSize: '1.25rem' /* 20px */,
    },
    'ds-xl': {
      lineHeight: '1.5rem' /* 24px */,
      fontSize: '1.5rem' /* 24px */,
    },
  },
  variants: {
    light: {
      color: 'neutral.900',
      background: 'transparent',
      fontWeight: 400,
      textDecor: 'underline',
      textUnderlineOffset: '3px',
      _hover: {
        color: 'primary.500',
        textDecor: 'none',
      },
      _disabled: {
        color: 'neutral.400',
        textDecor: 'none',
      },
      _focus: {
        outlineColor: 'violet.500',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
        borderRadius: '2px',
      },
      _focusVisible: {
        outlineColor: 'violet.500',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
        boxShadow: 'none',
        borderRadius: '2px',
      },
    },
    dark: {
      tabIndex: 1,
      color: 'white',
      background: 'transparent',
      fontWeight: 400,
      textDecor: 'underline',
      textUnderlineOffset: '3px',
      _hover: {
        color: 'primary.400',
        textDecor: 'none',
      },
      _disabled: {
        color: 'neutral.500',
        textDecor: 'none',
      },
      _focus: {
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
        borderRadius: '2px',
      },
      _focusVisible: {
        outlineColor: 'violet.400',
        outlineWidth: '2px',
        outlineStyle: 'solid',
        outlineOffset: '2px',
        boxShadow: 'none',
        borderRadius: '2px',
      },
    },
  },
});
