import { FunctionComponent } from 'react';

export const ShareFatIcon: FunctionComponent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M18.5671 8.30855L12.3171 2.05855C12.2298 1.97109 12.1184 1.9115 11.9972 1.88732C11.876 1.86314 11.7503 1.87545 11.6361 1.9227C11.5218 1.96995 11.4242 2.05001 11.3555 2.15276C11.2867 2.25551 11.25 2.37634 11.25 2.49995V5.6523C9.22339 5.82574 6.98511 6.81792 5.1437 8.37964C2.92651 10.2609 1.54605 12.6851 1.2562 15.2054C1.23355 15.4014 1.27328 15.5995 1.36974 15.7715C1.46619 15.9436 1.61446 16.0808 1.79343 16.1637C1.97241 16.2466 2.17298 16.271 2.36659 16.2333C2.56021 16.1956 2.73701 16.0978 2.87183 15.9539C3.7312 15.039 6.78901 12.146 11.25 11.8914V15C11.25 15.1236 11.2867 15.2444 11.3555 15.3471C11.4242 15.4499 11.5218 15.53 11.6361 15.5772C11.7503 15.6245 11.876 15.6368 11.9972 15.6126C12.1184 15.5884 12.2298 15.5288 12.3171 15.4414L18.5671 9.19136C18.684 9.07419 18.7497 8.91545 18.7497 8.74996C18.7497 8.58446 18.684 8.42571 18.5671 8.30855ZM12.5 13.4914V11.25C12.5 11.0842 12.4341 10.9252 12.3169 10.808C12.1997 10.6908 12.0407 10.625 11.875 10.625C9.6812 10.625 7.54448 11.1976 5.52417 12.3281C4.49522 12.9064 3.53652 13.6016 2.66714 14.4C3.12026 12.5375 4.26245 10.7664 5.9523 9.33277C7.76636 7.79449 9.98042 6.87495 11.875 6.87495C12.0407 6.87495 12.1997 6.80911 12.3169 6.6919C12.4341 6.57469 12.5 6.41572 12.5 6.24995V4.00933L17.2414 8.74996L12.5 13.4914Z"
      fill="#1F1F23"
    />
  </svg>
);
